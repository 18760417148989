import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const AddTypeItem = ({
  name,
  setFieldValue,
  handleRemove = () => {},
  handleChange = () => {},
  options = [],
  values = {},
  errors = {},
  index = 0,
  isContract = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const chosenTypes = values[name].map((item) => item.vatRateId);
  const filterOptions = options.filter(
    (item) =>
      !chosenTypes.includes(item._id) ||
      values[name][index]?.vatRateId === item._id
  );
  const classes = styles.useStylesItem();

  return (
    <div className={classes.itemContainer}>
      {!isContract && (
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
        >
          <InputLabel id="demo-simple-select-label">
            {t('categories.types')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Type"
            name={`${name}[${index}].vatRateId`}
            value={values[name][index]?.vatRateId || ''}
            onChange={handleChange}
          >
            {filterOptions.map((item) => (
              <MenuItem value={item._id} key={item._id}>
                {item.display}
              </MenuItem>
            ))}
          </Select>
          {errors?.vatRateId && (
            <p className={classes.errorMessage}>{errors.vatRateId}</p>
          )}
        </FormControl>
      )}
      <div>
        <TextField
          label={t('categories.account')}
          className={classes.accountNumber}
          variant="outlined"
          name={`${name}[${index}].accountNumber`}
          id={`${name}[${index}].accountNumber`}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{
                  color:
                    values[name][index].accountNumber?.toString().length > 0
                      ? 'black'
                      : 'gray',
                }}
              >
                {values.account.slice(0, 3)} -
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
          value={values[name][index]?.accountNumber?.toString() || ''}
          {...rest}
        />
        {errors?.accountNumber && (
          <p className={classes.errorMessage}>{errors.accountNumber}</p>
        )}
      </div>
      <DeleteOutline onClick={handleRemove} className={classes.deleteIcon} />
    </div>
  );
};

AddTypeItem.propTypes = {
  name: PropTypes.string.isRequired,
  handleRemove: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  index: PropTypes.number,
  options: PropTypes.array,
  isContract: PropTypes.bool,
};

export default AddTypeItem;
