import { authInstance as axios } from 'utils/axiosInterceptor';

const getNotification = () =>
  axios.get(`${process.env.REACT_APP_CREA}/admin/notification`);

const deleteNotification = (notificationId) =>
  axios.delete(
    `${process.env.REACT_APP_CREA}/admin/notification/${notificationId}`
  );

export default {
  getNotification,
  deleteNotification,
};
