import adminService from 'services/AdminService';

import {
  FETCHING_LOGGED_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
} from 'actions/Types';

const fetchUserFailed = (payload) => ({
  type: LOGIN_USER_FAILED,
  payload: {
    error: payload || 'An error has occured while retrieving the user',
  },
});

const fetchUserSuccess = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});

/* eslint import/prefer-default-export: off */
export const fetchLoggedUser = () => async (dispatch) => {
  dispatch({ type: FETCHING_LOGGED_USER });

  try {
    const {
      data: { is_admin: isAdmin, ...user },
    } = await adminService.getAuthUser();

    if (user) {
      dispatch(fetchUserSuccess({ isAdmin, user }));
    } else {
      dispatch(fetchUserFailed());
    }
  } catch (error) {
    dispatch(fetchUserFailed(error));
  }
};
