import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  imageContainer: {
    textAlign: 'center',
  },
  image: {
    border: 'none',
    borderRadius: '4px',
    width: '100%',
    maxWidth: '150px',
    objectFit: 'contain',
  },
}));

export default useStyles;
