import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

const CustomDialog = (props) => {
  const {
    title,
    contentText,
    children,
    closeDialog,
    userLoggedIn,
    setFullWidth,
    open,
    showButtons,
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
    disableBackdropClick,
  } = props;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      fullScreen={setFullWidth}
      maxWidth="lg"
      scroll="paper"
      onClose={closeDialog}
      disableBackdropClick={disableBackdropClick}
    >
      {userLoggedIn && (
        <Button
          onClick={closeDialog}
          style={{
            position: 'absolute',
            right: '5%',
            top: '1%',
            width: '30px',
            height: '30px',
          }}
        >
          <Close />
        </Button>
      )}
      <div style={{ margin: '2% auto 0 auto' }}>
        {title && (
          <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
        )}

        <DialogContent dividers>
          {contentText && <DialogContentText>{contentText}</DialogContentText>}
          {children}
        </DialogContent>

        {showButtons && (
          <DialogActions>
            <Button onClick={onConfirm} color="primary">
              {confirmText}
            </Button>
            <Button onClick={onCancel} color="primary">
              {cancelText}
            </Button>
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  closeDialog: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  showButtons: PropTypes.bool,
  userLoggedIn: PropTypes.bool,
  setFullWidth: PropTypes.bool,
  open: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
};

CustomDialog.defaultProps = {
  setFullWidth: false,
  contentText: '',
  disableBackdropClick: false,
};

export default CustomDialog;
