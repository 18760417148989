/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  IconButton,
  Box,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Refresh, Search } from '@material-ui/icons';

import MuiAlert from '@material-ui/lab/Alert';
import AppActions from 'actions/AppActions';
import BridgeWebhookActions from 'actions/BridgeWebhookActions';
import { BRIDGE_WEBHOOK_TYPE_LIST } from 'constants/bridgeWebhooks';
import { get } from 'lodash';
import useLoadMore from 'hooks/useLoadMore';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  backBtn: {
    marginRight: 20,
    textDecoration: 'none',
  },
  screenTitle: {
    fontSize: 25,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  container: {
    maxHeight: 440,
  },
  searchInput: {
    marginRight: 20,
    width: '28ch',
  },
  input: {
    height: 48,
  },
}));

const BridgeWebhooks = () => {
  const classes = useStyles();
  const tableRef = useRef();
  const dispatch = useDispatch();

  const { docs, hasNextPage } = useSelector(
    (state) => state.bridgeWebhook.data
  );

  const error = useSelector((state) => state.bridgeWebhook.error);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [filterType, setFilterType] = useState('');

  const [query, setQuery] = useState({
    pageIndex: 1,
    pageSize: 10,
    search: '',
  });

  useEffect(() => {
    dispatch(AppActions.setAppTitle('Bridge Webhooks'));
    (async () => {
      if (docs.length === 0) {
        setLoading(true);
        await BridgeWebhookActions.refreshBridgeWebhooks({
          pageSize: 10,
          pageIndex: 1,
        });
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    BridgeWebhookActions.removeError();
  };

  const loadMore = async () => {
    setLoading(true);
    await BridgeWebhookActions.fetchBridgeWebhooks({
      ...query,
      pageIndex: query.pageIndex + 1,
    });
    setLoading(false);

    setQuery((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex + 1,
    }));
  };

  useLoadMore(
    tableRef,
    {
      loading,
      hasMore: hasNextPage,
    },
    loadMore
  );

  const refreshData = async () => {
    setLoading(true);
    await BridgeWebhookActions.refreshBridgeWebhooks({
      pageSize: 10,
      pageIndex: 1,
    });

    setLoading(false);

    setQuery((prev) => ({
      ...prev,
      pageIndex: 1,
      type: undefined,
      search: undefined,
    }));

    setSearchValue('');
    setFilterType('');
  };

  const handleSelectType = React.useCallback(
    async (e) => {
      const { value } = e.target;
      setFilterType(value);

      setLoading(true);
      await BridgeWebhookActions.refreshBridgeWebhooks({
        ...query,
        pageSize: 10,
        pageIndex: 1,
        type: value,
      });

      if (value) {
        setQuery((prev) => ({
          ...prev,
          type: value,
        }));
      } else {
        setQuery((prev) => ({
          ...prev,
          type: undefined,
        }));
      }
      setLoading(false);
    },
    [setFilterType]
  );

  const columns = [
    {
      id: '_id',
      label: 'ID',
      // eslint-disable-next-line react/display-name
      _render: (value) => {
        return <Link to={`/bridge-webhooks/${value}`}>{value}</Link>;
      },
    },
    {
      id: 'type',
      label: 'Type',
      _render: (value) => value,
    },
    {
      id: 'timestamp',
      label: 'Time',
      _render: (value) => {
        return new Date(value).toLocaleString();
      },
    },
    {
      id: 'content.user_uuid',
      label: 'User id',
      _render: (value) => value,
    },
  ];

  const handleSearch = async (event) => {
    event.preventDefault();

    if (searchValue) {
      setLoading(true);
      await BridgeWebhookActions.refreshBridgeWebhooks({
        ...query,
        pageSize: 10,
        pageIndex: 1,
        search: searchValue,
      });

      setQuery((prev) => ({
        ...prev,
        search: searchValue,
      }));
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
              >
                {/* Search with webhook */}
                <form onSubmit={handleSearch}>
                  <TextField
                    className={classes.searchInput}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="example: 27403984"
                    helperText="anything"
                    InputProps={{
                      endAdornment: (
                        <IconButton type="submit">
                          <Search color="primary" />
                        </IconButton>
                      ),
                    }}
                    name="search"
                  />

                  <IconButton
                    color="primary"
                    aria-label="refresh"
                    onClick={refreshData}
                  >
                    <Refresh />
                  </IconButton>
                </form>

                {/* Search with object */}
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  p={2}
                >
                  <TextField
                    select
                    className={classes.searchInput}
                    value={filterType}
                    onChange={handleSelectType}
                    helperText="object"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {BRIDGE_WEBHOOK_TYPE_LIST.map((object) => (
                      <MenuItem key={object.value} value={object.value}>
                        {object.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
              <TableContainer className={classes.container} ref={tableRef}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {docs.length ? (
                      <>
                        {docs.map((row, i) => (
                          <TableRow hover key={i}>
                            {columns.map((column) => {
                              const value = get(row, column.id);
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column._render
                                    ? column._render(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                        {!loading && !hasNextPage && (
                          <TableRow>
                            <TableCell colSpan={columns.length}>
                              no more data
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      !loading && (
                        <TableRow>
                          <TableCell colSpan={columns.length}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              p={2}
                            >
                              <Typography variant="body1">
                                No data available
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    )}

                    {loading && (
                      <TableRow>
                        <TableCell colSpan={columns.length}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            p={2}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {error ? error.message : ''}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BridgeWebhooks;
