import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'components/materialtable/MaterialTable';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  // root: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'flex-start',
  //   fontWeight: 500,
  // },
  // item: {
  //   paddingBottom: theme.spacing(1),
  // },
  heading: {
    color: '#3d319d',
  },
}));

const BlockedTransfers = (props) => {
  const { blockedTransfers } = props;
  const classes = useStyles();

  return (
    <div>
      <MaterialTable
        title={
          <Typography variant="h5" className={classes.heading}>
            LISTES DES OPÉRATIONS SORTANTES BLOQUÉES
          </Typography>
        }
        columns={[
          {
            title: '_id',
            field: '_id',
            // <Link to={`/userdetails/${user._id}`}>
            //                   {user._id}
            //                 </Link>
            // eslint-disable-next-line react/display-name
            render: (blockedTransfer) => <>{blockedTransfer._id}</>,
          },
          {
            title: 'Montant',
            field: 'amount',
            // eslint-disable-next-line react/display-name
            render: (blockedTransfer) => <>{blockedTransfer.amount}</>,
          },
          {
            title: 'Date de création',
            field: 'created_at',
            // eslint-disable-next-line react/display-name
            render: (blockedTransfer) => <>{blockedTransfer.created_at}</>,
          },
          {
            title: 'Devise',
            field: 'currency',
            // eslint-disable-next-line react/display-name
            render: (blockedTransfer) => <>{blockedTransfer.currency}</>,
          },
          {
            title: "Date d'exécution",
            field: 'execution date',
            // render: (user) => <>{user.user_id}</>,
          },
        ]}
        data={blockedTransfers}
      />
    </div>
  );
};

BlockedTransfers.displayName = 'BlockedTransfers';

BlockedTransfers.propTypes = {
  blockedTransfers: PropTypes.object,
};

export default BlockedTransfers;
