import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  selectContainer: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '4px',
    height: '40px',
  },

  selecteTitle: {
    marginTop: '3%',
    fontSize: '1em',
    color: '#8E8FA1',
    textAlign: 'left',
  },
}));

const SmallSelect = ({ title, options, value, name, style }) => {
  const classes = useStyles();
  const { handleChange } = useFormikContext();

  return (
    <>
      {title && <h2 className={classes.selecteTitle}>{title}</h2>}

      <Select
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        name={name}
        className={classes.selectContainer}
        style={style}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

SmallSelect.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  name: PropTypes.string,
  style: PropTypes.object,
};

export default SmallSelect;
