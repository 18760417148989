import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const validationSchema = ({ t }) =>
  yup.object({
    display: yup.string().required(t('forms.mandatory_field')),
    description: yup.string().required(t('forms.mandatory_field')),
    helptext: yup.string(),
    hasProvider: yup.boolean(),
    isVatRates: yup.boolean(),
    intracom: yup.boolean(),
    importation: yup.boolean(),
    ss: yup.boolean(),
    tvaAccounting: yup.array().when('isVatRates', {
      is: true,
      then: yup.array().of(
        yup.object().shape({
          vatRateId: yup.string().required(t('forms.mandatory_field')),
          accountNumber: yup
            .number(t('forms.mandatory_field'))
            .required(t('forms.mandatory_field')),
        })
      ),
      otherwise: yup.array().strip(),
    }),
    intracomAccounting: yup.array().when('intracom', {
      is: true,
      then: yup.array().of(
        yup.object().shape({
          vatRateId: yup.string().required(t('forms.mandatory_field')),
          accountNumber: yup
            .number(t('forms.mandatory_field'))
            .required(t('forms.mandatory_field')),
        })
      ),
      otherwise: yup.array().strip(),
    }),
    importationAccounting: yup.array().when('importation', {
      is: true,
      then: yup.array().of(
        yup.object().shape({
          vatRateId: yup.string().required(t('forms.mandatory_field')),
          accountNumber: yup
            .number(t('forms.mandatory_field'))
            .required(t('forms.mandatory_field')),
        })
      ),
      otherwise: yup.array().strip(),
    }),
    ssValue: yup.array().of(
      yup.object().shape({
        accountNumber: yup
          .number(t('forms.mandatory_field'))
          .required(t('forms.mandatory_field')),
      })
    ),
  });
