import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Paper, Snackbar } from '@material-ui/core';
import CompanyService from 'services/CompanyService';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
  DISCOVERY_PACKAGE,
  SUBSCRIPTION_TYPE,
  TIME_DISABLED_DOWNGRADE_SUB,
} from 'constants/companySubscription';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import SubscriptionInfo from './SubscriptionInfo';
import SubscriptionHistory from './SubscriptionHistory';
import PaymentLinkInfo from './PaymentLinkInfo';
import ConfirmDowngrade from './Dialog/ConfirmDowngrade';
import useStyles from './styles';

const CompanySubscription = ({ companyId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    user: { _id: userId },
  } = useSelector((state) => state.loggedUser);
  const [isSubmit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paymentLinkLimit, setPaymentLinkLimit] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // variables handle subscription
  const [companySubscription, setCompanySubscription] = useState(null);
  const [historySubscription, setHistorySubscription] = useState([]);

  // call api
  const fetchCompanySubscription = async () => {
    try {
      setLoading(true);
      const data = await CompanyService.fetchCompanySubscription(companyId);
      setCompanySubscription(data?.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  const fetchHistorySubscription = async () => {
    try {
      setLoading(true);
      const data = await CompanyService.fetchHistorySubscription(companyId);
      setHistorySubscription(data?.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const updatePaymentLinkLimit = async () => {
    try {
      setLoading(true);
      const dataUpdatePaymentLink = {
        companyId,
        paymentLinkLimit,
        userId,
      };
      const res = await CompanyService.updatePaymentLinkLimit(
        dataUpdatePaymentLink
      );
      if (res) {
        await fetchCompanySubscription();
        await fetchHistorySubscription();
        setSuccess(true);
        setSubmit(false);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  const downgradeSubscription = async () => {
    try {
      setLoading(true);
      const dataDowngradeSubscription = {
        companyId,
        userId,
      };
      const res = await CompanyService.downgradeSubscription(
        dataDowngradeSubscription
      );
      if (res) {
        await fetchCompanySubscription();
        await fetchHistorySubscription();
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  // logic

  const companyWaitUpdate = () => {
    return companySubscription?.isChanged;
  };
  const companyWaitCancel = () => {
    return companySubscription?.isCancel;
  };

  const companyIsFreeSub = () => {
    return companySubscription?.name === DISCOVERY_PACKAGE;
  };

  // Downgrade subscription time within 14 days from subscription start date
  const checkDowngradeSubscription = () => {
    if (companySubscription.startDate) {
      const startDate = new Date(companySubscription.startDate);
      const now = new Date();
      const subtractDate = Math.floor((now - startDate) / 1000 / 60 / 60 / 24);
      return subtractDate <= TIME_DISABLED_DOWNGRADE_SUB;
    }
    return false;
  };

  const checkSubscriptionTypeIsPaidTier = () => {
    return companySubscription.subscriptionType === SUBSCRIPTION_TYPE.PAID_TIER;
  };

  const handleChangeQuota = () => {
    updatePaymentLinkLimit();
  };

  const handleDowngradeSubscription = () => {
    setOpenConfirmDialog(false);
    downgradeSubscription();
  };

  const renderSuccessNotify = () => {
    setTimeout(() => {
      setSuccess(false);
    }, 2500);
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity="success">
          {t('company.subscription.submitted_successfully')}
        </Alert>
      </Snackbar>
    );
  };

  const renderNotification = () => {
    if (companySubscription) {
      if (companyWaitCancel() || companyWaitUpdate())
        return t('company.subscription.wait_update_noti');
      if (!checkDowngradeSubscription())
        return t('company.subscription.disable_action_noti');
    }
    return '';
  };

  const disableActionSave = () => {
    return (
      !isSubmit ||
      companyWaitCancel() ||
      companyWaitUpdate() ||
      !checkDowngradeSubscription()
    );
  };

  const disableActionDowngrade = () => {
    return (
      companyIsFreeSub() ||
      companyWaitCancel() ||
      companyWaitUpdate() ||
      !checkDowngradeSubscription() ||
      !checkSubscriptionTypeIsPaidTier()
    );
  };

  const disableChangePaymentLinkInfo = () => {
    return (
      companyWaitCancel() ||
      companyWaitUpdate() ||
      !checkDowngradeSubscription()
    );
  };

  // fetch init values subscription
  useEffect(() => {
    if (companyId) {
      fetchCompanySubscription();
      fetchHistorySubscription();
    }
  }, [companyId]);

  return (
    <Paper elevation={3} className={classes.companyInfoContainer}>
      {success && renderSuccessNotify()}
      {loading && (
        <div className={classes.loadingBox}>
          <CircularProgress
            color="primary"
            className={classes.customCircular}
          />
        </div>
      )}

      {!loading && companySubscription && (
        <>
          <Typography variant="caption" color="error">
            {renderNotification()}
          </Typography>
          <SubscriptionInfo companySubscription={companySubscription} />
          <PaymentLinkInfo
            companySubscription={companySubscription}
            translate={t}
            setPaymentLinkLimit={setPaymentLinkLimit}
            setSubmit={setSubmit}
            readOnly={disableChangePaymentLinkInfo()}
          />
          <br></br>
          <div className={classes.actionsWapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveBtn}
              disabled={disableActionSave()}
              onClick={handleChangeQuota}
            >
              {t('company.subscription.save')}
            </Button>
            <Button
              variant="contained"
              className={classes.downgradeSubBtn}
              disabled={disableActionDowngrade()}
              onClick={() => setOpenConfirmDialog(true)}
            >
              {t('company.subscription.downgradeSub')}
            </Button>
          </div>

          <ConfirmDowngrade
            open={openConfirmDialog}
            translate={t}
            handleClose={() => {
              setOpenConfirmDialog(false);
            }}
            handleConfirm={handleDowngradeSubscription}
          />
          <SubscriptionHistory
            subscriptionHistory={historySubscription}
            translate={t}
          />
        </>
      )}
    </Paper>
  );
};

CompanySubscription.propTypes = {
  companyId: PropTypes.string,
};

export default CompanySubscription;
