/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core';
import CustomDialog from 'components/CustomDialog';
import VatActions from 'actions/VatActions';
import spinnerService from 'services/SpinnerService';
import categoryService from '../../services/CategoryService/index';
import CategoryForm from './Form/Form';
import { store } from '../../store';

const options = {
  download: true,
  print: false,
  filter: false,
  filterType: 'checkbox',
  selectableRows: 'none',
};

const useStyles = makeStyles(() => ({
  editIcon: {
    borderRadius: 100,
    cursor: 'pointer',
    padding: '4px',
    width: 28,
    height: 28,
    color: 'gray',
    '&:hover': {
      background: '#D3D3D3',
      color: 'black',
    },
  },
}));

function mapListCategories(category) {
  const res = {
    id: category._id,
    display: category.display,
    account: category.account,
    form: category.form || null,
    parent: category?.parentData?.display,
  };

  return res;
}

const Categories = () => {
  const classes = useStyles();

  const [showDialog, setShowDialog] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [defaultCategories, setDefaultCategories] = React.useState([]);
  const [selectedCate, setSelectedCate] = React.useState(false);

  const { user } = store.getState().loggedUser;
  const { t } = useTranslation();

  const columns = [
    { label: t('categories.table.parent'), name: 'parent' },
    { label: t('categories.display'), name: 'display' },
    {
      label: t('categories.account'),
      name: 'account',
    },
    {
      label: t('categories.form'),
      name: 'form',
    },
    {
      label: ' ',
      name: 'id',
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        search: false,
        customBodyRender: (value) => {
          return (
            <EditIcon
              sm
              className={classes.editIcon}
              onClick={() => {
                setShowDialog(true);
                setSelectedCate(value);
              }}
            />
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    const getVatRates = async () => {
      await VatActions.fetchVATRates();
    };
    getVatRates();
  }, []);

  const getCategories = async () => {
    try {
      spinnerService.startSpinner();
      const res = await categoryService.fetchCategories();
      if (res.status === 200) {
        const listCategories = _.map(res?.data)?.filter((item) => item.account);

        const formatCategories = listCategories
          ?.map((item) => mapListCategories(item))
          ?.sort((item1, item2) => item1.parent.localeCompare(item2.parent));

        // split record if account is an array
        const splitCategories = _.flatMap(formatCategories, (item) => {
          if (_.isString(item.account)) {
            return [item];
          }
          if (_.isArray(item.account)) {
            return item.account.map((subItem) => ({
              ...item,
              account: subItem.account,
            }));
          }
          return [];
        });

        setDefaultCategories(listCategories);
        setCategories(splitCategories);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      spinnerService.endSpinner();
    }
  };

  useEffect(() => {
    if (user?._id) getCategories();
  }, [user?._id]);

  const selectedCateInfo = React.useMemo(() => {
    if (selectedCate) {
      return defaultCategories.find((item) => item._id === selectedCate);
    }

    return null;
  }, [selectedCate]);

  function resetModal() {
    setShowDialog(false);
    setSelectedCate(null);
  }

  function renderFormDialog() {
    if (showDialog) {
      return (
        <CustomDialog
          title={t('categories.dialog_title')}
          closeDialog={resetModal}
          iconClose
          color="secondary"
          open={showDialog}
          disableBackdropClick
        >
          <CategoryForm
            closeCategoryFormDialog={resetModal}
            showDialog={showDialog}
            selectedCateInfo={selectedCateInfo}
            open={showDialog}
            getCategories={getCategories}
          />
        </CustomDialog>
      );
    }

    return null;
  }

  return (
    <div>
      {renderFormDialog()}
      <MUIDataTable
        title={t('categories.table.title')}
        data={categories}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Categories;
