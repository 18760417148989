import { useCallback, useLayoutEffect, useState } from 'react';

const useLoadMore = (tableRef, { loading, hasMore }, callback) => {
  const [distanceBottom, setDistanceBottom] = useState(0);

  const scrollListener = useCallback(async () => {
    const bottom =
      tableRef.current.scrollHeight - tableRef.current.clientHeight;
    // if you want to change distanceBottom every time new data is loaded
    // don't use the if statement
    if (!distanceBottom) {
      // calculate distanceBottom that works for you
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (
      tableRef.current.scrollTop > bottom - distanceBottom &&
      hasMore &&
      !loading
    ) {
      await callback();
    }
  }, [hasMore, callback, loading, distanceBottom]);

  useLayoutEffect(() => {
    const _tableRef = tableRef.current;
    _tableRef.addEventListener('scroll', scrollListener);
    return () => {
      _tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  return scrollListener;
};

export default useLoadMore;
