import React from 'react';
import GenerateField from 'components/GenerateField';
import SmallTextField from 'components/SmallTextField';
import PropTypes from 'prop-types';

const EndOfFiscalYear = ({ values }) => {
  const fiedls = [
    {
      component: SmallTextField,
      componentProps: {
        value: values.endFiscalYear,
      },
    },
  ];

  return (
    <GenerateField
      name="endFiscalYear"
      configs={fiedls}
      label="company.end_fiscal_year"
      readOnly
    />
  );
};

EndOfFiscalYear.propTypes = {
  values: PropTypes.object,
};
export default EndOfFiscalYear;
