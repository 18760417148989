import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconButton, makeStyles } from '@material-ui/core';
import { FilterNone as FilterNoneIcon } from '@material-ui/icons';

import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  tooltip: {
    color: 'white',
    backgroundColor: '#3f51b5',
    padding: '2px 10px',
    borderRadius: '10px',
    display: 'block',
    position: 'absolute',
    right: 0,
    top: -30,
  },
  clipboard: { position: 'relative' },
  button: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    // height: '0em',
    // width: '0em',
  },
  copyIcon: {
    color: 'blue',
  },
}));

const Clipboard = ({ valueToCopy }) => {
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  return (
    <span className={classes.clipboard}>
      <CopyToClipboard
        text={valueToCopy}
        onCopy={() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1500);
        }}
      >
        <IconButton aria-label="copy" size="small">
          <FilterNoneIcon fontSize="inherit" />
        </IconButton>
      </CopyToClipboard>
      {copied ? <span className={classes.tooltip}>Copié</span> : null}
    </span>
  );
};

Clipboard.propTypes = {
  valueToCopy: PropTypes.string,
};

export default Clipboard;
