export const SOCIÉTE = 'société';
export const AUTOENTREPRENEUR = 'autoEntrepreneur';
export const ASSOCIATION = 'association';
export const ENTREPRISEINDIVIDUELLE = 'entrepriseIndividuelle';

export const EURL = 'eurl';
export const SARL = 'sarl';
export const SAS = 'sas';
export const SASU = 'sasu';
export const SCI = 'sci';

export const TAXREGISTRATION = {
  tva: [
    {
      label: 'company.vat_info.yes',
      value: true,
    },
    {
      label: 'company.vat_info.no',
      value: false,
    },
  ],
  periodicVATReturns: [
    {
      label: 'company.periodic_vat_returns.monthly',
      value: 'monthly',
    },
    {
      label: 'company.periodic_vat_returns.quaterly',
      value: 'quaterly',
    },
    {
      label: 'company.periodic_vat_returns.yearly',
      value: 'yearly',
    },
  ],
  returnOnDebit: [
    {
      label: 'company.return_on_debit.yes',
      value: true,
    },
    {
      label: 'company.return_on_debit.no',
      value: false,
    },
  ],
  bookKeeping: [
    {
      label: 'company.book_keeping.cash',
      value: 'cash',
    },
    {
      label: 'company.book_keeping.commitment',
      value: 'commitment',
    },
  ],
  companyType: [
    {
      label: 'company.type_of_company.auto_entrepreneur',
      value: 'Auto-entrepreneur',
    },
    {
      label: 'company.type_of_company.entrepreneur_individuel',
      value: 'Entrepreneur individuel',
    },
    {
      label: 'company.type_of_company.société',
      value: 'Société',
    },
  ],
  cga: [
    {
      label: 'company.cga_info.yes',
      value: true,
    },
    {
      label: 'company.cga_info.no',
      value: false,
    },
  ],
  microOptions: [
    {
      label: 'company.micro_company.yes',
      value: true,
    },
    {
      label: 'company.micro_company.no',
      value: false,
    },
  ],
};

export const VAT_SIMPLIFIED = 'vat_simplified';
export const VAT_MINI_REAL = 'vat_mini_real';
export const VAT_REAL_NORMAL = 'vat_real_normal';
export const REGIME_VAT_OPTIONS = [
  {
    label: 'Régime simplifié - déclaration annuelle',
    value: VAT_SIMPLIFIED,
  },
  {
    label: 'Régime mini-réel - déclaration trimestrielle',
    value: VAT_MINI_REAL,
  },
  {
    label:
      'Régime réel normal - déclaration mensuelle (obligatoire en cas d’achat intracommunautaire)',
    value: VAT_REAL_NORMAL,
  },
];
