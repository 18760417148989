const styles = () => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#f4f4f4',
    margin: '2vw',
    padding: '2vw',
  },
  table: {
    margin: '0 auto',
    width: '100%',
  },
});

export default styles;
