/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ResultTable = ({ result }) => {
  const classes = useStyles();

  const { data } = result;

  return (
    // <Paper>
    //   {data} {message}
    // </Paper>
    <div>
      {result && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Deletion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{result.message}</TableCell>
              </TableRow>
              {data.map((res, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {res}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

ResultTable.propTypes = {
  result: PropTypes.object,
};

export default ResultTable;
