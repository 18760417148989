/**
 * Sort vatRates in ascending order and change display VAT
 * @param {*} vatrates
 * @returns Array
 */
const formatVATRates = (vatrates) => {
  if (!vatrates) return [];
  vatrates?.sort((currentVAT, nextVAT) => currentVAT.value - nextVAT.value);
  return vatrates.map((item) => ({
    ...item,
    display: item.value !== 0 ? `${item.value} %` : item.description,
  }));
};

export default formatVATRates;
