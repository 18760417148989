import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAuth0 } from 'auth/react-auth0-spa';
import logo from 'assets/images/octopus.svg';

// import HomePage from 'views/Home';
import LoginPage from 'views/Login';
import UserListView from 'views/userlist/UserListView';
import UserDetails from 'views/userdetails/UserDetails';
import CompanyDetails from 'views/companydetails/CompanyDetails';
import LogoutView from 'views/Logout';
import CompanyListPage from 'views/CompanyList';
import TreezorWebHooks from 'views/treezorwebhooks/TreezorWebHooks';
import TreezorWebHookDetails from 'views/treezorwebhooks/TreezorWebHookDetails';
import BankTransferDetails from 'views/banktransfer/BankTransferDetails';
import CreationCompany from 'views/CreationCompany/CreationCompany';
import ReviewDocuments from 'views/CreationCompany/ReviewDocuments';
import CreationFormData from 'views/CreationCompany/CreationFormData';
import CompanyCloseAccount from 'views/company/CompanyCloseAccount';
import UploadDocument from 'components/CreationCompany/UploadDocument';
import Notification from 'components/NotificationIcon';

import SEPAFormView from 'views/SEPAManagement/pages/SEPAFormView';
import SEPAFormList from 'views/SEPAManagement/pages/SEPAFormList';

import {
  Menu as MenuIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  Fingerprint as FingerprintIcon,
  Error as ErrorIcon,
  ContactMail as ContactMailIcon,
  ExitToApp,
  AccountBalanceWalletOutlined,
  CategoryOutlined,
} from '@material-ui/icons';

import SEPAErrors from 'components/SEPAerrors';

import { useSelector } from 'react-redux';
import BridgeWebhooks from 'views/BridgeWebhooks';
import BridgeWebhookDetails from 'views/BridgeWebhooks/details';
import Categories from 'views/Categories';

import PrivateRoute from './PrivateRoute';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const NavBars = [
  {
    text: 'Company',
    uri: 'companylist',
    icon: <BusinessIcon />,
  },
  {
    text: 'User',
    uri: 'userlist',
    icon: <PeopleIcon />,
  },
  {
    text: 'Treezor webhook',
    uri: 'webhooks',
    icon: <FingerprintIcon />,
  },
  {
    text: 'Bridge webhook',
    uri: 'bridge-webhooks',
    icon: <AccountBalanceWalletOutlined />,
  },
  {
    text: 'Sepa errors',
    uri: 'SEPAErrors',
    icon: <ErrorIcon />,
  },
  {
    text: 'Sepa Management',
    uri: 'sepa/management/forms',
    icon: <ContactMailIcon />,
  },
  {
    text: 'Categories',
    uri: 'categories',
    icon: <CategoryOutlined />,
  },
];

const AppRoutes = (props) => {
  const { container } = props;
  const { logout } = useAuth0();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const appTitle = useSelector((state) => state.app.title);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img
          style={{
            width: 80,
            padding: 5,
            display: 'block',
            margin: 'auto',
          }}
          src={logo}
          alt="logo of a company"
        />
      </div>
      <Divider />
      <List>
        {NavBars.map((navbar) => (
          <ListItem
            className={classes.link}
            key={navbar.text}
            component={Link}
            to={`/${navbar.uri}`}
          >
            <ListItemIcon>{navbar.icon}</ListItemIcon>
            <ListItemText primary={navbar.text} />
          </ListItem>
        ))}
        <ListItem className={classes.link} button onClick={logoutWithRedirect}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <PrivateRoute path="/logout" component={LogoutView} />
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {appTitle}
              </Typography>
              <div className={classes.grow} />
              <Notification />
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {/* <PrivateRoute path="/home" component={HomePage} /> */}
            <PrivateRoute path="/companylist" component={CompanyListPage} />
            <PrivateRoute
              path="/companydetails/:_id"
              component={CompanyDetails}
            />
            <PrivateRoute
              exact
              path="/creationcompany/:_id"
              component={CreationCompany}
            />
            <PrivateRoute path="/reviewdocs/:_id" component={ReviewDocuments} />
            <PrivateRoute
              path="/creationformdata/:_id"
              component={CreationFormData}
            />
            <PrivateRoute
              path="/uploaddocument/:docTitle/:companyOId"
              component={UploadDocument}
            />
            <PrivateRoute
              path="/company-details/close-account/:companyId"
              component={CompanyCloseAccount}
            />
            <PrivateRoute path="/userlist" component={UserListView} />
            <PrivateRoute path="/userdetails/:_id" component={UserDetails} />
            <PrivateRoute
              path="/banktransfer/:payoutOId"
              component={BankTransferDetails}
            />
            <PrivateRoute exact path="/webhooks" component={TreezorWebHooks} />
            <PrivateRoute
              path="/webhooks/:webhookId"
              component={TreezorWebHookDetails}
            />
            <PrivateRoute
              exact
              path="/bridge-webhooks"
              component={BridgeWebhooks}
            />
            <PrivateRoute
              path="/bridge-webhooks/:id"
              component={BridgeWebhookDetails}
            />

            <PrivateRoute path="/SEPAErrors" component={SEPAErrors} />
            <PrivateRoute
              path="/sepa/management/forms"
              component={SEPAFormList}
            />
            <PrivateRoute
              path="/sepa/management/request/:requestId"
              component={SEPAFormView}
            />
            <PrivateRoute path="/categories" component={Categories} />
          </main>
        </div>
      </Switch>
    </BrowserRouter>
  );
};

AppRoutes.propTypes = {
  container: PropTypes.element,
};

export default AppRoutes;
