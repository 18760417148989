import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ConfirmDowngrade = ({ open, handleClose, handleConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="confirm-downgrade-sub"
      aria-describedby="confirm-downgrade-sub-description"
    >
      <DialogTitle id="confirm-downgrade-sub-title">
        {t('company.subscription.title_confirm_dialog')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          {t('company.subscription.confirm')}
        </Button>
        <Button onClick={handleClose} variant="contained">
          {t('company.subscription.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDowngrade.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};
export default ConfirmDowngrade;
