/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, Button, Typography, Snackbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import bridgeWebhookActions from 'actions/BridgeWebhookActions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  backBtn: {
    marginRight: 20,
    textDecoration: 'none',
  },
  screenTitle: {
    fontSize: 25,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

const BridgeWebhookDetails = (props) => {
  const classes = useStyles();
  const { id: webhookId } = props.match.params;
  const error = useSelector((state) => state.webhook.error);

  const [webhook, setWebhook] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await bridgeWebhookActions.fetchBridgeWebhookDetails(
        webhookId
      );
      if (result.errors) {
        setWebhook('Empty');
        setOpen(true);
      } else {
        setWebhook(result);
      }
    })();
  }, []);

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    bridgeWebhookActions.removeError();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Box display="flex" alignItems="center" mb={5}>
          <Link to="/bridge-webhooks" className={classes.backBtn}>
            <Button variant="contained" color="primary">
              Back
            </Button>
          </Link>
          {webhook && (
            <Typography className={classes.screenTitle}>
              {webhook.webhook}
            </Typography>
          )}
        </Box>
        {webhook && (
          <Grid item xs={12}>
            <pre>{JSON.stringify(webhook, null, 2)}</pre>
          </Grid>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {error ? error.message : ''}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BridgeWebhookDetails;
