import {
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILED,
  UPDATE_COMPANY,
  LOGOUT_USER_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  FETCH_CREA_FORM_SUCCESS,
  FETCH_CREA_FORM_FAILURE,
  UPDATE_CREA_FORM_SUCCESS,
  GENERATE_ALL_DOCS_SUCCESS,
  GENERATE_ALL_DOCS_PENDING,
} from 'actions/Types';

const INITIAL_STATE = {
  companies: [],
  isFetchingCompanies: false,
  errorFetchingCompanies: false,
  erroFetchingingCompaniesMessage: null,
  isDeletingCompany: false,
  errorDeletingCompany: false,
  errorDeletingCompanyMessage: null,
  companyDeleteResult: [],
  creaFormData: {},
  errorFetchCreaForm: false,
  errorUpdateCreaForm: false,
  generatingAllDocs: false,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_COMPANIES:
      return {
        ...state,
        isFetchingCompanies: true,
        errorFetchingCompanies: false,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload.docs || [],
        page: action.payload.page,
        totalpage: action.payload.totalDocs,
        isFetchingCompanies: false,
      };
    case FETCH_COMPANIES_FAILED:
      return {
        ...state,
        isFetchingCompanies: false,
        errorFetchingCompanies: true,
        erroFetchingingCompaniesMessage: action.payload,
      };
    case UPDATE_COMPANY: {
      const index = state.companies.findIndex(
        (c) => c._id === action.payload.id
      );

      const { companies } = state;
      companies[index] = action.payload.data;

      return {
        ...state,
        companies,
      };
    }
    case DELETE_COMPANY: {
      return {
        ...state,
        isDeletingCompany: true,
        errorDeletingCompany: false,
        companyDeleteResult: [],
      };
    }
    case DELETE_COMPANY_SUCCESS: {
      const index = state.companies.findIndex(
        (c) => c._id === action.payload.id
      );
      const companies = [...state.companies];
      companies.splice(index, 1);
      return {
        ...state,
        companies,
        isDeletingCompany: false,
        companyDeleteResult: action.payload.data,
      };
    }
    case DELETE_COMPANY_FAILURE: {
      return {
        ...state,
        isDeletingCompany: false,
        errorDeletingCompany: true,
        errorDeletingCompanyMessage: action.payload.error,
      };
    }
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    case FETCH_CREA_FORM_SUCCESS: {
      return {
        ...state,
        creaFormData: action.payload,
        errorFetchCreaForm: false,
      };
    }
    case UPDATE_CREA_FORM_SUCCESS: {
      return {
        ...state,
        creaFormData: action.payload,
        errorFetchCreaForm: false,
      };
    }
    case GENERATE_ALL_DOCS_PENDING: {
      return {
        ...state,
        generatingAllDocs: true,
      };
    }
    case GENERATE_ALL_DOCS_SUCCESS: {
      return {
        ...state,
        creaFormData: { ...state.creaFormData, documents: action.payload },
        generatingAllDocs: false,
      };
    }
    case FETCH_CREA_FORM_FAILURE: {
      return {
        ...state,
        creaFormData: {},
        errorUpdateCreaForm: action.payload,
      };
    }
    default:
      return state;
  }
};
