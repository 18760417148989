import companyService from 'services/CompanyService';
import CreationCompanyService from 'services/CreationCompanyService';
import {
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILED,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  FETCH_CREA_FORM_FAILURE,
  FETCH_CREA_FORM_SUCCESS,
  UPDATE_CREA_FORM_FAILURE,
  UPDATE_CREA_FORM_SUCCESS,
  GENERATE_ALL_DOCS_SUCCESS,
  GENERATE_ALL_DOCS_PENDING,
} from 'actions/Types';
import { ERROR, SUCCESS } from 'constants/severity';
import AppActions from 'actions/AppActions';

const fetchCompaniesFailed = (payload) => ({
  type: FETCH_COMPANIES_FAILED,
  payload:
    payload || new Error('An error has occured while retrieving the user'),
});

const fetchCompaniesSuccess = (payload) => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload,
});

/* eslint-disable-next-line */
export const fetchAllCompanies = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_COMPANIES });
    const result = await companyService.fetchCompanies();

    if (result && result.data) {
      dispatch(fetchCompaniesSuccess(result.data));
    } else {
      dispatch(fetchCompaniesFailed());
    }
  } catch (error) {
    dispatch(fetchCompaniesFailed(error));
  }
};

/* eslint-disable-next-line */
export const updateCompany = (values) => async (dispatch) => {
  try {
    let { suspended } = values;

    suspended = !suspended;

    const companyData = { ...values, suspended };
    const { data } = await companyService.updateCompany(companyData);

    dispatch({ type: UPDATE_COMPANY, payload: { id: values._id, data } });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error: ', error);
  }
};

export const deleteCompany = (companyId) => async (dispatch) => {
  dispatch({ type: DELETE_COMPANY });
  companyService
    .deleteCompany(companyId)
    .then((res) => {
      dispatch({
        type: DELETE_COMPANY_SUCCESS,
        payload: { id: companyId, data: res.data.data },
      });
    })
    .catch((error) => {
      dispatch({ type: DELETE_COMPANY_FAILURE, payload: { error } });
    });
};

// /---------------/ //

// TODO: decicated action file

const fetchCreationCompanyFormFailure = (payload) => ({
  type: FETCH_CREA_FORM_FAILURE,
  payload:
    payload ||
    new Error(
      'An error has occured while retrieving the creation company form'
    ),
});

const fetchCreationCompanyFormSuccess = (payload) => ({
  type: FETCH_CREA_FORM_SUCCESS,
  payload,
});

export const fetchCreationCompanyForm = (companyId) => async (dispatch) => {
  let result;

  try {
    result = await CreationCompanyService.fetchCreationCompany(companyId);

    if (result && result.data) {
      dispatch(fetchCreationCompanyFormSuccess(result.data));
    } else {
      dispatch(fetchCreationCompanyFormFailure());
    }
  } catch (error) {
    dispatch(fetchCreationCompanyFormFailure(error));
  }

  return result;
};

const updateCreationCompanyFormFailure = (payload) => ({
  type: UPDATE_CREA_FORM_FAILURE,
  payload:
    payload ||
    new Error('An error has occured while updating the creation company form'),
});

export const updateCreationCompanyFormSuccess = (payload) => ({
  type: UPDATE_CREA_FORM_SUCCESS,
  payload,
});

export const updateCreaForm = (companyId, formId, data) => async (dispatch) => {
  try {
    const result = await CreationCompanyService.updateCreaFormData(
      companyId,
      formId,
      data
    );

    if (result && result.data) {
      dispatch(updateCreationCompanyFormSuccess(result.data));
    } else {
      dispatch(updateCreationCompanyFormFailure());
    }
  } catch (error) {
    dispatch(updateCreationCompanyFormFailure(error));
  }
};

export const recreateStatutes = (companyId) => async (dispatch) => {
  try {
    let result = await CreationCompanyService.recreateStatutesDocument(
      companyId
    );
    result = await CreationCompanyService.fetchCreationCompany(companyId);

    if (result && result.data) {
      dispatch(fetchCreationCompanyFormSuccess(result.data));
    } else {
      dispatch(fetchCreationCompanyFormFailure());
    }
  } catch (error) {
    dispatch(updateCreationCompanyFormFailure(error));
  }
};

export const sendFinalEmailToClient = (creationCompany) => async (dispatch) => {
  const { companyId, _id } = creationCompany;
  const res = {
    ...creationCompany,
    stateOfClientFolder: {
      ...creationCompany.stateOfClientFolder,
      stepper: 4,
      finalEmailSent: true,
    },
  };
  try {
    await CreationCompanyService.sendFinalEmail(companyId);
    dispatch(updateCreaForm(companyId, _id, res));
  } catch (e) {
    dispatch(
      AppActions.setSackbarMessage(
        "Erreur lors de l'envoie du mail au client.",
        ERROR
      )
    );
  }
};

export const notaryEmailSuccess = (creationCompany) => async (dispatch) => {
  const { companyId, _id } = creationCompany;
  const res = {
    ...creationCompany,
    stateOfClientFolder: {
      ...creationCompany.stateOfClientFolder,
      sentToTheNotary: true,
    },
  };
  dispatch(updateCreaForm(companyId, _id, res));
  dispatch(AppActions.setSackbarMessage('Email envoyé au notaire.', SUCCESS));
};

export const sendDocSignSuccess = (creationCompany) => async (dispatch) => {
  const { companyId, _id } = creationCompany;
  const res = {
    ...creationCompany,
    stateOfClientFolder: {
      ...creationCompany.stateOfClientFolder,
      sentToDocusign: true,
      stepper: 3,
    },
  };
  dispatch(updateCreaForm(companyId, _id, res));
  dispatch(
    AppActions.setSackbarMessage('Documents envoyés via DocuSign.', SUCCESS)
  );
};

export const saveLegalNotiveDone = (creationCompany) => async (dispatch) => {
  const { companyId, _id } = creationCompany;
  const res = {
    ...creationCompany,
    stateOfClientFolder: {
      ...creationCompany.stateOfClientFolder,
      sentTolegalNotice: true,
    },
  };
  dispatch(updateCreaForm(companyId, _id, res));
  dispatch(AppActions.setSackbarMessage('Annonce légale effectué.', SUCCESS));
};

export const reactivateSaveStatutsDate = (creationCompany) => async (
  dispatch
) => {
  const { companyId, _id } = creationCompany;
  const res = {
    ...creationCompany,
    stateOfClientFolder: {
      ...creationCompany.stateOfClientFolder,
      dateOfSignatureSaved: false,
    },
  };
  dispatch(updateCreaForm(companyId, _id, res));
};

export const saveFoundDepositDate = (
  creationCompany,
  // eslint-disable-next-line
  date_depot_des_fonds
) => async (dispatch) => {
  try {
    const res = {
      date_depot_des_fonds,
      stateOfClientFolder: {
        dateFoundDepositSaved: true,
      },
    };

    dispatch(
      updateCreaForm(creationCompany.companyId, creationCompany._id, res)
    );
    dispatch(
      AppActions.setSackbarMessage(
        'Date de dépôt des fonds enregistrée.',
        SUCCESS
      )
    );
  } catch (e) {
    dispatch(
      AppActions.setSackbarMessage(
        'Erreur lors de la sauvegarde de la date de dépôt des fonds.',
        ERROR
      )
    );
  }
};

export const generateAllDocs = (companyData) => async (dispatch) => {
  dispatch({
    type: GENERATE_ALL_DOCS_PENDING,
  });
  try {
    const { alternativeId, companyId } = companyData;
    const res = await CreationCompanyService.genarateDocs(
      companyData,
      alternativeId,
      companyId
    );
    dispatch({
      type: GENERATE_ALL_DOCS_SUCCESS,
      payload: res.data,
    });
    // dispatch(
    //   AppActions.setSackbarMessage(
    //     'Géneration des documents terminés.',
    //     SUCCESS
    //   )
    // );
  } catch (e) {
    dispatch(
      AppActions.setSackbarMessage(
        'Erreur lors de la géneration des documents.',
        ERROR
      )
    );
  }
};
