/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import GenerateField from 'components/GenerateField';
import PropTypes from 'prop-types';
import ViewImage from 'components/ViewImage';
import S3FileService from 'services/S3FileService';

const LogoCompany = ({ values }) => {
  const { filename } = values;
  const [fiedls, setfiedls] = useState({
    component: ViewImage,
    componentProps: {
      src: null,
      alt: 'Logo Company',
    },
  });
  const fetchLogoCompany = async () => {
    const res = await S3FileService.geturl(filename);
    setfiedls({
      ...fiedls,
      componentProps: {
        src: res.url,
      },
    });
  };
  useEffect(() => {
    if (values?.filename) {
      fetchLogoCompany();
    }
  }, [values?.filename]);
  return fiedls.componentProps.src ? (
    <GenerateField
      name="logoCompany"
      configs={[fiedls]}
      label="company.logo"
      labelConfig={{ align: 'center' }}
    />
  ) : null;
};

LogoCompany.propTypes = {
  values: PropTypes.object,
};
export default LogoCompany;
