import NotificationService from 'services/notificationService';
import {
  START_PROCESS_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  // GET_NOTIFICATION_ERROR,
  SET_SNACK_MESSAGE,
} from 'actions/Types';
import { ERROR } from 'constants/severity';

import { store } from '../../store';

const getNotification = async () => {
  try {
    store.dispatch({
      type: START_PROCESS_NOTIFICATION,
    });
    const result = await NotificationService.getNotification();
    if (result) {
      const notification = result.data;
      store.dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        payload: notification,
      });
      return notification;
    }
  } catch (error) {
    store.dispatch({
      type: SET_SNACK_MESSAGE,
      payload: {
        message: error.response?.data || error.message,
        severity: ERROR,
      },
    });
  }
  return null;
};

const deleteNotification = async (notificationId) => {
  try {
    store.dispatch({
      type: START_PROCESS_NOTIFICATION,
    });
    const result = await NotificationService.deleteNotification(notificationId);
    if (result) {
      return result;
    }
  } catch (error) {
    store.dispatch({
      type: SET_SNACK_MESSAGE,
      payload: {
        message: error.response?.data || error.message,
        severity: ERROR,
      },
    });
  }
  return null;
};

export default {
  getNotification,
  deleteNotification,
};
