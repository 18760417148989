/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fetchPayout, validatePayout } from 'actions/PayoutActions';
import S3FileService from 'services/S3FileService';
import FilePreview from 'components/FilePreview';

const useStyles = makeStyles(() => ({
  backButton: {
    marginTop: 10,
    borderRadius: 12,
    width: 90,
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  sectionHeading: {
    fontWeight: 500,
  },
  boldText: {
    fontWeight: 'bold',
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  dataRow: {
    marginBottom: 30,
  },
  headRow: {
    marginTop: 10,
  },
  header: {
    marginBottom: 30,
  },
  footer: {
    marginTop: 30,
  },
  transferButton: {
    marginTop: 10,
    borderRadius: 12,
    width: 120,
  },
}));

const BankTransferDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { payoutOId } = useParams();

  const payoutDetail = useSelector((state) =>
    state.payout.payouts.find((el) => el._id === payoutOId)
  );

  const isAdmin = useSelector((state) => state.loggedUser.isAdmin);

  const [payoutDocuments, setPayoutDocuments] = useState(null);
  const [sourceDocument, setSourceDocument] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [openingDocument, setOpeningDocument] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAdmin) {
      (async () => {
        setLoading(true);
        await dispatch(fetchPayout(payoutOId));
        setLoading(false);
      })();
    }
  }, [isAdmin]);

  useEffect(() => {
    if (
      payoutDetail &&
      payoutDetail.documents &&
      payoutDetail.documents.length > 0
    ) {
      (async () => {
        const documentPromises = payoutDetail.documents.map(async (doc) => {
          const urlRs = await S3FileService.geturl(doc.filename)
            .then((res) => {
              const { url, type } = res;
              return {
                url,
                fileType: type,
                filename: doc.filename,
              };
            })
            .catch((err) => {
              if (err.code === 'NoSuchKey') {
                return { ...err };
              }
            });
          return urlRs;
        });

        const files = await Promise.all(documentPromises);
        setPayoutDocuments(files);
      })();
    }
    if (payoutDetail && payoutDetail.source && payoutDetail.source.filename) {
      (async () => {
        const urlRs = await S3FileService.geturl(payoutDetail.source.filename)
          .then((res) => {
            const { url, type } = res;
            return {
              url,
              fileType: type,
              filename: payoutDetail.source.filename,
            };
          })
          .catch((err) => {
            if (err.code === 'NoSuchKey') {
              return { ...err };
            }
          });

        setSourceDocument(urlRs);
      })();
    }
  }, [payoutDetail]);

  const handleCloseDialog = () => {
    setDialogVisible(false);
    setOpeningDocument(null);
  };

  const handleOpenDialog = (file) => {
    setOpeningDocument(file);
    setDialogVisible(true);
  };

  const handleValidatePayout = async () => {
    const { _id: payoutOId } = payoutDetail;
    setLoading(true);
    const validated = await dispatch(validatePayout(payoutOId));
    if (!validated.errors) {
      await dispatch(fetchPayout(payoutOId));
    }
    setLoading(false);
    return true;
  };

  const headRow = (cols) => {
    return (
      <Fragment>
        {cols.map((col, key) => {
          return (
            <Grid key={key} item xs={col.size} className={classes.headRow}>
              <Typography component="h1">{t(`${col.content}`)}</Typography>
            </Grid>
          );
        })}
      </Fragment>
    );
  };

  const dataRow = (cols) => {
    return (
      <Fragment>
        {cols.map((col, key) => {
          return (
            <Grid key={key} item xs={col.size} className={classes.dataRow}>
              <Typography component="h1" className={classes.boldText}>
                {col.content}
              </Typography>
            </Grid>
          );
        })}
      </Fragment>
    );
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={1} className={classes.header}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.backButton}
          >
            {t(`Back`)}
          </Button>
        </Grid>
        <Grid item xs={12} sm={11} className={classes.header}>
          <Typography
            variant="h3"
            component="h1"
            color="primary"
            className={classes.pageTitle}
          >
            {t(`Virement`)}
          </Typography>
        </Grid>
      </Fragment>
    );
  };

  const renderPreviewFileDialog = () => {
    return (
      <Fragment>
        <Dialog
          onClose={handleCloseDialog}
          aria-labelledby="file-preview-dialog-title"
          open={dialogVisible}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="file-preview-dialog-title">File Preview</DialogTitle>
          {openingDocument && (
            <FilePreview
              content={{
                type:
                  openingDocument.fileType === 'application/pdf'
                    ? 'application/pdf'
                    : 'image',
                value: openingDocument.url,
              }}
            />
          )}
        </Dialog>
      </Fragment>
    );
  };

  const renderTransferInfo = () => {
    return (
      <Fragment>
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="h5"
            component="h1"
            color="primary"
            className={classes.sectionHeading}
          >
            {t(`Informations sur le virement`)}
          </Typography>
        </Grid>
        {headRow([
          { size: 4, content: '_id' },
          { size: 2, content: 'Montant' },
          { size: 3, content: 'Date de création' },
          { size: 3, content: 'Date de programmation' },
        ])}

        {dataRow([
          { size: 4, content: payoutDetail._id },
          { size: 2, content: payoutDetail.amount },
          {
            size: 3,
            content: payoutDetail.createdDate
              ? format(
                  new Date(payoutDetail.createdDate),
                  'dd/MM/yyyy HH:mm:ss'
                )
              : format(
                  new Date(payoutDetail.created_at),
                  'dd/MM/yyyy HH:mm:ss'
                ),
          },
          { size: 3, content: '' },
        ])}
      </Fragment>
    );
  };

  const renderBeneficiaryInfo = () => {
    return (
      <Fragment>
        <Grid item xs={12} className={classes.section}>
          <Typography
            variant="h5"
            component="h1"
            color="primary"
            className={classes.sectionHeading}
          >
            {t(`Informations sur le bénéficiaire`)}
          </Typography>
        </Grid>

        {headRow([
          { size: 3, content: 'Type' },
          { size: 3, content: 'Nom' },
          { size: 3, content: 'Email' },
          { size: 3, content: 'Téléphone' },
        ])}

        {dataRow([
          { size: 3, content: payoutDetail.bbfBeneficiary.type },
          { size: 3, content: payoutDetail.bbfBeneficiary.name },
          { size: 3, content: payoutDetail.bbfBeneficiary.email },
          { size: 3, content: payoutDetail.bbfBeneficiary.phonenumber },
        ])}

        {headRow([
          { size: 3, content: 'Adresse' },
          { size: 3, content: 'Ville' },
          { size: 3, content: 'Code postal' },
          { size: 3, content: 'Pays' },
        ])}

        {dataRow([
          { size: 3, content: payoutDetail.bbfBeneficiary.address },
          { size: 3, content: payoutDetail.bbfBeneficiary.city },
          { size: 3, content: payoutDetail.bbfBeneficiary.zipcode },
          { size: 3, content: payoutDetail.bbfBeneficiary.country },
        ])}

        {headRow([
          { size: 3, content: 'Siret' },
          { size: 3, content: 'IBAN' },
          { size: 6, content: 'BIC' },
        ])}

        {dataRow([
          { size: 3, content: payoutDetail.bbfBeneficiary.siret },
          { size: 3, content: payoutDetail.bbfBeneficiary.iban },
          { size: 6, content: payoutDetail.bbfBeneficiary.bic },
        ])}
      </Fragment>
    );
  };

  const renderIssuerInfo = () => {
    return (
      <Fragment>
        {headRow([
          { size: 3, content: '_id' },
          { size: 3, content: 'Nom' },
          { size: 3, content: 'Email' },
          { size: 3, content: 'Téléphone' },
        ])}

        {dataRow([
          { size: 3, content: payoutDetail.userOId || 'N/A' },
          { size: 3, content: payoutDetail.userLastname || 'N/A' },
          { size: 3, content: payoutDetail.userEmail || 'N/A' },
          { size: 3, content: payoutDetail.userPhoneNumber || 'N/A' },
        ])}
      </Fragment>
    );
  };

  const renderBody = () => {
    if (loading) {
      return <CircularProgress />;
    }
    if (payoutDetail) {
      return (
        <Fragment>
          {renderTransferInfo()}
          {payoutDetail.bbfBeneficiary && renderBeneficiaryInfo()}
          <Grid item xs={3}>
            <Typography
              variant="h5"
              component="h1"
              color="primary"
              className={classes.sectionHeading}
            >
              {t(`Pièce`)}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="h5"
              component="h1"
              color="primary"
              className={classes.sectionHeading}
            >
              {t(`Document justificatif(s)`)}
            </Typography>
          </Grid>
          {renderSourceDoc()}
          {renderBeneficiaryDoc()}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h1"
              color="primary"
              className={classes.sectionHeading}
            >
              {t(`Informations sur l'emetteur`)}
            </Typography>
          </Grid>
          {renderIssuerInfo()}
          {renderPreviewFileDialog()}
          {payoutDetail &&
            !payoutDetail.payoutId &&
            !payoutDetail.payoutStatus &&
            payoutDetail.beneficiaryId &&
            renderFooter()}
        </Fragment>
      );
    }
    return <Typography component="h1">{t(`Transfer not found`)}</Typography>;
  };

  const renderSourceDoc = () => {
    return (
      <Grid item xs={3}>
        <List>
          {sourceDocument && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <DescriptionIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={sourceDocument.filename} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleOpenDialog(sourceDocument)}
                >
                  <VisibilityIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </Grid>
    );
  };

  const renderBeneficiaryDoc = () => {
    return (
      <Grid item xs={9}>
        <List>
          {payoutDocuments &&
            payoutDocuments.map((doc, idx) => {
              return (
                <ListItem key={idx}>
                  <ListItemAvatar>
                    <Avatar>
                      <DescriptionIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={doc.filename} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleOpenDialog(doc)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      </Grid>
    );
  };

  const renderFooter = () => {
    return (
      <Grid item xs={12} sm={12} className={classes.footer}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.transferButton}
          onClick={() => handleValidatePayout()}
        >
          {t(`Transfer`)}
        </Button>
      </Grid>
    );
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        {renderHeader()}
        {renderBody()}
      </Grid>
    </Fragment>
  );
};

export default BankTransferDetails;
