import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Lightbox } from 'react-modal-image';

const useStyles = makeStyles(() => ({
  dialogImage: {
    maxWidth: '100%',
    // height: '100%',
    objectFit: 'contain',
  },
  uploadImage: {
    maxWidth: '300px',
    maxHeight: '300px',
    cursor: 'pointer',
  },
  // '@media (max-width: 1199px)': {
  //   uploadImage: {
  //     width: '100%',
  //     minWidth: '96px',
  //     maxHeight: 'auto',
  //   },
  // },
  '@media (max-width: 1023px)': {},
  '@media (max-width: 767px)': {},
}));

const ImagePreview = ({ isDialog, file, toggleDialog }) => {
  const classes = useStyles();

  function onImageClick() {
    if (toggleDialog) {
      toggleDialog(true);
    }
  }

  return (
    <Grid container justify="center">
      <Grid item xs={11}>
        <img
          className={classes.uploadImage}
          src={file}
          alt={'document'}
          onClick={onImageClick}
        />
        {isDialog && (
          <Lightbox
            medium={file}
            large={file}
            alt="document"
            onClose={() => toggleDialog(false)}
          />
        )}
      </Grid>
    </Grid>
  );
};

ImagePreview.propTypes = {
  isDialog: PropTypes.bool,
  file: PropTypes.string,
  toggleDialog: PropTypes.func,
};

ImagePreview.defaultProps = {
  isDialog: false,
};

export default ImagePreview;
