import TreezorService from 'services/treezorservice';
import { START_PROGRESS, END_PROGRESS } from 'actions/Types';
import { store } from '../../store';

const fetchWalletDetails = async (companyId) => {
  try {
    store.dispatch({
      type: START_PROGRESS,
    });

    const result = await TreezorService.fetchWallets(companyId);
    return result.data;
  } catch (error) {
    return error;
  } finally {
    store.dispatch({
      type: END_PROGRESS,
    });
  }
};

export default {
  fetchWalletDetails,
};
