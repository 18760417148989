import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const SEPARequestCheckbox = (props) => {
  const { field, form, placeholder, label, subLabel, disabled } = props;
  const { name } = field;
  const { errors, touched } = form;

  return (
    <FormControl error={errors && touched && errors[name] && touched[name]}>
      <FormControlLabel
        control={
          <Checkbox
            id={name}
            placeholder={placeholder}
            inputProps={{ disabled }}
            {...field}
          />
        }
        label={<b>{label}</b>}
      />
      <p style={{ color: 'gray', marginBottom: '2px', margin: '2px 0px 8px' }}>
        {subLabel}
      </p>
      <FormHelperText>{errors && errors[name]}</FormHelperText>
    </FormControl>
  );
};

SEPARequestCheckbox.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  subLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

SEPARequestCheckbox.defaultProps = {
  field: {},
  form: {},
  label: '',
  subLabel: '',
  placeholder: '',
  disabled: false,
};

export default SEPARequestCheckbox;
