/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CompanyService from 'services/CompanyService';
import CustomDialog from 'components/CustomDialog';
import ResultTable from '../../components/deletecompany/ResutlTable';

const CompanyDeleteDialog = ({ open, toggle, companyId, companyName }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogResultOpen, setDialogResultOpen] = useState(false);
  const [result, setResult] = useState(null);
  const history = useHistory();

  const deleteCompany = (id) => {
    CompanyService.deleteCompany(id)
      .then((response) => {
        // console.log(response.data);
        setResult(response.data);
        setDialogResultOpen(true);
      })
      .catch((/* e */) => {
        // console.log(e);
      });
  };

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  return (
    <>
      <div>
        <CustomDialog
          open={dialogOpen}
          title="
                Are you sure you want to delete this company:"
          contentText={companyName}
          confirmText="ok"
          showButtons
          onConfirm={() => {
            setDialogOpen(false);
            deleteCompany(companyId);
          }}
          cancelText="cancel"
          onCancel={() => {
            setDialogOpen(false);
            toggle(false);
          }}
        />
      </div>

      {result && (
        <CustomDialog
          open={dialogResultOpen}
          showButtons
          cancelText="ok"
          title="Company Deletion results"
          closeDialog={() => {
            setDialogResultOpen(false);
            toggle(false);
            history.push('/companylist');
          }}
        >
          <ResultTable result={result} />
        </CustomDialog>
      )}
    </>
  );
};

export default CompanyDeleteDialog;
