import {
  START_PROGRESS,
  END_PROGRESS,
  LEGAL_NOTICE_FAILED,
  LEGAL_NOTICE_SUCCESS,
  LEGAL_NOTICE_PROCESSING,
  SET_SNACK_MESSAGE,
  SET_TITLE,
} from 'actions/Types';
import { INFO } from 'constants/severity';

const initialState = {
  title: 'Backoffice',
  progress: false,
  error: null,
  snackMessage: {
    message: null,
    severity: INFO,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_PROGRESS:
      return {
        ...state,
        progress: true,
      };
    case END_PROGRESS:
      return {
        ...state,
        progress: false,
      };
    case LEGAL_NOTICE_PROCESSING:
      return {
        ...state,
        progress: true,
        error: null,
      };
    case LEGAL_NOTICE_SUCCESS:
      return {
        ...state,
        progress: false,
        error: null,
      };
    case LEGAL_NOTICE_FAILED:
      return {
        ...state,
        error: action.payload,
        process: false,
      };
    case SET_SNACK_MESSAGE:
      return {
        ...state,
        snackMessage: action.payload,
      };
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    default:
      return state;
  }
};
