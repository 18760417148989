import {
  START_PROGRESS,
  END_PROGRESS,
  SET_SNACK_MESSAGE,
  SET_TITLE,
} from 'actions/Types';

const startScreenProgress = () => ({
  type: START_PROGRESS,
});

const endScreenProgress = () => ({
  type: END_PROGRESS,
});

const setSackbarMessage = (message, severity) => async (dispatch) => {
  dispatch({
    type: SET_SNACK_MESSAGE,
    payload: {
      message,
      severity,
    },
  });
};

const setAppTitle = (title) => (dispatch) => {
  dispatch({
    type: SET_TITLE,
    payload: title,
  });
};

export default {
  setAppTitle,
  startScreenProgress,
  endScreenProgress,
  setSackbarMessage,
};
