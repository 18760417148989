import { bindActionCreators } from 'redux';
import {
  updateCompany,
  deleteCompany,
  fetchAllCompanies,
} from 'actions/CompanyActions';

import AppActions from 'actions/AppActions';

export default (dispatch) =>
  bindActionCreators(
    {
      updateCompany,
      deleteCompany,
      fetchAllCompanies,
      setAppTitle: AppActions.setAppTitle,
    },
    dispatch
  );
