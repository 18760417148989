/* Packages */
import { CircularProgress, Grid } from '@material-ui/core';
import {
  fetchSEPARequestByIdAction,
  fetchSEPATransactionAction,
} from 'actions/SEPAAdminActions';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/* Components */
import GoBackButton from 'views/SEPAManagement/components/GoBackButton';
import SEPAConfirmCard from 'views/SEPAManagement/components/SEPAConfirmCard';
import SEPARequestCheckbox from 'views/SEPAManagement/components/SEPARequestCheckbox';
import SEPARequestInput from 'views/SEPAManagement/components/SEPARequestInput';
import SEPARequestSelect from 'views/SEPAManagement/components/SEPARequestSelect';

const SEPAFormView = () => {
  const { requestId } = useParams();

  const loggedUserEmail = useSelector((state) => state.loggedUser.user?.email);

  const [isFormLoading, setIsFormLoading] = useState(true);
  const [requestData, setRequestData] = useState(null);
  const [transactionData, setTransactionData] = useState(null);

  useEffect(() => {
    setIsFormLoading(true);

    const getRequestData = async () => {
      const responseData = await fetchSEPARequestByIdAction(requestId);
      const responseTransactionData = await fetchSEPATransactionAction(
        responseData.data.payMutationId
      );

      setRequestData(responseData?.data);
      setTransactionData(responseTransactionData?.data);

      setIsFormLoading(false);
    };

    getRequestData();
  }, [requestId]);

  return (
    <>
      {isFormLoading ? (
        <CircularProgress />
      ) : (
        <Grid
          container
          spacing={2}
          style={{ backgroundColor: 'white', padding: '10px 15px' }}
        >
          <Grid item xs={12}>
            <GoBackButton />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestInput
              name="email"
              subLabel="Il s'agit du mail du titulaire du compte."
              label="Email"
              type="email"
              field={{
                value: loggedUserEmail,
              }}
              readOnly
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestInput
              name="payInOutId"
              label={
                requestData.typeOfRequest === 'Payin'
                  ? 'Pay In ID'
                  : 'Payout ID'
              }
              field={{
                value: requestData.payMutationId,
              }}
              type="text"
              subLabel="Il s'agit de l'identifiant électronique de vote opération."
              readOnly
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestSelect
              type="select"
              name="typeOfRequest"
              label="Type de demande"
              subLabel="Merci de choisir dans la liste déroulante ci-dessous."
              selectOptionType={requestData?.payMutationType}
              field={{
                value: requestData?.typeOfRequest,
              }}
              readOnly
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestInput
              name="subject"
              label="Sujet"
              subLabel="Synthétiser la nature du problème"
              type="text"
              field={{
                value: requestData.subject,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestInput
              name="description"
              subLabel="Merci de décrire le plus précisément possible le problème rencontré"
              label="Description"
              type="text"
              multiline={true}
              rows={8}
              field={{
                value: requestData.description,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestCheckbox
              name="agree"
              label="Réclamation"
              subLabel="Une réclamation est une demande d'un de vos utilisateurs rencontrant une problème. Dans la plupart des cas, cela peut se résoudre automatiquement lorsque les opérations ne sont pas validées en banque. En cas de validation, cela nécessite une action de la part de notre partenaire Treezor. Dans ce cas un délai de 15 jours pouvant être étendu à 35 jours en cas d'analyse approfondie peut être nécessaire. Mais nous vous tiendrons informé de la situation et de sa résolution. Merci de cocher la case pour signifier votre compréhension"
              field={{
                checked: requestData.isComplaint,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestCheckbox
              name="agree2"
              label="Demande critique avec intervention immédiate"
              subLabel="Cette case est à cocher lorsque la demande est critique et demande une intervention immédiate."
              field={{
                checked: requestData.isCriticalRequestWithImmediateEscalation,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <SEPAConfirmCard
              foreignId={transactionData.foreignId}
              transactionType={transactionData.transactionType}
              amount={transactionData.amount}
              currency={transactionData.currency}
              createdDate={transactionData.createdDate}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SEPAFormView;
