export const BRIDGE_WEBHOOK_TYPE = {
  ITEM_CREATED: 'item.created',
  ITEM_ACCOUNT_CREATED: 'item.account.created',
  ITEM_ACCOUNT_UPDATED: 'item.account.updated',
  ITEM_REFRESHED: 'item.refreshed',
  TRANSFER_ACCOUNT_CREATED: 'transfer.account.created',
  TRANSFER_ACCOUNT_UPDATED: 'transfer.account.updated',
  TRANSFER_CREATED: 'transfer.created',
  TRANSFER_UPDATE: 'transfer.update',
};

export const BRIDGE_WEBHOOK_TYPE_LIST = Object.entries(BRIDGE_WEBHOOK_TYPE).map(
  ([key, value]) => ({
    value,
    label: key
      .split('_')
      .map((ele) => `${ele[0].toUpperCase()}${ele.slice(1).toLowerCase()}`)
      .join(' '),
  })
);
