import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from 'reducers';

const middlewares = [ReduxThunk];

let enhancer = applyMiddleware(...middlewares);
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger());
  enhancer = composeWithDevTools(applyMiddleware(...middlewares));
}

// eslint-disable-next-line import/prefer-default-export
export const store = createStore(reducers, enhancer);
export const globalDispatch = store.dispatch;
