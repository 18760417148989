import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { useSelector } from 'react-redux';

const LoadingBackdrop = () => {
  const { isLoading } = useSelector((state) => state);
  return (
    <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    </Backdrop>
  );
};

LoadingBackdrop.propTypes = {
  open: PropTypes.bool,
};

export default LoadingBackdrop;
