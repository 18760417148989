import {
  FETCH_DOCUSIGN_USER_INFO_SUCCESS,
  FETCH_DOCUSIGN_USER_INFO_FAILURE,
  LOGIN_DOCUSIGN_FAILURE,
} from 'actions/Types';

const INITIAL_STATE = {
  userInfo: null,
  fetchUserInfoErr: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_DOCUSIGN_FAILURE:
      return {
        ...state,
        loginError: action.payload,
      };
    case FETCH_DOCUSIGN_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        fetchUserInfoErr: null,
        loginError: null,
      };
    case FETCH_DOCUSIGN_USER_INFO_FAILURE:
      return {
        ...state,
        userInfo: null,
        fetchUserInfoErr: action.payload,
      };
    default:
      return state;
  }
};
