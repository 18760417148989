import { parse, isValid } from 'date-fns';
import fr from 'date-fns/locale/fr';

const formatDateType = (dateString) => {
  const formats = [
    { regex: /^\d{4}-\d{2}-\d{2}$/, format: 'yyyy-MM-dd' },
    { regex: /^\d{2}\/\d{2}\/\d{4}$/, format: 'dd/MM/yyyy' },
    {
      regex: /^\d{1,2} [a-zA-Z]+ \d{4}$/,
      format: 'dd MMMM yyyy',
      locale: fr,
    },
    {
      regex: /^[a-zA-Z\d]+? [a-zA-Z]+ \d{4}$/,
      format: 'do MMMM yyyy',
      locale: fr,
    },
    {
      regex: /^[a-zA-Z\d]+? [a-zA-Z]/,
      format: 'do MMMM',
      locale: fr,
    },
  ];
  const matchingFormat = formats.find((format) =>
    format.regex.test(dateString)
  );

  if (matchingFormat) {
    let parsedDate;
    if (matchingFormat.locale)
      parsedDate = parse(dateString, matchingFormat.format, new Date(), {
        locale: matchingFormat.locale,
      });
    else {
      parsedDate = parse(dateString, matchingFormat.format, new Date());
    }
    return isValid(parsedDate) ? parsedDate : null;
  }
  return null;
};
export default formatDateType;
