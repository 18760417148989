/* eslint-disable consistent-return */
import DocuSignService from 'services/DocuSignService';
import S3FileService from 'services/S3FileService';

import {
  FETCH_DOCUSIGN_USER_INFO_SUCCESS,
  FETCH_DOCUSIGN_USER_INFO_FAILURE,
  START_PROGRESS,
  END_PROGRESS,
  LOGIN_DOCUSIGN_FAILURE,
} from 'actions/Types';

/* eslint-disable-next-line */
export const fetchDocuSignUserInfo = () => async (dispatch) => {
  try {
    const result = await DocuSignService.getUserInfo();

    if (result) {
      const { accountInfo } = result.data;
      dispatch({
        type: FETCH_DOCUSIGN_USER_INFO_SUCCESS,
        payload: accountInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_DOCUSIGN_USER_INFO_FAILURE,
      payload: error,
    });
  }
};

/* eslint-disable-next-line */
export const login = () => async (dispatch) => {
  try {
    dispatch({
      type: START_PROGRESS,
    });
    const result = await DocuSignService.login();
    if (result) {
      const { accessToken } = result.data;
      localStorage.setItem('dsToken', accessToken);
      const userInfo = await DocuSignService.getUserInfo();
      const { accountInfo } = userInfo.data;
      dispatch({
        type: FETCH_DOCUSIGN_USER_INFO_SUCCESS,
        payload: accountInfo,
      });
      dispatch({
        type: END_PROGRESS,
      });
    }
  } catch (error) {
    dispatch({
      type: END_PROGRESS,
    });
    if (error.response) {
      dispatch({
        type: LOGIN_DOCUSIGN_FAILURE,
        payload: error.response.data,
      });
    } else {
      dispatch({
        type: LOGIN_DOCUSIGN_FAILURE,
        payload: error.message,
      });
    }
  }
};

export const sendEnvelope = (
  documents,
  recipients,
  dsUserInfo,
  companyName
) => async (dispatch) => {
  try {
    dispatch({
      type: START_PROGRESS,
    });
    const { basePath, accountId } = dsUserInfo;

    const documentTitles = [];
    // Prepare documents for the envelope
    const envDocPromises = documents.map(async (document, idx) => {
      const { title } = document;
      documentTitles.push(title);

      const s3Result = await S3FileService.getobject(document.key);
      const fileBuffer = s3Result.data.body;

      const arrayBuffer64 = Buffer.from(fileBuffer.data, 'base64');
      const base64File = arrayBuffer64.toString('base64');

      const fileName = document.key.split(`/`)[1];

      return {
        name: fileName,
        fileExtension: 'pdf',
        documentId: `${idx + 1}`,
        documentBase64: base64File,
      };
    });

    const envDocs = await Promise.all(envDocPromises);

    const envSigners = await Promise.all(
      recipients.map((recipient, idx) => ({
        email: recipient.email,
        name: recipient.name,
        recipientId: `${idx + 1}`,
        tabs: {
          signHereTabs: [
            {
              anchorString: `/sn_${recipient.email}/`,
              anchorXOffset: `10`,
              anchorYOffset: '15',
              anchorUnits: 'pixels',
            },
          ],
          initialHereTabs: [
            {
              anchorString: `/in_${recipient.email}/`,
              anchorXOffset: '10',
              anchorYOffset: '10',
              anchorUnits: 'pixels',
            },
          ],
        },
        emailNotification: {
          emailSubject: `[BBF] Signature pour ${
            documentTitles.length === 1 ? documentTitles[0] : 'documents'
          }`,
          emailBody: `<p>${recipient.firstname} ${
            recipient.name
          },</p><p>Veuillez signer ${documentTitles.join(', ')}</p>`,
        },
      }))
    );

    await DocuSignService.sendEnvelope(
      envDocs,
      envSigners,
      accountId,
      basePath,
      companyName
    )
      .then((res) => res.data)
      .catch((err) => ({
        message: err.message,
        result: null,
      }));

    dispatch({
      type: END_PROGRESS,
    });
  } catch (error) {
    dispatch({
      type: END_PROGRESS,
    });
    if (error.response) {
      dispatch({
        type: LOGIN_DOCUSIGN_FAILURE,
        payload: error.response.data,
      });
    } else {
      dispatch({
        type: LOGIN_DOCUSIGN_FAILURE,
        payload: error.message,
      });
    }
    return null;
  }
};
