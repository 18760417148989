import PayoutService from 'services/PayoutService';
import {
  FETCHING_PAYOUT,
  FETCH_PAYOUT_SUCCESS,
  FETCH_PAYOUT_FAILURE,
  FETCHING_BLOCKED_TRANSFERS,
  FETCH_BLOCKED_TRANSFERS_SUCCESS,
  FETCH_BLOCKED_TRANSFERS_FAILURE,
  VALIDATING_PAYOUT,
  VALIDATE_PAYOUT_FAILURE,
  VALIDATE_PAYOUT_SUCCESS,
} from 'actions/Types';

export const fetchingPayout = () => ({
  type: FETCHING_PAYOUT,
});

export const fetchPayoutFailure = (error) => ({
  type: FETCH_PAYOUT_FAILURE,
  payload: { error },
});

export const fetchPayoutSuccess = (data) => ({
  type: FETCH_PAYOUT_SUCCESS,
  payload: { ...data },
});

export const fetchPayout = (payoutOId) => async (dispatch) => {
  try {
    dispatch(fetchingPayout());
    const response = await PayoutService.fetchPayout(payoutOId);
    dispatch(fetchPayoutSuccess(response.data));
  } catch (err) {
    dispatch(fetchPayoutFailure(err));
  }
};

export const fetchingBlockedTransfers = () => ({
  type: FETCHING_BLOCKED_TRANSFERS,
});

export const fetchingBlockedTransfersSuccess = (data) => ({
  type: FETCH_BLOCKED_TRANSFERS_SUCCESS,
  payload: data,
});

export const fetchingBlockedTransfersFailure = (error) => ({
  type: FETCH_BLOCKED_TRANSFERS_FAILURE,
  payload: { error },
});

export const fetchBlockedTransfers = (companyId) => async (dispatch) => {
  try {
    dispatch(fetchingBlockedTransfers());
    const response = await PayoutService.fetchBlockedTransfers(companyId);
    dispatch(fetchingBlockedTransfersSuccess(response.data));
  } catch (err) {
    dispatch(fetchingBlockedTransfersFailure(err));
  }
};

export const validatingPayout = () => ({
  type: VALIDATING_PAYOUT,
});

export const validatePayoutFailure = (error) => ({
  type: VALIDATE_PAYOUT_FAILURE,
  payload: { error },
});

export const validatePayoutSuccess = (data) => ({
  type: VALIDATE_PAYOUT_SUCCESS,
  payload: { ...data },
});

// eslint-disable-next-line
export const validatePayout = (payoutOId) => async (dispatch) => {
  try {
    dispatch(validatingPayout());
    const response = await PayoutService.validatePayout(payoutOId);
    if (response.data.errors) {
      dispatch(validatePayoutFailure(response.data.errors));
    } else {
      dispatch(validatePayoutSuccess(response.data));
    }
    return response.data;
  } catch (err) {
    dispatch(validatePayoutFailure(err));
  }
};
