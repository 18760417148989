import { authInstance as axios } from 'utils/axiosInterceptor';

const fetchTreezorTransactionLocation = (companyId) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/treezortransactionlocation`,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

export default {
  fetchTreezorTransactionLocation,
};
