import {
  FETCH_VATRATES,
  FETCH_VATRATES_FAILED,
  FETCH_VATRATES_FOR_NO_VAT,
  LOGOUT_USER_SUCCESS,
} from '../../actions/Types';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_VATRATES:
      return action.payload || [];
    case FETCH_VATRATES_FAILED:
      return [];
    case FETCH_VATRATES_FOR_NO_VAT:
      return action.payload || [];
    case LOGOUT_USER_SUCCESS:
      return [];
    default:
      return state;
  }
};
