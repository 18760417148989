/* Packages */
import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

/* Icons */
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const GoBackButton = () => {
  const history = useHistory();

  const onGoBack = () => history.goBack();

  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<ChevronLeftIcon />}
      onClick={onGoBack}
    >
      Retour
    </Button>
  );
};

export default GoBackButton;
