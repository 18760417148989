import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from 'auth/react-auth0-spa';
import { fetchLoggedUser } from 'actions/LoggedUserActions';

import Unauthorized from 'components/Unauthorized';
import { CircularProgress } from '@material-ui/core';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  const isFetchingUser = useSelector(
    (state) => state.loggedUser.isFetchingUser
  );
  const user = useSelector((state) => state.loggedUser.user);

  const error = useSelector((state) => state.loggedUser.errorFetchingUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      return;
    }

    if (isAuthenticated && isFetchingUser) {
      return;
    }

    const authenticate = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };

    const dispatchAdminUser = async () => {
      // eslint-disable-next-line no-console
      console.log('initialisation');
      // setIsFetching(true);
      if (user === null) dispatch(fetchLoggedUser());
    };
    if (!isFetchingUser && user === null) {
      dispatchAdminUser();
    }
    if (!isAuthenticated) {
      authenticate();
    }
  }, [
    loading,
    isAuthenticated,
    loginWithRedirect,
    path,
    dispatch,
    isFetchingUser,
    user,
    error,
  ]);

  const render = (props) => {
    if (isFetchingUser || loading) {
      return <CircularProgress />;
    }
    if (error) {
      return <Unauthorized message={error.message} />;
    }
    return isAuthenticated === true ? <Component {...props} /> : null;
  };

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object, // when class etends React.Component
  ]).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default withRouter(PrivateRoute);
