import React from 'react';
import GenerateField from 'components/GenerateField';
import PropTypes from 'prop-types';
import { TAXREGISTRATION } from 'constants/companies';
import ReadOnlyRadioGroup from 'components/ReadOnlyRadioGroup';

const MicroCompany = ({ taxRegistration }) => {
  const { microOptions } = TAXREGISTRATION;

  const fiedls = [
    {
      component: ReadOnlyRadioGroup,
      componentProps: {
        name: 'isMicro',
        options: microOptions,
        value: taxRegistration.micro,
      },
    },
  ];

  return (
    <GenerateField
      name="isMicro"
      configs={fiedls}
      readOnly
      label="company.micro_company.title"
    />
  );
};

MicroCompany.propTypes = {
  taxRegistration: PropTypes.object,
};
export default MicroCompany;
