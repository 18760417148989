import { find } from 'lodash';
import Auth0Config from 'auth/Config';

export const getAuthCachedTokenKey = () => {
  const allKeys = Object.keys(localStorage);
  //   console.log(allKeys);
  const authKey = find(allKeys, (key) =>
    key.startsWith(`@@auth0spajs@@::${Auth0Config.clientId}`)
  );
  //   console.log('Found authKey', authKey);
  return authKey;
};

export const getAuthCachedToken = () => {
  const authKey = getAuthCachedTokenKey();
  const cachedToken = localStorage.getItem(authKey);
  const token = JSON.parse(cachedToken).body.id_token;
  return token;
};
