import React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(() => ({
  card: {
    padding: '4%',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5em',
    textAlign: 'center',
    marginBottom: '1em',
    position: 'relative',
  },
  lockIcon: {
    position: 'absolute',
    right: 0,
    color: 'white',
    marginRight: '2%',
    padding: '1%',
    width: '15px',
    height: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#32BEA6',
    borderRadius: '100%',
  },
}));

const SimpleActionButton = ({ handleSubmit, label, title, disabled, done }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.card}>
      <Typography
        color="primary"
        component="h6"
        variant="h6"
        className={classes.cardHeading}
      >
        {title}
      </Typography>
      {/* {!done && ( */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={disabled}
      >
        {label}
      </Button>
      {/* )} */}
      {/* {done && (
        <Typography
          component="body1"
          variant="body1"
          className={classes.cardHeading}
        >
          {doneMessage}
          <DoneIcon />
        </Typography>
      )}
      {done && (
        <div onClick={() => unlock(step)} className={classes.lockIcon}>
        <Tooltip title="Ré-activer le bouton">
        <LockOpenIcon style={{ height: '20px', width: '20px' }} />
        </Tooltip>
        </div>
      )} */}
      {done && (
        <div className={classes.lockIcon}>
          <CheckIcon style={{ height: '12px', width: '12px' }} />
        </div>
      )}
    </Paper>
  );
};

SimpleActionButton.propTypes = {
  title: PropTypes.string,
  doneMessage: PropTypes.string,
  label: PropTypes.string,
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  done: PropTypes.bool,
  unlock: PropTypes.func,
  step: PropTypes.string,
};

export default SimpleActionButton;
