import TreezorService from 'services/treezorservice';
import {
  REMOVE_ERROR,
  FETCHING_WEBHOOK_SUCCESS,
  FETCHING_WEBHOOK_FAILED,
  START_PROGRESS,
  END_PROGRESS,
  RESET_WEBHOOK,
} from 'actions/Types';
import { store } from '../../store';

const removeError = async () => {
  store.dispatch({
    type: REMOVE_ERROR,
  });
};

const refreshWebhooks = async (limit, last) => {
  try {
    store.dispatch({
      type: RESET_WEBHOOK,
    });

    const result = await TreezorService.fetchWebhooks(limit, last);

    store.dispatch({
      type: FETCHING_WEBHOOK_SUCCESS,
      payload: result.data,
    });
    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_WEBHOOK_FAILED,
      payload: error,
    });

    return error;
  }
};

const fetchWebhooks = async (limit, last) => {
  try {
    const result = await TreezorService.fetchWebhooks(limit, last);

    if (result.error) {
      throw result;
    }

    store.dispatch({
      type: FETCHING_WEBHOOK_SUCCESS,
      payload: result.data,
    });

    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_WEBHOOK_FAILED,
      payload: error,
    });

    return error;
  }
};

const fetchWebhookDetails = async (webhookId) => {
  try {
    store.dispatch({
      type: START_PROGRESS,
    });
    const result = await TreezorService.fetchWebhookDetails(webhookId);

    if (result.error) {
      throw result;
    }
    store.dispatch({
      type: END_PROGRESS,
    });
    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_WEBHOOK_FAILED,
      payload: error,
    });
    store.dispatch({
      type: END_PROGRESS,
    });
    return error;
  }
};

const searchWebhooks = async (searchObjectId, searchObject) => {
  try {
    store.dispatch({
      type: START_PROGRESS,
    });

    const result = await TreezorService.searchWebhooks(
      searchObjectId,
      searchObject
    );
    store.dispatch({
      type: END_PROGRESS,
    });
    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_WEBHOOK_FAILED,
      payload: error,
    });
    store.dispatch({
      type: END_PROGRESS,
    });
    return error;
  }
};

export default {
  fetchWebhooks,
  fetchWebhookDetails,
  removeError,
  refreshWebhooks,
  searchWebhooks,
};
