import { authInstance as axios } from 'utils/axiosInterceptor';

const fetchPayout = (payoutOId) =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/admin/payouts/${payoutOId}`);

const fetchBlockedTransfers = (companyId) =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/admin/payouts`, {
    params: {
      isBlocked: 1,
    },
    headers: {
      'X-Company-Id': companyId,
    },
  });

const validatePayout = (payoutOId) =>
  axios.post(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/payouts/${payoutOId}/validate`
  );

export default {
  fetchPayout,
  fetchBlockedTransfers,
  validatePayout,
};
