import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Document, pdfjs, Page } from 'react-pdf';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0',
  },
  pdfWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pages: {
    margin: '0 1rem',
  },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfActions = ({ currentPage, totalPages, nextPage, prevPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.actions}>
      <IconButton
        aria-label="prev"
        color="primary"
        disabled={currentPage === 1}
        onClick={() => {
          prevPage();
        }}
      >
        <ChevronLeftIcon fontSize="inherit" />
      </IconButton>
      <Typography
        className={classes.pages}
        variant="button"
        color="textPrimary"
      >
        {`${currentPage} of ${totalPages}`}
      </Typography>
      <IconButton
        aria-label="next"
        color="primary"
        disabled={currentPage === totalPages}
        onClick={() => {
          nextPage();
        }}
      >
        <ChevronRightIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

PdfActions.propTypes = {
  // isDialog: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
};

const PdfViewer = (props) => {
  const { file } = props;

  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPages(numPages);
  };

  function nextPage() {
    setPageNumber((prevValue) => prevValue + 1);
  }

  function prevPage() {
    setPageNumber((prevValue) => prevValue - 1);
  }

  return (
    <div className={classes.pdfWrapper}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <PdfActions
          currentPage={pageNumber}
          totalPages={pages}
          nextPage={nextPage}
          prevPage={prevPage}
        />
        <Page pageNumber={pageNumber} scale={2} />
        <PdfActions
          currentPage={pageNumber}
          totalPages={pages}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </Document>
    </div>
  );
};

PdfViewer.propTypes = {
  file: PropTypes.object,
  // togglePdfDialog: PropTypes.func.isRequired,
  // isDialog: PropTypes.bool,
};

// PdfViewer.defaultProps = {
//   isDialog: false,
// };

export default PdfViewer;
