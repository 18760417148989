import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const PaymentLinkInfo = ({
  companySubscription,
  setPaymentLinkLimit,
  setSubmit,
  readOnly,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const {
    paymentLinkLimit,
    paymentLinkUsed,
    paymentLinkBought,
  } = companySubscription;

  const validatePaymentLinkLimit = (value) => {
    const regex = new RegExp('^[0-9]*$');
    return regex.test(value);
  };
  const handleChangePaymentLinkLimit = (e) => {
    const value = e?.target?.value;
    if (validatePaymentLinkLimit(value) && setPaymentLinkLimit) {
      setPaymentLinkLimit(value);
      setSubmit(true);
      setError(false);
    } else {
      setSubmit(false);
      setError(true);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          style={{ textTransform: 'uppercase' }}
          component={'h6'}
          variant="h6"
        >
          {t('company.subscription.paymentLinkInfo.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="paymentLimit"
          label={t('company.subscription.paymentLinkInfo.paymentLinkLimit')}
          fullWidth
          variant="outlined"
          size="small"
          defaultValue={paymentLinkLimit}
          onChange={handleChangePaymentLinkLimit}
          error={error}
          helperText={
            error
              ? t('company.subscription.paymentLinkInfo.paymentLinkLimitError')
              : null
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="paymentLinkUse"
          label={t('company.subscription.paymentLinkInfo.paymentLinkUse')}
          variant="outlined"
          size="small"
          fullWidth
          value={paymentLinkUsed}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="paymentLinkBought"
          label={t('company.subscription.paymentLinkInfo.paymentLinkBought')}
          fullWidth
          variant="outlined"
          size="small"
          value={paymentLinkBought}
          disabled
        />
      </Grid>
    </Grid>
  );
};

PaymentLinkInfo.propTypes = {
  companySubscription: PropTypes.any,
  setPaymentLinkLimit: PropTypes.func,
  setIsUpdateSubscription: PropTypes.func,
  setSubmit: PropTypes.func,
  readOnly: PropTypes.bool,
};
export default PaymentLinkInfo;
