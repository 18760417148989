import React from 'react';
import GenerateField from 'components/GenerateField';
import PropTypes from 'prop-types';
import { TAXREGISTRATION } from 'constants/companies';
import ReadOnlyRadioGroup from 'components/ReadOnlyRadioGroup';

const ReturnOnDebit = ({ values }) => {
  const {
    settings: { tax_registration: taxRegistration },
  } = values;
  const fiedls = [
    {
      component: ReadOnlyRadioGroup,
      componentProps: {
        name: 'returnOnDebit',
        options: TAXREGISTRATION.returnOnDebit,
        value: taxRegistration.return_on_debit,
      },
    },
  ];

  return (
    <GenerateField
      name="returnOnDebit"
      configs={fiedls}
      readOnly
      label="company.return_on_debit.title"
    />
  );
};

ReturnOnDebit.propTypes = {
  values: PropTypes.object,
};
export default ReturnOnDebit;
