import querystring from 'qs';

const queryStringHelper = (query) => {
  const qs = {
    page: query.page + 1,
    limit: query.pageSize,
    search: query.search,
  };
  let i = 0;
  const filters = {};
  while (i < query.filters.length) {
    filters[i] = {
      field: query.filters[i].column.field,
      value: query.filters[i].value,
    };
    i += 1;
  }
  qs.filters = filters;
  if (query.orderBy) {
    qs.orderBy = query.orderBy.field;
    qs.orderDirection = query.orderDirection;
  }
  return querystring.stringify(qs);
};

export default queryStringHelper;
