import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  radioGroup: {
    display: 'flex',
  },
  radio: {
    minWidth: '200px',
  },
}));

export default useStyles;
