import React from 'react';
import PropTypes from 'prop-types';
import GenerateField from 'components/GenerateField';
import SmallTextField from 'components/SmallTextField';

const SubscriptionInfo = ({ companySubscription }) => {
  const {
    name,
    interval: subscriptionType,
    startDate,
    endDate: renewalDate,
  } = companySubscription;
  const reponsiveLg = {
    xs: 12,
    sm: 12,
    md: 6,
  };
  const fieldConfigurations = [
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.subscription.subscriptionInfo.current_subscription',
        value: name,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.subscription.subscriptionInfo.subscription_type',
        value: subscriptionType,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.subscription.subscriptionInfo.start_date',
        value: startDate,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.subscription.subscriptionInfo.renewal_date',
        value: renewalDate,
      },
    },
  ];
  return (
    <GenerateField
      name="subscriptionInfo"
      configs={fieldConfigurations}
      label="company.subscription.subscriptionInfo.title"
      readOnly
    />
  );
};

SubscriptionInfo.propTypes = {
  companySubscription: PropTypes.object.isRequired,
};
export default SubscriptionInfo;
