import formatVATRates from 'utils/formatVATRates';
import vatServices from '../../services/VATService';
import { store } from '../../store';
import { FETCH_VATRATES, FETCH_VATRATES_FAILED } from '../Types';

const fetchVATRates = async () => {
  try {
    const vat = store.getState().vatRates;
    if (vat.length === 0) {
      const result = await vatServices.fetchVATRates();
      const vatRates = formatVATRates(result.data);
      store.dispatch({ type: FETCH_VATRATES, payload: vatRates });
      return vatRates;
    }
    return vat;
  } catch (error) {
    store.dispatch({ type: FETCH_VATRATES_FAILED, error });
  }
  return null;
};

export default {
  fetchVATRates,
};
