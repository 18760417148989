const SEPA_TYPE_OF_REQUESTS = [
  {
    value: 'RECALL_REQUEST_FOR_WITHDRAWAL',
    label: 'Demande de retour de prélèvement',
    type: 'Payout',
  },
  {
    value: 'BANK_TRANSFER_RECALL',
    label: 'Rappel de virement',
    type: 'Payout',
  },
  {
    value: 'WITHDRAWAL_ISSUED',
    label: 'Problème sur un prélèvement émis',
    type: 'Payin',
  },
  {
    value: 'WITHDRAWAL_RECEIVED',
    label: 'Prélèvement recu',
    type: 'Payout',
  },
  {
    value: 'BANK_TRANSFER_ISSUED',
    label: 'Virement émis',
    type: 'Payout',
  },
  {
    value: 'BANK_TRANSFER_RECEIVED',
    label: 'Problème sur un virement reçu',
    type: 'Payin',
  },
  {
    value: 'ICS_REQUEST',
    label: "Demande d'ICS",
    type: 'Other',
  },
  {
    value: 'BANK_INFORMATION_REQUEST',
    label: "Demande d'informations bancaires",
    type: 'Other',
  },
];

export default SEPA_TYPE_OF_REQUESTS;
