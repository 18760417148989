import { FormControlLabel, Radio } from '@material-ui/core';
// import HelpIcon from 'components/commons/HelpIcon';
import { makeStyles } from '@material-ui/core/styles';
import { Field, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  inputWithHelpIconContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  // RADIO TYPE COMPANY
  radioContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '1rem',
  },
  radioWrapper: {
    flex: 1,
    padding: '2%',
    display: 'flex',
    height: '200px',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '15px',
    border: '1px solid #8E8FA1',
    position: 'relative',
  },
  radioWrapperTitle: {
    position: 'absolute',
    fontSize: '1em',
    textTransform: 'uppercase',
    top: -20,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: 'white',
    color: '#8E8FA1',
    zIndex: 10,
  },
  radioLabel: {
    margin: 0,
    padding: '5%',
    height: '100px',
    width: '100px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    color: '#3D319D',
    fontWeight: 'bold',
    border: '2px solid #3D319D',
    '&:hover': {
      backgroundColor: '#3D319D',
      color: 'white',
    },
  },
  // RADIO WITH ICON

  // OTHER RADIO
  radioTitle: {
    marginTop: '3%',
    fontSize: '1em',
    color: '#8E8FA1',
    textAlign: 'left',
  },
  smallRadioContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  smallRadioLabel: {
    margin: 0,
    padding: '20px',
    whiteSpace: 'nowrap',
    height: '1em',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    color: '#3D319D',
    fontWeight: 'bold',
    borderRight: '2px solid #3D319D',
    borderTop: '2px solid #3D319D',
    borderBottom: '2px solid #3D319D',
    '&:first-child': {
      borderLeft: '2px solid #3D319D',
      borderRadius: '10px 0% 0% 10px',
    },
    '&:last-child': {
      borderRadius: '0px 10px 10px 0px',
    },
    '&:hover': {
      backgroundColor: '#3D319D',
      color: 'white',
    },
  },
  selected: {
    backgroundColor: '#3D319D',
    color: 'white',
  },
  radioError: {
    borderColor: 'red !important',
    color: 'red',
  },
  radio: {
    display: 'none',
  },
  nameTextField: {
    margin: '1%',
  },
  nameTextFieldFull: {
    margin: '1%',
    width: '100%',
  },
  townAndZipCode: {
    display: 'flex',
  },
  town: {
    flex: 1,
  },
  zipCode: {
    flex: 0.5,
  },

  subCategories: {
    paddingLeft: '10%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SmallRadio = ({ title, options, value, name, style /* helpName */ }) => {
  const { touched, errors } = useFormikContext();
  const classes = useStyles();

  return (
    <>
      <div className={classes.inputWithHelpIconContainer}>
        <h2 className={classes.radioTitle}>{title}</h2>
        {/* {helpName && <HelpIcon helpName={helpName} />} */}
      </div>
      <Field
        component={RadioGroup}
        name={name}
        className={classes.smallRadioContainer}
        style={style}
      >
        {options.map((option) => (
          <FormControlLabel
            className={`${
              value === option.value
                ? `${classes.smallRadioLabel} ${classes.selected}`
                : classes.smallRadioLabel
            }
                ${touched[name] && errors[name] ? classes.radioError : ''}`}
            key={option.value}
            value={option.value}
            control={<Radio className={classes.radio} />}
            label={option.label.toUpperCase()}
          />
        ))}
      </Field>
    </>
  );
};

SmallRadio.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  name: PropTypes.string,
  style: PropTypes.object,
  helpName: PropTypes.string,
};

export default SmallRadio;
