import React from 'react';
import { FieldArray } from 'formik';
import { AddCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './styles';
import AddTypeItem from './AddTypeItem';

const AddType = ({
  title = 'Title',
  values = {},
  name = '',
  handleChange = () => {},
  errors = {},
  options = [],
  isContract = false,
  ...rest
}) => {
  const classes = styles.useStyles();
  const { t } = useTranslation();

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div className={classes.addTypeContainer}>
          <div className={classes.addTypeHeader}>
            <div className={classes.addTypeTitle}>{title}</div>
            <AddCircleOutline
              className={classes.btnAdd}
              onClick={() => {
                if (isContract) {
                  if (!values[name].length)
                    arrayHelpers.push({
                      accountNumber: '',
                    });
                  return;
                }
                if (values[name].length < options.length)
                  arrayHelpers.push({
                    vatRateId: '',
                    accountNumber: '',
                  });
              }}
            />
          </div>
          <div className={classes.list}>
            {values[name]?.map((_, index) => {
              return (
                <AddTypeItem
                  handleChange={handleChange}
                  values={values}
                  errors={(name in errors && errors[name][index]) || null}
                  key={name}
                  name={name}
                  handleRemove={() => {
                    arrayHelpers.remove(index);
                  }}
                  index={index}
                  options={options}
                  isContract={isContract}
                  {...rest}
                />
              );
            })}
            {!values[name].length && (
              <div className={classes.noData}>{t('categories.no_data')}</div>
            )}
          </div>
        </div>
      )}
    ></FieldArray>
  );
};

AddType.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  maxLength: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isContract: PropTypes.bool,
};
export default AddType;
