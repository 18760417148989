import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'components/materialtable/MaterialTable';
import { useTranslation } from 'react-i18next';
import {
  SUBSCRIPTION_INTERVAL,
  SUBSCRIPTION_STATUS,
} from 'constants/companySubscription';

const SubscriptionHistory = ({ subscriptionHistory }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t(
        'company.subscription.subscription_history.table.transaction_id'
      ),
      field: '_id',
    },
    {
      title: t(
        'company.subscription.subscription_history.table.subscription_name'
      ),
      field: 'subscriptionName',
    },
    {
      title: t(
        'company.subscription.subscription_history.table.subscription_type'
      ),
      field: 'subscriptionType',
    },
    {
      title: t('company.subscription.subscription_history.table.amount'),
      field: 'amount',
    },
    {
      title: t('company.subscription.subscription_history.table.user'),
      field: 'userName',
    },
    {
      title: t('company.subscription.subscription_history.table.status'),
      field: 'status',
    },
  ];

  const renderStatus = (status, subscription) => {
    if (subscription.modified) {
      const subscriptionStatus = SUBSCRIPTION_STATUS.find(
        (item) => item.value === 'modified'
      );
      return t(subscriptionStatus.label);
    }
    const subscriptionStatus = SUBSCRIPTION_STATUS.find(
      (item) => item.value === status
    );
    return t(subscriptionStatus?.label) || '';
  };

  const renderSubscriptionType = (type) => {
    const subscriptionInterval = SUBSCRIPTION_INTERVAL.find(
      (item) => item.value === type
    );
    return t(subscriptionInterval?.label) || '-';
  };

  const renderAmount = (amount) => {
    return `${(amount / 100).toFixed(2)} €`;
  };

  const dataTable = useMemo(() => {
    if (!subscriptionHistory?.length) return [];
    return subscriptionHistory.map((item) => ({
      ...item,
      status: renderStatus(item.status, item),
      subscriptionType: renderSubscriptionType(item.subscriptionType),
      amount: renderAmount(item.amount),
    }));
  }, [subscriptionHistory]);

  return (
    <MaterialTable
      title={t('company.subscription.subscription_history.title')}
      columns={columns}
      data={dataTable}
    />
  );
};

SubscriptionHistory.propTypes = {
  subscriptionHistory: PropTypes.array.isRequired,
};

export default SubscriptionHistory;
