import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Divider,
  CircularProgress,
  Box,
  TextField,
} from '@material-ui/core';
import AppActions from 'actions/AppActions';
import {
  fetchCreationCompanyForm,
  updateCreaForm,
} from 'actions/CompanyActions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '22px',
  },
  cardContainer: {
    justifyContent: 'center',
    marginBottom: '2em',
  },
  thinTitle: {
    fontWeight: 'lighter',
  },
  listItem: {
    marginBottom: '20px',
  },
  submitDateBtn: {
    marginLeft: '40px',
  },
  backBtn: {
    marginRight: 20,
    textDecoration: 'none',
  },
}));

const CreationCompany = (props) => {
  const classes = useStyles();
  const companyId = props.match.params._id;
  const previousCompanyId = useRef(companyId);
  const currentCompanyId = companyId;
  const [folderOwner, setFolderOwner] = useState('');

  const [loading, setLoading] = useState(false);
  const [creationCompany, setCreationCompany] = useState(null);

  const dispatch = useDispatch();

  const fetchCreationCompany = async (id) => {
    setLoading(true);
    try {
      const res = await dispatch(fetchCreationCompanyForm(id));

      setCreationCompany(res.data);
    } catch (error) {
      // eslint-disable-next-line
      console.error('Backoffice error: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(AppActions.setAppTitle('Company Creation'));
    if (previousCompanyId.current !== currentCompanyId) {
      previousCompanyId.current = currentCompanyId;
      fetchCreationCompany(companyId);
    }
  });

  useEffect(() => {
    if (creationCompany === null) {
      fetchCreationCompany(companyId);
    }
  }, [creationCompany]);

  const renderCreationCompany = () => {
    const { kit } = creationCompany;

    const email =
      creationCompany.director_email ||
      creationCompany.associe_gerant_multiassocie.partner_email ||
      creationCompany.associe_gerant_multiassocie[0].partner_email;

    const name =
      creationCompany.director_firstname ||
      creationCompany.associe_gerant_multiassocie.partner_firstname ||
      creationCompany.associe_gerant_multiassocie[0].partner_firstname;

    const onchange = (e) => {
      setFolderOwner(e.currentTarget.value);
    };

    const saveOnwer = () => {
      const res = {
        folderOwner,
      };
      dispatch(
        updateCreaForm(creationCompany.companyId, creationCompany._id, res)
      );
    };

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={classes.listItem}>
              <Typography variant="h6" className={classes.thinTitle}>
                Client
              </Typography>
              <Typography variant="h6">{name || 'N/A'}</Typography>
            </div>

            <div className={classes.listItem}>
              <Typography variant="h6" className={classes.thinTitle}>
                E-mail
              </Typography>
              <Typography variant="h6">{email || 'N/A'}</Typography>
            </div>

            <div className={classes.listItem}>
              <Typography variant="h6" className={classes.thinTitle}>
                Formule
              </Typography>
              <Typography variant="h6">{kit || 'N/A'}</Typography>
            </div>

            <Grid item xs={12}>
              <Link
                to={`/reviewdocs/${companyId}`}
                style={{ marginRight: '30px' }}
              >
                <Button variant="contained" color="primary">
                  LISTE DES DOCUMENTS
                </Button>
              </Link>

              <Link to={`/creationformdata/${companyId}`}>
                <Button variant="contained" color="primary">
                  Creation Entreprise Form Data
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.thinTitle}>
              Personne en charge du dossier
            </Typography>
            {creationCompany.folderOwner && (
              <Typography variant="h6">
                {creationCompany.folderOwner}
              </Typography>
            )}
            {!creationCompany.folderOwner && (
              <>
                <TextField
                  style={{ marginTop: '2%' }}
                  fullWidth
                  value={folderOwner}
                  id="folderOwner"
                  label="Nom de la personne en charge du dossier"
                  variant="outlined"
                  onChange={onchange}
                />
                <Button variant="contained" color="primary" onClick={saveOnwer}>
                  Enregistrer
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderBody = () => {
    if (creationCompany.length === 0) {
      return (
        <Typography variant="h4" style={{ textAlign: 'center' }}>
          Not found any creation company data
        </Typography>
      );
    }

    const {
      nom_propre: nomPropre,
      company_name: companyName,
    } = creationCompany;

    return (
      <>
        <Grid container spacing={2}>
          <Box display="flex" alignItems="center" mb={5}>
            <Link to="/companylist" className={classes.backBtn}>
              <Button variant="contained" color="primary">
                Back
              </Button>
            </Link>
            <Typography variant="h4">
              {nomPropre}: {companyName}
            </Typography>
          </Box>
        </Grid>
        <Divider />
        {creationCompany && renderCreationCompany()}
      </>
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  return <div className={classes.root}>{creationCompany && renderBody()}</div>;
};

CreationCompany.propTypes = {
  match: PropTypes.object,
};

export default CreationCompany;
