import React from 'react';
import GenerateField from 'components/GenerateField';
import PropTypes from 'prop-types';
import { TAXREGISTRATION } from 'constants/companies';
import ReadOnlyRadioGroup from 'components/ReadOnlyRadioGroup';
import SmallTextField from 'components/SmallTextField';

const TypeOfCompany = ({ values }) => {
  const {
    settings: { tax_registration: taxRegistration },
  } = values;
  const { companyType } = TAXREGISTRATION;
  const isCompany = taxRegistration.companyType === 'Société';
  const reponsiveLg = {
    xs: 12,
    sm: 12,
    md: 6,
  };

  const fiedls = [
    {
      gridReponsives: reponsiveLg,
      component: ReadOnlyRadioGroup,
      componentProps: {
        name: 'companyType',
        options: companyType,
        value: taxRegistration.companyType,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      isHidden: !isCompany,
      componentProps: {
        label: 'company.type_of_company.capital',
        value: values.capital,
      },
    },
  ];

  return (
    <GenerateField
      name="companyType"
      configs={fiedls}
      readOnly
      label="company.type_of_company.title"
    />
  );
};

TypeOfCompany.propTypes = {
  values: PropTypes.object,
};
export default TypeOfCompany;
