import LegalNoticeService from 'services/LegalNoticeService';
import {
  LEGAL_NOTICE_FAILED,
  LEGAL_NOTICE_SUCCESS,
  LEGAL_NOTICE_PROCESSING,
} from 'actions/Types';

const generateLegalNoticeFailed = (payload) => ({
  type: LEGAL_NOTICE_FAILED,
  payload:
    payload || new Error("Erreur lors de la génération de l'annonce légale"),
});

const generateLegalNoticeSuccess = () => ({
  type: LEGAL_NOTICE_SUCCESS,
});

const generateLegalNoticeProcessing = () => ({
  type: LEGAL_NOTICE_PROCESSING,
});

// eslint-disable-next-line
export const generateLegalNotice = (companyId) => async (dispatch) => {
  dispatch(generateLegalNoticeProcessing());
  try {
    const { status, data } = await LegalNoticeService.generateLegalNotice(
      companyId
    );
    if (status === 200) {
      dispatch(generateLegalNoticeSuccess());
    }
    return data.annonce;
  } catch (error) {
    dispatch(generateLegalNoticeFailed(error));
    return null;
  }
};
// eslint-disable-next-line
export const sendLegalNotice = (companyId, annonce) => async (dispatch) => {
  dispatch(generateLegalNoticeProcessing());
  try {
    const { status } = await LegalNoticeService.sendLegalNotice(
      companyId,
      annonce
    );
    if (status === 200) {
      dispatch(generateLegalNoticeSuccess());
    }
  } catch (error) {
    dispatch(generateLegalNoticeFailed(error));
  }
};
