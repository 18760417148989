import { authInstance as simpleInstance } from 'utils/axiosInterceptor';

const generateLegalNotice = (companyId) =>
  simpleInstance.post(
    `${process.env.REACT_APP_LEGAL_NOTICE_TEXT}`,
    {},
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );
const sendLegalNotice = (companyId, annonce) =>
  simpleInstance.post(
    `${process.env.REACT_APP_LEGAL_NOTICE_SEND}`,
    { annonce },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

export default { generateLegalNotice, sendLegalNotice };
