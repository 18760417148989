/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { Form, Formik } from 'formik';

// import { updateCategory } from 'actions/CategoriesActions';

import CategoryService from 'services/CategoryService';
import spinnerService from 'services/SpinnerService';
import { validationSchema } from './validation';
import { store } from '../../../store';
import AddType from './AddType';

const getTypes = (t) => [
  {
    id: 'isVatRates',
    display: t('categories.tva'),
  },
  {
    id: 'intracom',
    display: t('categories.intracom'),
  },
  {
    id: 'importation',
    display: t('categories.importation'),
  },
  {
    id: 'ss',
    display: t('categories.subcontract'),
  },
];

const formatSubmitAccounting = (arr = [], vatRates = [], account) => {
  const newArr = [...arr];
  const result = newArr.map((item) => {
    const currVat = vatRates.find((vat) => vat._id === item.vatRateId);
    return {
      vatRateId: item.vatRateId,
      vatRate: currVat.value,
      accountNumber: Number(
        String(account).slice(0, 3) + String(item.accountNumber)
      ),
    };
  });
  return result;
};

const formatInitAccounting = (arr = []) => {
  if (!arr.length) return arr;

  const newArr = arr.map((item) => ({
    ...item,
    accountNumber: item?.accountNumber?.toString().substring(3),
  }));
  return newArr;
};

const CategoryForm = ({
  showDialog,
  closeCategoryFormDialog,
  selectedCateInfo,
  getCategories,
}) => {
  const { t } = useTranslation();
  const { vatRates } = store.getState();

  const initValues = {
    display: selectedCateInfo?.display,
    account: selectedCateInfo?.account || '',
    description: selectedCateInfo?.description,
    helptext: selectedCateInfo?.helptext,
    hasProvider: selectedCateInfo?.hasProvider && true,
    isVatRates: !!selectedCateInfo?.tvaAccounting?.length || false,
    intracom: !!selectedCateInfo?.intracomAccounting?.length || false,
    importation: !!selectedCateInfo?.importationAccounting?.length || false,
    ss: selectedCateInfo?.contractAccounting
      ? !!Object.keys(selectedCateInfo?.contractAccounting).length || false
      : false,
    tvaAccounting: formatInitAccounting(selectedCateInfo?.tvaAccounting || []),
    intracomAccounting: formatInitAccounting(
      selectedCateInfo?.intracomAccounting || []
    ),
    importationAccounting: formatInitAccounting(
      selectedCateInfo?.importationAccounting || []
    ),
    ssValue: selectedCateInfo?.contractAccounting
      ? formatInitAccounting([selectedCateInfo?.contractAccounting] || [])
      : [],
  };

  async function onSubmit(values, action) {
    // format
    const tvaAccounting = values.isVatRates
      ? formatSubmitAccounting(values.tvaAccounting, vatRates, values.account)
      : null;
    const intracomAccounting = values.intracom
      ? formatSubmitAccounting(
          values.intracomAccounting,
          vatRates,
          values.account
        )
      : null;
    const importationAccounting = values.importation
      ? formatSubmitAccounting(
          values.importationAccounting,
          vatRates,
          values.account
        )
      : null;

    const contractAccounting =
      values.ss && values.ssValue.length > 0
        ? {
            accountNumber: Number(
              String(values.account).slice(0, 3) +
                String(values.ssValue[0]?.accountNumber)
            ),
          }
        : null;

    const vatIds = tvaAccounting?.map((item) => ({ id: item.vatRateId }));
    const format = {
      ...selectedCateInfo,
      ...values,
      tvaAccounting,
      intracomAccounting,
      importationAccounting,
      contractAccounting,
      vat: { ...selectedCateInfo.vat, vatRates: vatIds },
    };

    delete format.isVatRates;
    delete format.intracom;
    delete format.importation;
    delete format.ss;
    delete format.ssValue;

    try {
      spinnerService.startSpinner();
      const result = await CategoryService.updateCategory(
        selectedCateInfo._id,
        format
      );
      if (result.status === 200) {
        getCategories();
        closeCategoryFormDialog();
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      spinnerService.endSpinner();
    }
  }

  if (showDialog) {
    return (
      <div className="section p-10">
        <Formik
          initialValues={initValues}
          validationSchema={validationSchema({ t })}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
            dirty,
          }) => {
            return (
              <>
                <Form
                  onSubmit={(e) => {
                    if (!dirty) {
                      closeCategoryFormDialog();
                      return;
                    }
                    handleSubmit(e);
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{
                      paddingRight: 48,
                      paddingLeft: 48,
                      paddingBottom: 24,
                    }}
                    gap={2}
                  >
                    <Grid xs={12}>
                      <TextField
                        variant="outlined"
                        style={{ marginTop: 12 }}
                        name="display"
                        fullWidth
                        label={t('categories.display')}
                        placeholder={t('categories.display')}
                        id="display"
                        onChange={handleChange}
                        defaultValue={initValues.display}
                        size="small"
                      />
                      <Grid container spacing={8}>
                        <Grid xs={6} item>
                          <TextField
                            variant="outlined"
                            style={{ marginTop: 12 }}
                            name="account"
                            disabled
                            fullWidth
                            label={t('categories.account')}
                            placeholder={t('categories.account')}
                            id="account"
                            size="small"
                            onChange={handleChange}
                            defaultValue={initValues.account}
                          />
                        </Grid>

                        <Grid xs={6} item style={{ marginTop: 12 }}>
                          <FormControlLabel
                            xs={6}
                            name={'hasProvider'}
                            id={'hasProvider'}
                            control={
                              <Checkbox
                                defaultChecked={initValues.hasProvider}
                                color="primary"
                                onChange={(e) => {
                                  return handleChange(e);
                                }}
                              />
                            }
                            label={t('categories.has_provider')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        variant="outlined"
                        style={{ marginTop: 12 }}
                        fullWidth
                        name="description"
                        label={t('categories.description')}
                        placeholder={t('categories.description')}
                        size="small"
                        id="description"
                        onChange={handleChange}
                        defaultValue={initValues.description}
                      />
                    </Grid>
                    <TextField
                      variant="outlined"
                      style={{ marginTop: 12 }}
                      fullWidth
                      name="helptext"
                      label={t('categories.helptext')}
                      placeholder={t('categories.helptext')}
                      id="helptext"
                      onChange={handleChange}
                      defaultValue={initValues.helptext}
                      size="small"
                    />

                    <Grid
                      style={{
                        display: 'flex',
                        gap: 20,
                        alignItems: 'center',
                        marginTop: 20,
                      }}
                    >
                      <Typography variant="h6">
                        {t('categories.account_types')}
                      </Typography>
                      {getTypes(t).map((item, index) => (
                        <FormControlLabel
                          key={item.id}
                          name={item.id}
                          id={item.id}
                          control={
                            <Switch
                              color="primary"
                              defaultChecked={initValues[item.id]}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          }
                          label={item.display}
                        />
                      ))}
                    </Grid>
                    {values.isVatRates && (
                      <AddType
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        title={t('categories.vat_info')}
                        values={values}
                        name="tvaAccounting"
                        maxLength={5}
                        options={vatRates}
                      />
                    )}
                    {values.intracom && (
                      <AddType
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        title={t('categories.intracom_info')}
                        name="intracomAccounting"
                        values={values}
                        options={vatRates}
                        maxLength={4}
                      />
                    )}
                    {values.importation && (
                      <AddType
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        title={t('categories.import_info')}
                        name="importationAccounting"
                        values={values}
                        options={vatRates}
                        maxLength={4}
                      />
                    )}
                    {values.ss && (
                      <AddType
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        title={t('categories.sub_contract')}
                        name="ssValue"
                        values={values}
                        maxLength={1}
                        options={vatRates}
                        isContract
                      />
                    )}

                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: 12 }}
                      justify="flex-end"
                    >
                      <Button
                        disabled={isSubmitting}
                        color="primary"
                        variant="outlined"
                        onClick={closeCategoryFormDialog}
                        style={{ marginRight: 20 }}
                      >
                        {t('categories.cancel')}
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        color="primary"
                        type="submit"
                        variant="contained"
                      >
                        {t('categories.update')}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    );
  }

  return null;
};

CategoryForm.propTypes = {
  showDialog: PropTypes.bool,
  closeCategoryFormDialog: PropTypes.func,
  selectedCateInfo: PropTypes.object,
  getCategories: PropTypes.func,
};

export default CategoryForm;
