/* eslint-disable camelcase */
import { genders } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import React from 'react';
import PropTypes from 'prop-types';
import SmallRadio from 'components/SmallRadio';

function InfoSpouce({ classes }) {
  const { values } = useFormikContext();
  const { partner_spouse_title } = values;
  return (
    <>
      <h2 className={classes.subTitle}>Informations sur votre conjoint.</h2>
      <SmallRadio
        classes={classes}
        options={genders}
        value={partner_spouse_title}
        name="partner_spouse_title"
        style={{ marginBottom: '2%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom du conjoint"
        name="partner_spouse_name"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom du conjoint"
        name="partner_spouse_firstname"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de naissance du conjoint *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="partner_spouse_date_naissance"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      {/* <Field
        component={TextField}
        type="string"
        label="Date de naissace du conjoint"
        name="partner_spouse_date_naissance"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
      /> */}
      <Field
        component={TextField}
        type="string"
        label="Lieu de naissace du conjoint"
        name="partner_spouse_lieu_naissance"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de l'union *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="partner_wedding_date"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      {/* <Field
        component={TextField}
        type="string"
        label="Date de l'union"
        name="partner_wedding_date"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
      /> */}
      <Field
        component={TextField}
        type="string"
        label="Lieu de l'union"
        name="partner_wedding_place"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
      />
    </>
  );
}

InfoSpouce.propTypes = {
  classes: PropTypes.object,
};

export default InfoSpouce;
