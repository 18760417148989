import React from 'react';
import GenerateField from 'components/GenerateField';
import PropTypes from 'prop-types';
import ReadOnlyCheckboxGroup from 'components/ReadOnlyCheckboxGroup';

const Activity = ({ values }) => {
  const {
    settings: { tax_registration: taxRegistration },
  } = values;

  const fiedls = [
    {
      component: ReadOnlyCheckboxGroup,
      componentProps: {
        options: [
          {
            label: 'company.activity.prestation',
            value: taxRegistration?.prestation,
          },
          {
            label: 'company.activity.vente',
            value: taxRegistration?.vente,
          },
        ],
      },
    },
  ];

  return (
    <GenerateField
      name={'activity'}
      configs={fiedls}
      readOnly
      label="company.activity.title"
    />
  );
};

Activity.propTypes = {
  values: PropTypes.object,
};
export default Activity;
