// TODO: move int companydetails

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import WalletActions from 'actions/WalletActions';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#3d319d',
    fontWeight: 500,
    textAlign: 'center',
  },
  closeAccountBtn: {
    backgroundColor: '#ef5350',
    borderColor: '#ef5350',
    color: 'white',
    '&:hover': {
      backgroundColor: '#b61827',
      borderColor: '#b61827',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#b61827',
      borderColor: '#b61827',
    },
  },
}));

const CompanyCloseAccount = (props) => {
  const { companyId } = props.match.params;
  const classes = useStyles();
  const history = useHistory();

  const [wallet, setWallet] = useState(null);

  useEffect(() => {
    (async () => {
      if (companyId) {
        const [wallets] = await WalletActions.fetchWalletDetails(companyId);
        setWallet(wallets);
      }
    })();
  }, []);

  const closeAccount = () => {
    // update all the usercompany to remove the treezorUser attribute from the UserCompany collection
    // update the company collection to remove the treezor user attribute on the company
    // call the delete operation on all treeor user linked to this company, including the treezoruser of the company,
    // then call a delete operation on the wallet
  };

  return (
    <div style={{ width: '100%' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <Typography className={classes.heading} variant="h5" component="h5">
        CLOTURE COMPTE TREEZOR
      </Typography>

      {wallet && (
        <Box
          my={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ width: 400 }}>
            <div>Wallet Id: {wallet.walletId}</div>
            <div>Treezor user Id: {wallet.userId}</div>
            <div>Solde: {wallet.solde} €</div>
            <p>msg pour informer le client qu’il doit vider ses comptes</p>
          </div>
          <Button
            variant="contained"
            disabled={wallet.solde !== 0}
            className={classes.closeAccountBtn}
            onClick={closeAccount}
          >
            CLOTURER LE COMPTE
          </Button>
        </Box>
      )}
    </div>
  );
};

CompanyCloseAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyId: PropTypes.number.isRequired,
    }),
  }),
};

export default CompanyCloseAccount;
