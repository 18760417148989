import { Box, Button, Modal, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  modal: {
    textAlign: 'center',
    marginTop: '5%',
    margin: '0 auto',
    width: '50%',
    backgroundColor: 'white',
    borderRadius: '15px',
    padding: '2em',
  },
  actions: {
    width: '100%',
    marginTop: '5%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ModalLegalNotice = ({ annonce, handleClose, sendLegalAnnonce }) => {
  const classes = useStyles();

  const formatToHtml = () => {
    const annonceHtml = annonce;
    return annonceHtml.replaceAll('\n', '<br />');
  };

  return (
    <Modal
      open={annonce !== null}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        <Typography
          id="modal-modal-title"
          variant="h4"
          style={{ margin: '2%' }}
        >
          Annonce legale
        </Typography>
        {annonce && (
          <div
            style={{ textAlign: 'left' }}
            dangerouslySetInnerHTML={{ __html: formatToHtml() }}
          />
        )}
        {/* {annonce && (
          <TextareaAutosize
            style={{ width: '100%' }}
            aria-label="maximum height"
            // dangerouslySetInnerHTML={{ __html: formatToHtml() }}
            value={formatToHtml()}
          />
        )} */}
        <Box className={classes.actions}>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
            // disabled={disabled}
          >
            Fermer
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              sendLegalAnnonce(annonce);
              handleClose();
            }}
            // disabled={disabled}
          >
            ENVOYER l’annonce legale
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ModalLegalNotice.propTypes = {
  annonce: PropTypes.object,
  handleClose: PropTypes.func,
  sendLegalAnnonce: PropTypes.func,
};

export default ModalLegalNotice;
