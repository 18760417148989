import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const TransactionDetails = ({ transactionId, transaction }) => {
  const classes = useStyles();
  return (
    <>
      <List className={classes.root} subheader={<li />}>
        <li key={`section-${transactionId}`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader>{`_id: ${transactionId}`}</ListSubheader>
            <ListItem>
              <ListItemText primary={`Amount : ${transaction.amount}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`CreatedDate : ${transaction.createdDate}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Currency : ${transaction.currency}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Description : ${transaction.description}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`ExecutionDate : ${transaction.executionDate}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={`ExpenseId : ${transaction.expenseId}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`ForeignId : ${transaction.foreignId}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Name: ${transaction.name}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`TransactionId: ${transaction.transactionId}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`TransactionType : ${transaction.transactionType}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={`ValueDate : ${transaction.valueDate}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`WalletCreditBalance : ${transaction.walletCreditBalance}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`WalletCreditId : ${transaction.walletCreditId}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`WalletDebitBalance : ${transaction.walletDebitBalance}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`WalletDebitId : ${transaction.walletDebitId}`}
              />
            </ListItem>
          </ul>
        </li>
      </List>
    </>
  );
};

TransactionDetails.propTypes = {
  transactionId: PropTypes.string.isRequired,
  transaction: PropTypes.object.isRequired,
};

export default TransactionDetails;

// {transactionId}
// {transaction.amount}
