/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  // Typography,
  Button,
  Box,
  IconButton,
  Card,
  CardContent,
  // Divider,
  // OutlinedInput,
  // InputAdornment,
  // FormControl,
  CircularProgress,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Formik /* Field */ } from 'formik';
import CreationFormDataFields from 'views/CreationCompany/CreationFormDataFields';

import {
  fetchCreationCompanyForm,
  generateAllDocs,
  updateCreaForm,
} from 'actions/CompanyActions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '22px',
  },
  cardContainer: {
    justifyContent: 'center',
    marginBottom: '2em',
  },
  thinTitle: {
    fontWeight: 'lighter',
  },
  listItem: {
    marginBottom: '20px',
  },
  submitDateBtn: {
    marginLeft: '40px',
  },
  checkboxRound: {
    width: '1.3em',
    height: '1.3em',
    backgroundColor: 'white',
    borderRadius: '50%',
    verticalAlign: 'middle',
    border: '1px solid #ddd',
    // appearance: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  heading: {
    color: 'blue',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  tableData: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
  },
  backBtn: {
    marginRight: 20,
    textDecoration: 'none',
  },
}));

const CreationFormData = (props) => {
  const { _id: companyOId } = props.match.params;
  const history = useHistory();
  const classes = useStyles();
  const [viewRawData, setViewRawData] = useState(false);
  const dispatch = useDispatch();
  const [creaCompanyFormToRender, setCreaCompanyFormToRender] = useState(null);
  const [partnersToRender, setPartnersToRender] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const creaCompanyForm = useSelector(
    (state) => state.companies && state.companies.creaFormData
  );

  useEffect(() => {
    if (isEmpty(creaCompanyForm)) {
      (async () => {
        await dispatch(fetchCreationCompanyForm(companyOId));
      })();
    }
  }, []);

  useEffect(() => {
    if (creaCompanyForm && !isEmpty(creaCompanyForm)) {
      const filteredCreaCompanyForm = { ...creaCompanyForm };
      const { partners } = filteredCreaCompanyForm;

      setPartnersToRender(partners);

      delete filteredCreaCompanyForm._id;
      delete filteredCreaCompanyForm.stateOfClientFolder;
      delete filteredCreaCompanyForm.documents;
      delete filteredCreaCompanyForm.formStack;
      delete filteredCreaCompanyForm.presence_dg;
      delete filteredCreaCompanyForm.dg;
      delete filteredCreaCompanyForm.director_type;
      delete filteredCreaCompanyForm.director_title;
      delete filteredCreaCompanyForm.director_name;
      delete filteredCreaCompanyForm.director_firstname;
      delete filteredCreaCompanyForm.director_father_name;
      delete filteredCreaCompanyForm.director_father_firstname;
      delete filteredCreaCompanyForm.director_mother_name;
      delete filteredCreaCompanyForm.director_mother_firstname;
      delete filteredCreaCompanyForm.director_date_of_birth;
      delete filteredCreaCompanyForm.director_place_of_birth;
      delete filteredCreaCompanyForm.director_place_of_birth_zipCode;
      delete filteredCreaCompanyForm.director_state_of_birth;
      delete filteredCreaCompanyForm.director_country_of_birth;
      delete filteredCreaCompanyForm.director_nationality;
      delete filteredCreaCompanyForm.director_place_of_living;
      delete filteredCreaCompanyForm.director_city;
      delete filteredCreaCompanyForm.director_zipcode;
      delete filteredCreaCompanyForm.director_email;
      delete filteredCreaCompanyForm.associe_gerant_multiassocie;
      delete filteredCreaCompanyForm.currentForm;
      delete filteredCreaCompanyForm.dgPartnerID;
      delete filteredCreaCompanyForm.partnerId;
      delete filteredCreaCompanyForm.isReview;
      delete filteredCreaCompanyForm.dg_type;
      delete filteredCreaCompanyForm.dg_title;
      delete filteredCreaCompanyForm.dg_name;
      delete filteredCreaCompanyForm.dg_firstname;
      delete filteredCreaCompanyForm.dg_father_name;
      delete filteredCreaCompanyForm.dg_father_firstname;
      delete filteredCreaCompanyForm.dg_mother_name;
      delete filteredCreaCompanyForm.dg_mother_firstname;
      delete filteredCreaCompanyForm.dg_date_of_birth;
      delete filteredCreaCompanyForm.dg_place_of_birth;
      delete filteredCreaCompanyForm.dg_place_of_birth_zipCode;
      delete filteredCreaCompanyForm.dg_state_of_birth;
      delete filteredCreaCompanyForm.dg_country_of_birth;
      delete filteredCreaCompanyForm.dg_nationality;
      delete filteredCreaCompanyForm.dg_place_of_living;
      delete filteredCreaCompanyForm.dg_city;
      delete filteredCreaCompanyForm.dg_zipcode;
      delete filteredCreaCompanyForm.dg_email;
      delete filteredCreaCompanyForm.director_adresse_actionnaire_personne_morale_sasu;
      delete filteredCreaCompanyForm.director_capital_personne_morale;
      delete filteredCreaCompanyForm.director_code_postal_actionnaire_personne_morale_sasu;
      delete filteredCreaCompanyForm.director_forme_juridique_personne_morale;
      delete filteredCreaCompanyForm.director_raison_sociale_actionnaire_personne_morale_sasu;
      delete filteredCreaCompanyForm.director_rcs_actionnaire_personne_morale_sasu;
      delete filteredCreaCompanyForm.director_ville_actionnaire_personne_morale_sasu;
      delete filteredCreaCompanyForm.director_ville_rcs_actionnaire_personne_morale_sasu;
      delete filteredCreaCompanyForm.filter;
      delete filteredCreaCompanyForm.creaformOID;
      delete filteredCreaCompanyForm.__v;
      delete filteredCreaCompanyForm.updated_at;
      delete filteredCreaCompanyForm.created_at;
      delete filteredCreaCompanyForm.id;
      delete filteredCreaCompanyForm.partners;

      setCreaCompanyFormToRender(filteredCreaCompanyForm);
    }
  }, [creaCompanyForm]);

  const renderRawData = () => {
    return (
      <pre>
        <code
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(creaCompanyForm, null, 2),
          }}
        ></code>
      </pre>
    );
  };

  const onUpdateCompanyCreationForm = async (values, actions) => {
    const { submitCondition } = values;
    const { setValues } = actions;

    const {
      isPartnerEditing,
      selectedPartnerIdx,
      generalFormValues,
      partnerFormValues,
    } = submitCondition;

    let data;

    if (isPartnerEditing) {
      const partners = [...partnersToRender];
      partners[selectedPartnerIdx] = { ...partnerFormValues };
      data = { ...generalFormValues, partners };
      setValues(generalFormValues);
    } else {
      data = { ...creaCompanyForm, ...values };
    }

    setLoading(true);
    const { _id: companyCreationFormOId } = creaCompanyForm;

    delete data._id;
    delete data.creaformOID;
    await dispatch(updateCreaForm(companyOId, companyCreationFormOId, data));
    await dispatch(fetchCreationCompanyForm(companyOId));
    await dispatch(generateAllDocs(data));
    setLoading(false);
  };

  const renderCreationForm = () => {
    return (
      <Card variant="outlined" style={{ marginTop: 20 }}>
        <CardContent>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <Formik
              initialValues={creaCompanyFormToRender}
              onSubmit={onUpdateCompanyCreationForm}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CreationFormDataFields
                  classes={classes}
                  partnersToRender={partnersToRender}
                />
              </MuiPickersUtilsProvider>
            </Formik>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      {creaCompanyForm && !isEmpty(creaCompanyForm) && (
        <Grid container spacing={2}>
          <Box display="flex" alignItems="center" mb={5}>
            <IconButton
              className={classes.backBtn}
              variant="contained"
              color="primary"
              onClick={() => history.goBack()}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Button
              variant="text"
              color="primary"
              onClick={() => setViewRawData(!viewRawData)}
            >
              <VisibilityIcon />
              <span style={{ marginLeft: 10 }}>Creation company form data</span>
            </Button>
          </Box>
          <Grid item xs={12}>
            {viewRawData && renderRawData()}
            {creaCompanyFormToRender &&
              !isEmpty(creaCompanyFormToRender) &&
              renderCreationForm()}
          </Grid>
        </Grid>
      )}
    </>
  );
};

CreationFormData.propTypes = {
  match: PropTypes.object,
};

export default CreationFormData;
