import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SNACK_MESSAGE } from 'actions/Types';
import { INFO } from 'constants/severity';

const SnackBar = () => {
  const dispatch = useDispatch();
  const {
    snackMessage: { message, severity },
  } = useSelector((state) => state.app);

  const handleClose = () => {
    dispatch({
      type: SET_SNACK_MESSAGE,
      payload: {
        message: null,
        severity: INFO,
      },
    });
  };

  return (
    <Snackbar open={!!message} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
