import bridgeService from 'services/bridgeService';
import {
  RESET_BRIDGE_WEBHOOK,
  FETCHING_BRIDGE_WEBHOOK_FAILED,
  START_PROGRESS,
  END_PROGRESS,
  FETCHING_BRIDGE_WEBHOOK_SUCCESS,
} from 'actions/Types';
import { store } from '../../store';

const removeError = async () => {
  store.dispatch({
    type: RESET_BRIDGE_WEBHOOK,
  });
};

const refreshBridgeWebhooks = async (query) => {
  try {
    store.dispatch({
      type: RESET_BRIDGE_WEBHOOK,
    });

    const result = await bridgeService.retrieveBridgeWebhooks(query);

    store.dispatch({
      type: FETCHING_BRIDGE_WEBHOOK_SUCCESS,
      payload: result.data,
    });
    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_BRIDGE_WEBHOOK_FAILED,
      payload: error,
    });

    return error;
  }
};

const fetchBridgeWebhooks = async (query) => {
  try {
    const result = await bridgeService.retrieveBridgeWebhooks(query);

    if (result.error) {
      throw result;
    }

    store.dispatch({
      type: FETCHING_BRIDGE_WEBHOOK_SUCCESS,
      payload: result.data,
    });

    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_BRIDGE_WEBHOOK_FAILED,
      payload: error,
    });

    return error;
  }
};

const fetchBridgeWebhookDetails = async (webhookId) => {
  try {
    store.dispatch({
      type: START_PROGRESS,
    });
    const result = await bridgeService.fetchWebhookDetails(webhookId);

    if (result.error) {
      throw result;
    }
    store.dispatch({
      type: END_PROGRESS,
    });
    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_BRIDGE_WEBHOOK_FAILED,
      payload: error,
    });
    store.dispatch({
      type: END_PROGRESS,
    });
    return error;
  }
};

export default {
  fetchBridgeWebhooks,
  fetchBridgeWebhookDetails,
  refreshBridgeWebhooks,
  removeError,
};
