import { categoriesInstance as axios } from 'utils/axiosInterceptor';

const fetchCategories = async (filter) =>
  axios.get(
    `${process.env.REACT_APP_CATEGORIES_URL}/admin/categories-template`,
    {
      params: filter,
    }
  );

const updateCategory = async (id, data) =>
  axios.put(
    `${process.env.REACT_APP_CATEGORIES_URL}/admin/category/${id}`,
    data
  );
export default {
  fetchCategories,
  updateCategory,
};
