import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'components/materialtable/MaterialTable';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// import TreezorService from 'services/treezorservice';
import { FRAUD_LIMIT_PAYIN, FRAUD_LIMIT_PAYOUT, PAYIN, PAYOUT } from 'Config';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#3d319d',
  },
}));

const WalletTransactions = (props) => {
  const classes = useStyles();
  const { transactions } = props;

  const columns = [
    {
      title: '_id',
      field: '_id',
      // <Link to={`/userdetails/${user._id}`}>
      //                   {user._id}
      //                 </Link>
      // eslint-disable-next-line react/display-name
      render: (item) => <>{item._id}</>,
    },
    {
      title: 'Debit',
      field: 'debit',
      filtering: false,
      // eslint-disable-next-line react/display-name
      render: (item) =>
        item.transactionType === PAYOUT ? <>{item.amount}</> : '',
    },
    {
      title: 'Credit',
      field: 'credit',
      filtering: false,
      // eslint-disable-next-line react/display-name
      render: (item) =>
        item.transactionType === PAYIN ? <>{item.amount}</> : '',
    },
    {
      title: 'Devise',
      field: 'currency',
      // eslint-disable-next-line react/display-name
      render: (item) => <>{item.currency}</>,
    },
    {
      title: 'Date de création',
      field: 'created_at',
      // eslint-disable-next-line react/display-name
      render: (item) => <>{item.created_at}</>,
    },
    {
      title: "Date d'exécution",
      field: 'execution_date',
      // eslint-disable-next-line react/display-name
      render: (item) => <>{item.executionDate}</>,
    },
    // {
    //   title: 'Expense Id',
    //   field: 'expense_id',
    //   // eslint-disable-next-line react/display-name
    //   render: (item) => <>{item.expenseId}</>,
    // },
    {
      title: 'Transaction Id',
      field: 'transaction_Id',
      // eslint-disable-next-line react/display-name
      render: (item) => <>{item.transactionId}</>,
    },
  ];

  const renderLineInRed = (rowData) => {
    const { transactionType, amount } = rowData;

    switch (transactionType) {
      case PAYIN: {
        return {
          backgroundColor: Number(amount) > FRAUD_LIMIT_PAYIN ? 'red' : '#FFF',
          color: Number(amount) > FRAUD_LIMIT_PAYIN ? '#FFF' : 'black',
        };
      }
      case PAYOUT: {
        return {
          backgroundColor: Number(amount) > FRAUD_LIMIT_PAYOUT ? 'red' : '#FFF',
          color: Number(amount) > FRAUD_LIMIT_PAYOUT ? '#FFF' : 'black',
        };
      }
      default: {
        return {
          backgroundColor: '#FFF',
          color: 'black',
        };
      }
    }
  };

  return (
    <div>
      <Typography variant="h5" className={classes.heading}>
        LISTE DES TRANSACTIONS
      </Typography>
      {transactions &&
        transactions.length > 0 &&
        transactions.map((transaction, index) => (
          <div key={index}>
            <MaterialTable
              title={`Wallet Transaction ${index + 1}`}
              key={index}
              columns={columns}
              data={transaction}
              options={{
                rowStyle: (rowData) => renderLineInRed(rowData),
              }}
            />
            <br />
          </div>
        ))}
    </div>
  );
};

WalletTransactions.propTypes = {
  transactions: PropTypes.object,
};

export default WalletTransactions;
