import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import { IconImage } from '../AppIcons';

import ImagePreview from './ImagePreview';
import PdfPreview from './PdfPreview';

const FilePreview = ({ content: { type, value } }) => {
  const [isDialog, setIsDialog] = useState(false);

  if (!value) {
    // return <IconImage xl secondary fill />;
    return <p>Empty</p>;
  }

  if (type === 'application/pdf') {
    return <PdfPreview file={value} />;
  }

  const toggleDialog = (isOpen) => {
    setIsDialog(isOpen);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.3)',
      }}
    >
      <ImagePreview
        file={value}
        toggleDialog={toggleDialog}
        isDialog={isDialog}
      />
    </div>
  );
};

FilePreview.propTypes = {
  content: PropTypes.object,
  toggleDialog: PropTypes.func,
  isDialog: PropTypes.bool,
};

FilePreview.defaultProps = {
  isDialog: false,
};

export default FilePreview;
