/* eslint-disable no-console */
import { useState, useEffect, useRef } from 'react';
import socketio from 'socket.io-client';

export default function useSocket({ socketUrl }) {
  const [isOnline, setIsOnline] = useState(false);
  const [reconnecting, setReconnecting] = useState(false);
  const socket = useRef(null);

  useEffect(() => {
    console.log('running socket hook');
    socket.current = socketio.connect(socketUrl, { transports: ['websocket'] });

    socket.current.on('connect', () => {
      console.log('SocketIO: Connected and authenticated', socket.current.id);
      setIsOnline(true);
      setReconnecting(false);
    });

    socket.current.on('disconnect', (reason) => {
      console.log(`SocketIO: Disconnect ${socket.id}:`, reason);
      setIsOnline(false);
      if (socket.current) {
        if (reconnecting) return;
        setReconnecting(true);
      }
    });

    socket.current.on('error', (msg) => {
      console.error('SocketIO: Error', msg);
    });

    return () => {
      socket.current.disconnect();
      // socket.current = null;
    };
  }, [socketUrl]);

  return {
    socket,
    reconnecting,
    isOnline,
  };
}
