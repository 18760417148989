import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const ReadOnlyCheckboxGroup = ({ options }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.radioGroup}>
      {options.map((item, idx) => (
        <div key={idx} className={classes.radio}>
          <Checkbox
            name={item.label}
            value={item.value}
            disabled
            checked={item.value}
          />
          {t(item.label)}
        </div>
      ))}
    </div>
  );
};

ReadOnlyCheckboxGroup.propTypes = {
  options: PropTypes.array,
};

export default ReadOnlyCheckboxGroup;
