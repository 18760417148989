/* eslint-disable camelcase */
// import HelpIcon from 'components/commons/HelpIcon';
import { weddingStatuts, YES, yesNo } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import React from 'react';
import Adresse from 'components/Adresse';
import SmallRadio from 'components/SmallRadio';
import InfoSpouce from './InfoSpouce';

function InfoWedding({ classes }) {
  const { values } = useFormikContext();
  const { partner_wedding_prenup_type, partner_wedding_prenup } = values;
  return (
    <>
      {/* <div className={classes.inputWithHelpIconContainer}>
        <h2 className={classes.subTitle}>Informations sur votre mariage.</h2>
        <HelpIcon helpName="wedding" />
      </div> */}
      <SmallRadio
        classes={classes}
        title="Régime matrimonial"
        options={weddingStatuts}
        value={partner_wedding_prenup_type}
        name="partner_wedding_prenup_type"
      />
      <InfoSpouce classes={classes} />
      <SmallRadio
        classes={classes}
        title="Avez-vous conclu un contrat de mariage ?"
        options={yesNo}
        value={partner_wedding_prenup}
        name="partner_wedding_prenup"
      />
      {partner_wedding_prenup === YES && (
        <>
          <h2 className={classes.radioTitle}>
            Information sur le contrat de mariage.
          </h2>
          <Field
            component={KeyboardDatePicker}
            clearable
            label="Date de signature *"
            placeholder="01/01/1990"
            format="dd/MM/yyyy"
            maxDate={new Date()}
            name="partner_wedding_prenup_date"
            className={classes.nameTextField}
            inputVariant="outlined"
            size="small"
          />
          {/* <Field
            component={TextField}
            type="string"
            label="Date de signature"
            name="partner_wedding_prenup_date"
            className={classes.nameTextField}
            autoComplete="off"
            variant="outlined"
            size="small"
          /> */}
          <Field
            component={TextField}
            type="string"
            label="Lieu de signature"
            name="partner_wedding_prenup_place"
            className={classes.nameTextField}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
          <Field
            component={TextField}
            type="string"
            label="Nom de l'office notarial"
            name="partner_wedding_prenup_officer_office_name"
            className={classes.nameTextField}
            autoComplete="off"
            variant="outlined"
            size="small"
            inputProps={{ style: { textTransform: 'capitalize' } }}
          />
          <Adresse
            classes={classes}
            title="Adresse de l'office notarial *"
            address="partner_wedding_prenup_officer_office_address"
            city="partner_wedding_prenup_officer_office_city"
            zipCode="partner_wedding_prenup_officer_office_zipcode"
            department={null}
            country={null}
            cityLabel="Ville *"
            nationality={null}
          />
        </>
      )}
    </>
  );
}

InfoWedding.propTypes = {
  classes: PropTypes.object,
};

export default InfoWedding;
