import React from 'react';
import GenerateField from 'components/GenerateField';
import PropTypes from 'prop-types';
import { TAXREGISTRATION } from 'constants/companies';
import ReadOnlyRadioGroup from 'components/ReadOnlyRadioGroup';

const BookKeeping = ({ taxRegistration }) => {
  const fiedls = [
    {
      component: ReadOnlyRadioGroup,
      componentProps: {
        name: 'bookkeeping',
        options: TAXREGISTRATION.bookKeeping,
        value: taxRegistration.bookkeeping,
      },
    },
  ];

  return (
    <GenerateField
      name={'bookkeeping'}
      configs={fiedls}
      readOnly
      label="company.book_keeping.title"
    />
  );
};

BookKeeping.propTypes = {
  taxRegistration: PropTypes.object,
};
export default BookKeeping;
