import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Typography, makeStyles } from '@material-ui/core';
import SmallTextField from 'components/SmallTextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  bankDetailsContainer: {
    padding: '20px',
    width: '350px',
    margin: '0 auto',
  },
  divider: {
    marginBottom: '20px',
    backgroundColor: 'transparent',
  },
}));

const BankDetails = ({ bankDetails }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (!bankDetails?.length) return null;
  return bankDetails.map((bankDetail) => (
    <Fragment key={bankDetail.iban}>
      <Typography component={'h6'} variant="h6">
        {t('company.bank_details.title')}
      </Typography>
      <Paper elevation={3} className={classes.bankDetailsContainer}>
        <SmallTextField
          label="company.bank_details.bank_name"
          value={bankDetail.bank_name}
        />
        <Divider className={classes.divider} />
        <SmallTextField
          label="company.bank_details.iban"
          value={bankDetail.iban}
        />
        <Divider className={classes.divider} />
        <SmallTextField
          label="company.bank_details.bic"
          value={bankDetail.bic}
        />
        <Divider className={classes.divider} />
        <SmallTextField
          label="company.bank_details.wallet_id"
          value={bankDetail.walletId}
        />
        <Divider className={classes.divider} />
        <SmallTextField
          label="company.bank_details.codeJournal"
          value={bankDetail.journalCode}
        />
        <Divider className={classes.divider} />
        <SmallTextField
          label="company.bank_details.bank_account"
          value={bankDetail.account}
        />
        <Divider className={classes.divider} />
        <SmallTextField
          label="company.bank_details.beneficiary_id"
          value={bankDetail.beneficiaryId}
        />
      </Paper>
    </Fragment>
  ));
};

BankDetails.propTypes = {
  bankDetails: PropTypes.array,
};
export default BankDetails;
