import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PublishIcon from '@material-ui/icons/Publish';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MailIcon from '@material-ui/icons/Mail';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const ListOfDocs = ({
  documents,
  handleDocument,
  setDialogVisible,
  setOpeningDocument,
  downloadFile,
  companyId,
}) => {
  return (
    <List>
      {documents &&
        documents.map((file, idx) => (
          <ListItem key={idx}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText>
              {file.title}
              {file.version && ` (v${file.version})`}
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton aria-label="view">
                <Link
                  to={`/uploaddocument/${encodeURIComponent(
                    file.title
                  )}/${companyId}`}
                >
                  <PublishIcon />
                </Link>
              </IconButton>
              {file.notary && (
                <Tooltip title="Envoyé au notaire">
                  <span>
                    <IconButton disabled>
                      <AttachFileIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {file.recipients.length > 0 && (
                <Tooltip title="Ajouté dans l'enveloppe Docusign">
                  <span>
                    <IconButton disabled>
                      <MailIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <IconButton
                aria-label="view"
                onClick={async () => {
                  const document = await handleDocument(file);
                  setDialogVisible(true);
                  setOpeningDocument(document);
                }}
                // eslint-disable-next-line
                disabled={!file.hasOwnProperty('key')}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="download"
                // eslint-disable-next-line
                disabled={!file.hasOwnProperty('key')}
              >
                <span onClick={() => downloadFile(file)} download>
                  <GetAppIcon />
                </span>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
};

ListOfDocs.propTypes = {
  documents: PropTypes.object.isRequired,
  handleDocument: PropTypes.func,
  setDialogVisible: PropTypes.func,
  setOpeningDocument: PropTypes.func,
  downloadFile: PropTypes.func,
  companyId: PropTypes.string,
};

export default ListOfDocs;
