import trezorService from 'services/treezorservice';

const fetchSEPAAdminRequestAction = (queryString) => {
  const responseData = trezorService.fetchSEPAAdminRequest(queryString);
  return responseData;
};

const updateSEPAAdminRequestTypeAction = (formData) => {
  const responseData = trezorService.updateSEPAAdminRequestType(formData);
  return responseData;
};

const fetchSEPARequestByIdAction = (requestOId) => {
  const responseData = trezorService.fetchSEPARequestById(requestOId);
  return responseData;
};

const fetchSEPATransactionAction = (foreignId) => {
  const responseData = trezorService.fetchSEPATransaction(foreignId);
  return responseData;
};

export {
  fetchSEPAAdminRequestAction,
  updateSEPAAdminRequestTypeAction,
  fetchSEPARequestByIdAction,
  fetchSEPATransactionAction,
};
