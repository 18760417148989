import React from 'react';

import { useAuth0 } from 'auth/react-auth0-spa';

const LogoutView = () => {
  const { logout } = useAuth0();

  logout({
    returnTo: window.location.origin,
  });

  return <div>logging out</div>;
};

LogoutView.propTypes = {};

export default LogoutView;
