import { authInstance as axios } from 'utils/axiosInterceptor';

const fetchUsers = (query) =>
  axios.get(`${process.env.REACT_APP_USER_URL}/admin/users?${query}`);

const fetchUser = (id) =>
  axios.get(`${process.env.REACT_APP_USER_URL}/admin/users/${id}`);

const updateUser = (id, data) =>
  axios.put(`${process.env.REACT_APP_USER_URL}/admin/users/${id}`, data);

const fetchUserCompanyByUserId = async (i) =>
  axios.get(`${process.env.REACT_APP_USER_URL}/admin/usercompany?user_id=${i}`);

const fetchUserCompanyByCompanyId = async (id) =>
  axios.get(
    `${process.env.REACT_APP_USER_URL}/admin/usercompany?company_id=${id}`
  );

export default {
  fetchUsers,
  fetchUser,
  updateUser,
  fetchUserCompany: fetchUserCompanyByUserId,
  fetchUserCompanyByCompanyId,
};
