import { authInstance as axios, simpleInstance } from 'utils/axiosInterceptor';

const fetchCreationCompany = (companyId) =>
  axios.get(`${process.env.REACT_APP_CREATION_COMPANY}`, {
    headers: {
      'X-Company-Id': companyId,
    },
  });

const getUrl = (companyId, key) =>
  axios.get(`${process.env.REACT_APP_GET_URL}?key=${key}`, {
    headers: {
      'X-Company-Id': companyId,
    },
  });

const updateCreaFormData = (companyId, OId, data) =>
  axios.put(`${process.env.REACT_APP_UPDATE_CREA_FORM}/${OId}`, data, {
    headers: {
      'X-Company-Id': companyId,
    },
  });

const recreateStatutesDocument = (companyId) =>
  axios.put(
    `${process.env.REACT_APP_DOCUMENT_NEW_VERSION}`,
    {},
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const sendEmailWithDocuments = (companyId) =>
  simpleInstance.post(
    `${process.env.REACT_APP_CREA_SEND_EMAIL_NOTARY}`,
    {},
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const sendFinalEmail = (companyId) =>
  simpleInstance.post(
    `${process.env.REACT_APP_CREA_SEND_FINAL_EMAIL}`,
    {},
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const genarateDocs = async (values, alternativeId, companyId) =>
  axios.put(
    process.env.REACT_APP_URL_GENERATE_DOCS,
    { ...values, alternativeId, companyId },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

// eslint-disable-next-line
export default {
  recreateStatutesDocument,
  fetchCreationCompany,
  getUrl,
  updateCreaFormData,
  sendEmailWithDocuments,
  sendFinalEmail,
  genarateDocs,
};
