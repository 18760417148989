import {
  FETCHING_LOGGED_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER_SUCCESS,
} from 'actions/Types';

const INITIAL_STATE = {
  user: null,
  isFetchingUser: false,
  errorFetchingUser: null,
  isAdmin: false, // this boolean is not used as the authorzer always returns 403 if usser is not admin
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_LOGGED_USER:
      return {
        ...state,
        isFetchingUser: true,
        errorFetchingUser: null,
      };

    case LOGIN_USER_SUCCESS: {
      const { isAdmin, user } = action.payload;

      return {
        ...state,
        user,
        isAdmin,
        isFetchingUser: false,
        errorFetchingUser: null,
      };
    }

    case LOGIN_USER_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        user: null,
        isFetchingUser: false,
        isAdmin: false,
        errorFetchingUser: error,
      };
    }

    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
};
