import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AppActions from 'actions/AppActions';

const SEPAErrors = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AppActions.setAppTitle('SEPA Errors'));
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div>
          <table>
            <thead>
              <tr>
                <th>Code</th> <th>ISO Definition</th> <th>Reason</th>{' '}
                <th>Type of R-trans</th> <th>Use Cases</th> <th>Cause</th>{' '}
                <th>Suggestion</th>
              </tr>
            </thead>{' '}
            <tbody>
              <tr>
                <td>
                  <code>AC01</code>
                </td>{' '}
                <td>Incorrect Account Number</td>{' '}
                <td>
                  Account identifier invalid or incorrect (i.e. invalid IBAN or
                  account number does not exist)
                </td>{' '}
                <td>Reject, Return</td>{' '}
                <td>
                  Reject: • Invalid format of the IBAN; • IBAN not existing at
                  the Beneficiary Bank level. Return: IBAN not existing at
                  Beneficiary Bank level
                </td>{' '}
                <td>
                  Beneficiary gave invalid IBAN; Originator used wrong IBAN data
                  from its customers’ database; Originator had technical problem
                  during the processing of the SCT instruction issuance
                </td>{' '}
                <td>
                  Originator to contact the Beneficiary to get the correct IBAN
                  of the Beneficiary
                </td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AC03</code>
                </td>{' '}
                <td>Invalid Creditor Account Number</td>{' '}
                <td>Wrong unique identifier of the Beneficiary account</td>{' '}
                <td>RFRO</td>{' '}
                <td>
                  Originator has made an SCT transaction which was addressed to
                  a wrong IBAN
                </td>{' '}
                <td>
                  Originator itself selected or entered a wrong IBAN of the
                  Beneficiary when issuing the SCT instruction
                </td>{' '}
                <td>
                  Originator: • Adapt this internal SCT instruction issuance
                  processes to avoid the selection of a wrong IBAN in the
                  future; • Pay more attention in selecting/ entering the IBAN
                  when issuing a SCT instruction
                </td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AC04</code>
                </td>{' '}
                <td>Account closed</td> <td>Account closed</td>{' '}
                <td>Return, Negative answer to a Recall or to a RFRO</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AC06</code>
                </td>{' '}
                <td>Account blocked</td> <td>Account blocked</td>{' '}
                <td>Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>ACNR</code>
                </td>{' '}
                <td>Interbank positive response to Claim Non-Receipt</td>{' '}
                <td>Interbank positive response to Claim Non-Receipt</td>{' '}
                <td>Positive answer to SCT Inquiry “Claim Non- Receipt”</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>ACVA</code>
                </td>{' '}
                <td>Accepted Value Date Adjustment</td>{' '}
                <td>
                  Interbank positive response to Claim for Value Date Correction
                </td>{' '}
                <td>
                  Positive answer to SCT Inquiry “Claim for Value Date
                  Correction”
                </td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AG01</code>
                </td>{' '}
                <td>Transaction Forbidden</td>{' '}
                <td>
                  Credit transfer forbidden on this account (e.g., savings
                  account)
                </td>{' '}
                <td>Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AG02</code>
                </td>{' '}
                <td>Invalid Bank Operation Code</td>{' '}
                <td>
                  Operation code/ transaction code incorrect, invalid file
                  format
                </td>{' '}
                <td>Reject, Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AM04</code>
                </td>{' '}
                <td>Insufficient funds on the account</td>{' '}
                <td>Insufficient funds on the account</td>{' '}
                <td>Negative answer to a Recall or to a RFRO</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AM05</code>
                </td>{' '}
                <td>Duplicate payment</td> <td>Duplicate payment</td>{' '}
                <td>Reject, Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AM09</code>
                </td>{' '}
                <td>Wrong amount</td> <td>Wrong amount</td> <td>RFRO</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>AM23</code>
                </td>{' '}
                <td>Amount exceeds settlement limit</td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>ARDT</code>
                </td>{' '}
                <td>The Transaction Has Already Been Returned</td>{' '}
                <td>Already returned transaction</td>{' '}
                <td>
                  Negative • answer to a Recall or to a RFRO; Negative answer to
                  SCT Inquiry “Claim Non- Receipt”
                </td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>ARJT</code>
                </td>{' '}
                <td>Already Rejected Transaction</td>{' '}
                <td>Already rejected transaction</td>{' '}
                <td>Negative answer to SCT Inquiry “Claim Non- Receipt”</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>BE04</code>
                </td>{' '}
                <td>Missing Creditor Address</td>{' '}
                <td>Account address invalid</td> <td>Return</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>CERI</code>
                </td>{' '}
                <td>Check ERI</td>{' '}
                <td>
                  The credit transfer is not tagged as an Extended Remittance
                  Information (ERI) transaction but contains ERI
                </td>{' '}
                <td>Reject</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>CNOR</code>
                </td>{' '}
                <td>Creditor Bank is not registered</td>{' '}
                <td>
                  Beneficiary bank is not registered under this BIC in the CSM
                </td>{' '}
                <td>Reject, Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>CUST</code>
                </td>{' '}
                <td>Requested by customer</td>{' '}
                <td>
                  a. By request of Customer the Originator without any reason
                  specified. b. Refusal by the Beneficiary
                </td>{' '}
                <td>a. RFRO. b. Negative answer to a Recall or to a RFRO</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>CVAA</code>
                </td>{' '}
                <td>Correct value date already applied</td>{' '}
                <td>
                  Interbank negativ response to Claim for Value Date Correction
                </td>{' '}
                <td>
                  Negative answer to SCT Inquiry “Claim for Value Date
                  Correction”
                </td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>DNOR</code>
                </td>{' '}
                <td>Debtor bank is not registered</td>{' '}
                <td>Originator bank is not registered</td> <td>Reject</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>DUPL</code>
                </td>{' '}
                <td>Duplicate payment</td> <td>Duplicate sending</td>{' '}
                <td>Recall</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>ED05</code>
                </td>{' '}
                <td>Settlement failed</td>{' '}
                <td>Settlement of the SEPA Credit Transfer failed</td>{' '}
                <td>Reject</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>ERIN</code>
                </td>{' '}
                <td>ERI Option Not Supported</td>{' '}
                <td>
                  The Extended Remittance Information (ERI) option is not
                  supported
                </td>{' '}
                <td>Reject, Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>FF01</code>
                </td>{' '}
                <td>Invalid File Format</td>{' '}
                <td>
                  Operation/ transaction code incorrect, invalid file format
                </td>{' '}
                <td>Reject</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>FOCR</code>
                </td>{' '}
                <td>Following Cancellation Request</td>{' '}
                <td>Positive answer to the Recall</td>{' '}
                <td>Positive answer to a recall or to a RGRO</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>FRAD</code>
                </td>{' '}
                <td>Fraudulent Origin</td>{' '}
                <td>Fraudulent originated credit transfer</td> <td>Recall</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>LEGL</code>
                </td>{' '}
                <td>Legal Decision</td> <td>Legal reasons</td>{' '}
                <td>Negative answer to a Recall or to a RFRO</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>MD07</code>
                </td>{' '}
                <td>End Customer Deceased</td> <td>Beneficiary deceased</td>{' '}
                <td>Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>MODI</code>
                </td>{' '}
                <td>Modified As Per Request</td>{' '}
                <td>
                  Interbank confirmed positive response to Claim for Value Date
                  Correction
                </td>{' '}
                <td>
                  Confirmed positive answer to SCT Inquiry “Claim for Value Date
                  Correction”
                </td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>MS02</code>
                </td>{' '}
                <td>Not Specified Reason Customer Generated</td>{' '}
                <td>By order of the Beneficiary</td> <td>Return</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>MS03</code>
                </td>{' '}
                <td>Not Specified Reason</td> <td>Reason not specified</td>{' '}
                <td>Reject, Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>NERI</code>
                </td>{' '}
                <td>No ERI</td>{' '}
                <td>
                  The SEPA Credit Transfer is tagged as an Extended Remittance
                  Information (ERI) transaction but does not contain ERI
                </td>{' '}
                <td>Reject</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>NOAS</code>
                </td>{' '}
                <td>No Answer From Customer</td>{' '}
                <td>No response from Beneficiary</td>{' '}
                <td>Negative answer to a Recall or to a RFRO</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>NOOR</code>
                </td>{' '}
                <td>No Original Transaction Received</td>{' '}
                <td>Original Credit Transfer never received</td>{' '}
                <td>
                  Negative answer to a Recall or to a RFRO; Negative answer to
                  SCT Inquiry “Claim Non- Receipt”
                </td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>RC01</code>
                </td>{' '}
                <td>Bank Identifier Incorrect</td>{' '}
                <td>Bank identifier incorrect (i.e. invalid BIC)</td>{' '}
                <td>Reject, Return</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>RJNR</code>
                </td>{' '}
                <td>Rejected Claim Non- Receipt</td>{' '}
                <td>Interbank negative response to Claim Non-Receipt</td>{' '}
                <td>Negative answer to SCT Inquiry “Claim Non- Receipt”</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>RJVA</code>
                </td>{' '}
                <td>Rejected Value Date Adjustment</td>{' '}
                <td>
                  Interbank negative response to Claim for Value Date Correction
                </td>{' '}
                <td>
                  Negative answer to SCT Inquiry “Claim for Value Date
                  Correction”
                </td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>RNPR</code>
                </td>{' '}
                <td>Original Transaction Received but not processable</td>{' '}
                <td>Original Transaction Received but Not Processable</td>{' '}
                <td>Negative answer to SCT Inquiry “Claim Non- Receipt”</td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>RR01</code>
                </td>{' '}
                <td>Missing Debtor Account Or Identification</td>{' '}
                <td>Regulatory Reason</td> <td>Reject, Return</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>RR02</code>
                </td>{' '}
                <td>Missing Debtor’s Name Or Address</td>{' '}
                <td>Regulatory Reason</td> <td>Reject, Return</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>RR03</code>
                </td>{' '}
                <td>Missing Creditor’s Name Or Address</td>{' '}
                <td>Regulatory Reason</td> <td>Reject, Return</td> <td></td>{' '}
                <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>RR04</code>
                </td>{' '}
                <td>Regulatory Reason</td> <td>Regulatory Reason</td>{' '}
                <td>
                  Reject, Return; Negative answer to SCT Inquiry “Claim Non-
                  Receipt”
                </td>{' '}
                <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>TECH</code>
                </td>{' '}
                <td>Technical Problem</td>{' '}
                <td>Technical problems Recall. resulting in erroneous SCTs</td>{' '}
                <td>Recall</td> <td></td> <td></td> <td></td>
              </tr>{' '}
              <tr>
                <td>
                  <code>TM01</code>
                </td>{' '}
                <td>Cut Off Time</td> <td>File received after Cut-off Time</td>{' '}
                <td>Reject</td> <td></td> <td></td> <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
    </Grid>
  );
};

export default SEPAErrors;
