import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
// import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import ScreenProgress from 'components/ScreenProgress';
import SnackBar from 'components/Snackbar';

import { store } from 'store';

import { Auth0Provider } from 'auth/react-auth0-spa';
import config from 'auth/Config';
import history from 'utils/history';

// import theme from 'assets/jss/theme';
// import styles from 'assets/jss/root';
import AppRoutes from 'routes';

import { I18nextProvider } from 'react-i18next';
import LoadingBackdrop from 'components/LoadingBackdrop';
import i18n from './i18n';

// const useStyles = makeStyles();

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const App = () => {
  // const classes = useStyles();

  return (
    <I18nextProvider i18n={i18n}>
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        audience={config.audience}
        cacheLocation="localstorage"
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <Provider store={store}>
          <CssBaseline />
          <SnackBar />

          {/* <PersistGate loading={null} persistor={persistor}> */}
          {/* <MuiThemeProvider theme={theme}> */}
          <ScreenProgress />
          <LoadingBackdrop />
          <div>
            <AppRoutes />
          </div>
          {/* </MuiThemeProvider> */}
          {/* </PersistGate> */}
        </Provider>
      </Auth0Provider>
    </I18nextProvider>
  );
};

App.propTypes = { classes: PropTypes.object };

export default App;
