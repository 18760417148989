import {
  REMOVE_ERROR,
  FETCHING_WEBHOOK_SUCCESS,
  FETCHING_WEBHOOK_FAILED,
  RESET_WEBHOOK,
} from 'actions/Types';

const initialState = {
  data: [],
  lastEvaludatedKey: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_ERROR:
      return {
        ...state,
        error: null,
      };
    case FETCHING_WEBHOOK_SUCCESS: {
      const ids = new Set(state.data.map((d) => d.webhook_id));
      const merged = [
        ...state.data,
        ...action.payload.Items.filter((d) => !ids.has(d.webhook_id)),
      ];

      return {
        ...state,
        loading: false,
        data: merged,
        lastEvaludatedKey: action.payload.LastEvaluatedKey
          ? action.payload.LastEvaluatedKey
          : null,
      };
    }
    case FETCHING_WEBHOOK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_WEBHOOK:
      return {
        ...state,
        error: null,
        data: [],
        lastEvaludatedKey: null,
      };
    default:
      return state;
  }
};
