import React from 'react';
import GenerateField from 'components/GenerateField';
import SmallTextField from 'components/SmallTextField';
import PropTypes from 'prop-types';
import { TAXREGISTRATION } from 'constants/companies';
import ReadOnlyRadioGroup from 'components/ReadOnlyRadioGroup';

const VATInfo = ({ values }) => {
  const {
    settings: { tax_registration: taxRegistration },
  } = values;
  const reponsiveSm = {
    xs: 12,
    sm: 6,
    md: 3,
  };
  const fiedls = [
    {
      gridReponsives: reponsiveSm,
      component: ReadOnlyRadioGroup,
      componentProps: {
        name: 'tva',
        options: TAXREGISTRATION.tva,
        value: taxRegistration.tva,
      },
    },
    {
      gridReponsives: reponsiveSm,
      component: SmallTextField,
      isHidden: !taxRegistration.tva,
      componentProps: {
        label: 'company.vat_info.intracomvat',
        value: values.intracomvat,
      },
    },
  ];

  return (
    <GenerateField
      label="company.vat_info.vat_info_title"
      name="tva"
      configs={fiedls}
      readOnly
    />
  );
};

VATInfo.propTypes = {
  values: PropTypes.object,
};
export default VATInfo;
