import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Badge,
  MenuItem,
  Menu,
  Box,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Notifications as NotificationsIcon, Delete } from '@material-ui/icons';
import NotificationActions from 'actions/notificationActions';

import { SOCKET_URL } from 'Config';
import useSocket from 'hooks/useSocket';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  notificationLink: {
    textDecoration: 'none',
    color: 'black',
    width: '100%',
  },
  readNotification: {
    color: '#999999',
    width: '100%',
  },
  margin: {
    margin: '0 10px',
  },
  sectionDesktop: {
    display: 'flex',
    // [theme.breakpoints.up('md')]: {
    //   display: 'flex',
    // },
  },
  // sectionMobile: {
  //   display: 'flex',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  loading: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    background: '#ffffffc7',
    zIndex: 1,
  },
}));

const NotificationIcon = () => {
  const classes = useStyles();

  const { socket, isOnline } = useSocket({
    socketUrl: SOCKET_URL,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadNoti, setUnreadNoti] = useState(0);

  const notification = useSelector((state) => state.notification.data || []);
  const notificationLoading = useSelector(
    (state) => state.notification.loading
  );

  const getNotifications = async () => {
    const result = await NotificationActions.getNotification();

    if (result) {
      const unread = result.filter((item) => !item.read);
      setUnreadNoti(unread.length);
    }
  };

  useEffect(() => {
    (async () => {
      await getNotifications();
    })();
  }, [anchorEl]);

  useEffect(() => {
    socket.current.on('notification:get', async () => {
      await getNotifications();
    });
  }, []);

  const handleDeleteNotification = async (notificationId) => {
    const result = await NotificationActions.deleteNotification(notificationId);
    if (result) {
      await getNotifications();
    }
  };

  const handleMenuClose = (notificationId) => {
    setAnchorEl(null);
    if (notificationId) {
      socket.current.emit('notification:read', notificationId);
    }
  };

  const handleNotiMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      {notificationLoading && (
        <div className={classes.loading}>
          <Box
            my={2}
            style={{ width: '100%', height: '20%' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        </div>
      )}
      {notification?.map((item) => (
        <MenuItem key={item._id} disableGutters={true}>
          <Box
            style={{ width: '100%' }}
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              to={item.url}
              onClick={() => handleMenuClose(item._id)}
              className={classes.notificationLink}
            >
              <div
                className={item.read ? classes.readNotification : {}}
                dangerouslySetInnerHTML={{ __html: item.message }}
              />
            </Link>

            <IconButton
              aria-label="delete"
              className={classes.margin}
              size="small"
              onClick={() => handleDeleteNotification(item._id)}
            >
              <Delete fontSize="inherit" />
            </IconButton>
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <div className={classes.sectionDesktop}>
        {isOnline && <p>Online</p>}
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleNotiMenuOpen}
        >
          <Badge
            badgeContent={unreadNoti}
            invisible={!unreadNoti}
            color="secondary"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </div>
      {renderMenu()}
    </>
  );
};

export default NotificationIcon;
