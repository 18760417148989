/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  IconButton,
  Box,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Refresh, Search } from '@material-ui/icons';

import MuiAlert from '@material-ui/lab/Alert';
import AppActions from 'actions/AppActions';
import TreezorActions from 'actions/WebhookActions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  backBtn: {
    marginRight: 20,
    textDecoration: 'none',
  },
  screenTitle: {
    fontSize: 25,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  container: {
    maxHeight: 440,
  },
  searchInput: {
    marginRight: 20,
    width: '28ch',
  },
  input: {
    height: 48,
  },
}));

const TreezorWebhook = () => {
  const classes = useStyles();
  const tableRef = useRef();
  const dispatch = useDispatch();

  const webhooks = useSelector((state) => state.webhook.data);
  const LastEvaluatedKey = useSelector(
    (state) => state.webhook.lastEvaludatedKey
  );
  const error = useSelector((state) => state.webhook.error);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [more, setMore] = useState(true);
  const [searchWebookId, setSearchWebhookId] = useState('');
  const [searchWebhookData, setSearchWebhookData] = useState(null);
  const [searchObject, setSearchObject] = useState('');
  const [searchObjectId, setSearchObjectId] = useState('');

  useEffect(() => {
    dispatch(AppActions.setAppTitle('Treezor Webhooks'));
    (async () => {
      if (webhooks.length === 0) {
        setLoading(true);
        await TreezorActions.fetchWebhooks(10);
        setLoading(false);
      }
      // hide "more" when all data is loaded (when navigate back)
      if (webhooks.length && !LastEvaluatedKey) {
        setMore(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    TreezorActions.removeError();
  };

  const loadMore = async () => {
    if (!searchWebhookData) {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
        if (scrollTop + clientHeight === scrollHeight && !loading) {
          if (LastEvaluatedKey) {
            const last = {
              PK: LastEvaluatedKey.PK,
              SK: LastEvaluatedKey.SK,
            };
            setLoading(true);
            const result = await TreezorActions.fetchWebhooks(10, last);
            setLoading(false);
            if (!result.LastEvaluatedKey) setMore(false);
          }
        }
      }
    }
  };

  const refreshWebhooks = async () => {
    setMore(true);
    setLoading(true);
    await TreezorActions.refreshWebhooks(10);
    setLoading(false);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchWebhookId(value);
    if (value.trim().length === 0) {
      setSearchWebhookData(null);
      setMore(true);
    }
  };

  const handleSearchWebhook = async () => {
    if (searchWebookId.trim().length) {
      // call api
      const result = await TreezorActions.fetchWebhookDetails(searchWebookId);
      setSearchWebhookData([result]);
      setMore(false);
    }
  };

  const handleObjectChange = async (e) => {
    const { value } = e.target;
    setSearchObject(value);
  };

  const handleObjectIdChange = (e) => {
    const { value } = e.target;
    setSearchObjectId(value);
    if (value.trim().length === 0) {
      setSearchWebhookData(null);
      setMore(true);
    }
  };

  const handleSearchObjectId = async () => {
    if (searchObjectId.trim().length && searchObject.trim().length) {
      // call api
      const result = await TreezorActions.searchWebhooks(
        searchObjectId,
        searchObject
      );
      setSearchWebhookData(result.Items);
      setMore(false);
    }
  };

  const columns = [
    { id: 'webhook_id', label: 'Webhook id' },
    {
      id: 'webhook',
      label: 'Webhook name',
    },
    {
      id: 'object',
      label: 'Object',
    },
    {
      id: 'object_id',
      label: 'Object id',
    },
  ];

  const webhookObjects = [
    {
      value: 'acquirermerchantidgroups',
      label: 'ACQUIRERMERCHANTIDGROUPS',
    },
    {
      value: 'balance',
      label: 'BALANCE',
    },
    {
      value: 'bankaccount',
      label: 'BANKACCOUNT',
    },
    {
      value: 'beneficiaries',
      label: 'BENEFICIARIES',
    },
    {
      value: 'business',
      label: 'BUSINESS',
    },
    {
      value: 'card',
      label: 'CARD',
    },
    {
      value: 'carddigitalizations',
      label: 'CARDDIGITALIZATIONS',
    },
    {
      value: 'cardreserve',
      label: 'CARDRESERVE',
    },
    {
      value: 'cardtransaction',
      label: 'CARDTRANSACTION',
    },
    {
      value: 'countryrestrictiongroups',
      label: 'COUNTRYRESTRICTIONGROUPS',
    },
    {
      value: 'document',
      label: 'DOCUMENT',
    },
    {
      value: 'heartbeat',
      label: 'HEARTBEAT',
    },
    {
      value: 'issuerinitiateddigitizationdata',
      label: 'ISSUERINITIATEDDIGITIZATIONDATA',
    },
    {
      value: 'mandate',
      label: 'MANDATE',
    },
    {
      value: 'mccrestrictiongroups',
      label: 'MCCRESTRICTIONGROUPS',
    },
    {
      value: 'merchantidrestrictiongroups',
      label: 'MERCHANTIDRESTRICTIONGROUPS',
    },
    {
      value: 'payin',
      label: 'PAYIN',
    },
    {
      value: 'payinrefund',
      label: 'PAYINREFUND',
    },
    {
      value: 'payout',
      label: 'PAYOUT',
    },
    {
      value: 'payoutrefunds',
      label: 'PAYOUTREFUNDS',
    },
    {
      value: 'recallr',
      label: 'RECALLR',
    },
    {
      value: 'taxresidence',
      label: 'TAXRESIDENCE',
    },
    {
      value: 'transaction',
      label: 'TRANSACTION',
    },
    {
      value: 'transfer',
      label: 'TRANSFER',
    },
    {
      value: 'transferrefund',
      label: 'TRANSFERREFUND',
    },
    {
      value: 'user',
      label: 'USER',
    },
    {
      value: 'virtualibans',
      label: 'VIRTUALIBANS',
    },
    {
      value: 'wallet',
      label: 'WALLET',
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
              >
                {/* Search with webhook */}
                <div>
                  <TextField
                    className={classes.searchInput}
                    value={searchWebookId}
                    onChange={handleSearchChange}
                    placeholder="example: 27403984"
                    helperText="webhook id"
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleSearchWebhook}>
                          <Search color="primary" />
                        </IconButton>
                      ),
                    }}
                  />

                  <IconButton
                    color="primary"
                    aria-label="refresh"
                    onClick={refreshWebhooks}
                  >
                    <Refresh />
                  </IconButton>
                </div>

                {/* Search with object */}
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  p={2}
                >
                  <TextField
                    select
                    className={classes.searchInput}
                    value={searchObject}
                    onChange={handleObjectChange}
                    helperText="object"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {webhookObjects.map((object) => (
                      <MenuItem key={object.value} value={object.value}>
                        {object.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    className={classes.searchInput}
                    value={searchObjectId}
                    onChange={handleObjectIdChange}
                    placeholder="example: 1762662"
                    helperText="object id"
                  />
                  <IconButton
                    color="primary"
                    aria-label="search"
                    onClick={handleSearchObjectId}
                  >
                    <Search />
                  </IconButton>
                </Box>
              </Box>
              <TableContainer
                className={classes.container}
                ref={tableRef}
                onScroll={loadMore}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchWebhookData ? (
                      searchWebhookData.length ? (
                        searchWebhookData.map((row, i) => (
                          <TableRow hover key={i}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.id === 'webhook_id' ? (
                                    <Link to={`/webhooks/${value}`}>
                                      {value}
                                    </Link>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              Not found
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      webhooks.map((row, i) => (
                        <TableRow hover key={i}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === 'webhook_id' ? (
                                  <Link to={`/webhooks/${value}`}>{value}</Link>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))
                    )}
                    {more && (
                      <TableRow>
                        <TableCell colSpan={columns.length}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress size={20} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {error ? error.message : ''}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TreezorWebhook;
