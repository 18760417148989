import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const ReadOnlyRadioGroup = ({ value, name, options }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.radioGroup}>
      {options.map((item, idx) => (
        <div key={idx} className={classes.radio}>
          <Radio
            name={name}
            value={item.value}
            disabled
            checked={value === item.value}
          />
          {t(item.label)}
        </div>
      ))}
    </div>
  );
};

ReadOnlyRadioGroup.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  name: PropTypes.string,
};

export default ReadOnlyRadioGroup;
