/* eslint-disable no-unused-vars */
import { Divider, Grid, Paper } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import CompanyOverview from './CompanyOverview';
import VATInfo from './VATInfo';
import PeriodicVATReturns from './PeriodicVATReturns';
import Activity from './Activity';
import ReturnOnDebit from './ReturnOnDebit';
import BookKeeping from './BookKeeping';
import TypeOfCompany from './TypeOfCompany';
import CGAInfo from './CGAInfo';
import EndOfFiscalYear from './EndOfFiscalYear';
import InvoiceSetting from './InvoiceSetting';
import BankDetails from './BankDetails';
import useStyles from './styles';
import MicroCompany from './MicroCompany';

const CompanyInfo = ({ values }) => {
  const classes = useStyles();
  const {
    settings: { tax_registration: taxRegistration },
    bank_details: bankDetails,
  } = values;
  const { tva } = taxRegistration;
  const isEntrepreneurIndividuel =
    taxRegistration.companyType === 'Entrepreneur individuel';
  return (
    <Paper elevation={3} className={classes.companyInfoContainer}>
      <CompanyOverview values={values} />
      <Divider className={classes.divider} />
      <VATInfo values={values} />
      <Divider className={classes.divider} />
      <Grid container>
        {tva && (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <PeriodicVATReturns taxRegistration={taxRegistration} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Activity values={values} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ReturnOnDebit values={values} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={6}>
          <BookKeeping taxRegistration={taxRegistration} />
        </Grid>
        <Grid item xs={12}>
          <TypeOfCompany values={values} />
        </Grid>
        {isEntrepreneurIndividuel && (
          <Grid item xs={12}>
            <MicroCompany taxRegistration={taxRegistration} />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6}>
          <CGAInfo taxRegistration={taxRegistration} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <EndOfFiscalYear values={values} />
        </Grid>
        <Grid item xs={12}>
          <InvoiceSetting values={values} />
        </Grid>
        <Grid item xs={12}>
          <BankDetails bankDetails={bankDetails} />
        </Grid>
      </Grid>
    </Paper>
  );
};

CompanyInfo.propTypes = {
  values: PropTypes.object,
};
export default CompanyInfo;
