/* Packages */
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { addDays, subDays } from 'date-fns';

/* Constants */
import SEPA_TYPE_OF_REQUESTS from 'views/SEPAManagement/constants/typeOfRequest';

const SEPA_TYPE_STATUS = ['Pending', 'Resolved', 'Rejected'];

const SEPATableToolbar = (props) => {
  const { tableSearch, handleChangeTableSearch } = props;

  const renderValueOfTypeSelect = (selected) => {
    const getTypeLabelByValue = selected.map((i) => {
      return SEPA_TYPE_OF_REQUESTS.find((r) => r.value === i).label;
    });

    return getTypeLabelByValue.join(', ');
  };

  return (
    <Box
      elevation={2}
      style={{
        display: 'flex',
        gap: 50,
        padding: '0 8px',
      }}
    >
      <FormControl variant="standard" fullWidth={true}>
        <InputLabel id="select-status">Status</InputLabel>
        <Select
          multiple
          label="Status"
          labelId="select-status"
          id="select-status-outlined"
          value={tableSearch.status}
          renderValue={(selected) => selected.join(', ')}
          onChange={(e) => handleChangeTableSearch('status', e.target.value)}
        >
          {SEPA_TYPE_STATUS.map((status) => (
            <MenuItem key={status} value={status}>
              <Checkbox checked={tableSearch.status.indexOf(status) > -1} />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" fullWidth={true}>
        <InputLabel id="select-type">Type</InputLabel>
        <Select
          multiple
          label="Type"
          labelId="select-type"
          id="select-type-outlined"
          value={tableSearch.type}
          onChange={(e) => handleChangeTableSearch('type', e.target.value)}
          renderValue={renderValueOfTypeSelect}
        >
          {SEPA_TYPE_OF_REQUESTS.map((request) => (
            <MenuItem key={request.label} value={request.value}>
              <Checkbox
                checked={tableSearch.type.indexOf(request.value) > -1}
              />
              <ListItemText primary={request.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <>
          <KeyboardDatePicker
            maxDate={subDays(tableSearch.endDate, 1) || null}
            style={{ margin: '0' }}
            fullWidth={true}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-start"
            label="Start Date"
            value={tableSearch.startDate}
            onChange={(date) => handleChangeTableSearch('startDate', date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            minDate={addDays(tableSearch.startDate, 1) || null}
            style={{ margin: '0' }}
            fullWidth={true}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-end"
            label="End Date"
            value={tableSearch.endDate}
            onChange={(date) => handleChangeTableSearch('endDate', date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

SEPATableToolbar.propTypes = {
  tableSearch: PropTypes.object,
  handleChangeTableSearch: PropTypes.func,
};

export default SEPATableToolbar;
