/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import MaterialTable from 'components/materialtable/MaterialTable';
import queryStringHelper from 'utils/queryStringHelper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import UserService from 'services/userservice';

import AppActions from 'actions/AppActions';
import { useDispatch } from 'react-redux';

const UserListView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AppActions.setAppTitle('User List'));
  }, []);

  return (
    <>
      <Grid>
        <MaterialTable
          title=""
          columns={[
            {
              title: '_id',
              field: '_id',
              render: (rowData) => {
                return (
                  <Link to={`/userdetails/${rowData._id}`}>{rowData._id}</Link>
                );
              },
            },
            { title: 'First Name', field: 'firstname' },
            { title: 'Last Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Auth0', field: 'user_id' },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              UserService.fetchUsers(queryStringHelper(query))
                .then((res) => {
                  resolve({
                    data: res.data.docs,
                    page: res.data.page - 1,
                    totalCount: res.data.totalDocs,
                  });
                  // console.log(res.data.docs);
                })
                .catch((err) => {
                  reject(err);
                });
            })
          }
          actions={[
            {
              icon: () => <VisibilityIcon />,
              tooltip: 'Details User',
              onClick: (event, rowData) => {
                history.push(`/userdetails/${rowData._id}`);
              },
            },
          ]}
          options={{
            search: true,
            filtering: true,
            actionsColumnIndex: -1,
          }}
        />
      </Grid>
    </>
  );
};

export default UserListView;
