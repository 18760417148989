/* eslint-disable camelcase */
import { genders, pacsTypes } from 'constants/usersContants';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import PropTypes from 'prop-types';
import { EURL } from 'constants/companies';
import SmallRadio from 'components/SmallRadio';

function InfoPacte({ classes, eurl_sasu }) {
  const { values } = useFormikContext();
  const { pacs_spouse_title, pacs_type } = values;
  return (
    <>
      {eurl_sasu === EURL && (
        <h2 className={classes.subTitle}>
          S’agissant des partenaires liés par un PACS, ils peuvent en principe
          souscrire librement des parts sociales d’une EURL en toute
          indépendance, sauf clause contraire prévue dans la convention de PACS.
          <br />
          Aucun formalisme n’est nécessaire lorsque les parts sont financées par
          des apports personnels ne faisant pas partie de la communauté, et quel
          que soit le régime matrimonial.
        </h2>
      )}
      <h2 className={classes.subTitle}> Pacsé(e) sous quel régime ?</h2>
      <SmallRadio
        classes={classes}
        options={pacsTypes}
        value={pacs_type}
        name="pacs_type"
        style={{ marginBottom: '2%' }}
      />
      <h2 className={classes.subTitle}>Informations sur votre conjoint.</h2>
      <SmallRadio
        classes={classes}
        options={genders}
        value={pacs_spouse_title}
        name="pacs_spouse_title"
        style={{ marginBottom: '2%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom du conjoint *"
        name="pacs_spouse_name"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom du conjoint *"
        name="pacs_spouse_firstname"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de naissance du conjoint *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="pacs_spouse_date_naissance"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Lieu de naissance du conjoint *"
        name="pacs_spouse_lieu_naissance"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de l'union *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="pacs_date"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Lieu de l'union *"
        name="pacs_place"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
    </>
  );
}

InfoPacte.propTypes = {
  classes: PropTypes.object,
  eurl_sasu: PropTypes.string,
};

export default InfoPacte;
