import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateCompany } from 'actions/CompanyActions';

export const useDeactivateTreezor = (company) => {
  const dispatch = useDispatch();

  const toggleActivationTreezor = useCallback(async () => {
    await dispatch(
      updateCompany({ ...company, isActivateTreezor: !company.isActivated })
    );
  }, [company, dispatch]);

  return { toggleActivationTreezor };
};

export default useDeactivateTreezor;
