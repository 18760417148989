import React from 'react';
import PropTypes from 'prop-types';
import {
  NumberFormat,
  EuropeCountryCodes,
  OutsideEuropeCountryCodes,
  HighRiskCountryCodes,
} from '@bebusinessfocus/compta-hub-core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { PAYIN, PAYOUT } from 'Config';
import {
  FRENCH,
  EUROPE,
  OUTSIDE_EUROPE,
  HIGH_RISK_COUNTRY,
} from 'constants/countryCodes';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    color: '#3d319d',
    fontWeight: 500,
  },
  table_center_cols: {
    textAlign: 'center',
    width: '100%',
  },
  // th_big: {
  //   width: '70%',
  // },
});

const FinancialDashboard = ({ transactions, treezorTransactionLocation }) => {
  const classes = useStyles();
  const TreezorTransactions = transactions.flat(2) || [];

  // Analysis data for Financial dashboard
  const isPayout = (transaction) => transaction.transactionType === PAYOUT;

  const isPayin = (transaction) => transaction.transactionType === PAYIN;

  const getPayoutTransactions = (transactionList) => {
    if (
      typeof transactionList === 'undefined' ||
      transactionList.length === 0
    ) {
      return [];
    }

    return transactionList.filter(isPayout);
  };

  const getPayinTransactions = (transactionList) => {
    if (
      typeof transactionList === 'undefined' ||
      transactionList.length === 0
    ) {
      return [];
    }

    return transactionList.filter(isPayin);
  };

  const averageAmount = (transactionList) => {
    if (transactionList.length === 0) return 0;

    const average =
      transactionList.reduce((acc, curr) => acc + Number(curr.amount), 0) /
      transactionList.length;
    return `${NumberFormat.n(average)}€`;
  };

  const totalAmount = (transactionList) => {
    const total = transactionList.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0
    );
    return `${NumberFormat.n(total)}€`;
  };

  const maxAmount = (transactionList) => {
    if (transactionList.length === 0) return 0;
    const max = Math.max(
      ...transactionList.map((transaction) => Number(transaction.amount))
    );
    return `${NumberFormat.n(max)}€`;
  };

  const countTransactionsLowerThanNumber = (transactionList, number) =>
    transactionList.filter((transaction) => Number(transaction.amount) < number)
      .length;

  const countTransactionsGreaterThanNumber = (transactionList, number) =>
    transactionList.filter((transaction) => Number(transaction.amount) > number)
      .length;

  const countTransactionsGreaterThanOrEqualNumber = (transactionList, number) =>
    transactionList.filter(
      (transaction) => Number(transaction.amount) >= number
    ).length;

  const payinTransactions = getPayinTransactions(TreezorTransactions);
  const payoutTransactions = getPayoutTransactions(TreezorTransactions);

  // Analysis data for Treezor transactions location
  const payinTransactionsLocation = getPayinTransactions(
    treezorTransactionLocation
  );
  const payoutTransactionsLocation = getPayoutTransactions(
    treezorTransactionLocation
  );

  const filterTransactionsByLocation = (
    treezorTransactionLocations,
    location
  ) => {
    // debugger;  eslint-disable-line no-debugger
    // Filter transactions inside French
    if (location === FRENCH) {
      return treezorTransactionLocations.filter(
        (transaction) => transaction.countryCode === FRENCH
      );
    }

    // Filter transactions inside Europe, outside Europe and in strict country
    return treezorTransactionLocations.filter((transaction) =>
      location.includes(transaction.countryCode)
    );
  };

  const getTransactionsByLocation = (treezorTransactionLocations, location) => {
    // Count transactions inside Europe except in French
    const filteredEUCountryCodes = EuropeCountryCodes.filter(
      (country) => country !== FRENCH
    );

    const params = {
      [FRENCH]: FRENCH,
      [EUROPE]: filteredEUCountryCodes,
      [OUTSIDE_EUROPE]: OutsideEuropeCountryCodes,
      [HIGH_RISK_COUNTRY]: HighRiskCountryCodes,
    };

    const result = filterTransactionsByLocation(
      treezorTransactionLocations,
      params[location]
    );
    return result;
  };

  const payinTransactionsInFrench = getTransactionsByLocation(
    payinTransactionsLocation,
    FRENCH
  );

  const payoutTransactionsInFrench = getTransactionsByLocation(
    payoutTransactionsLocation,
    FRENCH
  );

  const payinTransactionsInEurope = getTransactionsByLocation(
    payinTransactionsLocation,
    EUROPE
  );

  const payoutTransactionsInEurope = getTransactionsByLocation(
    payoutTransactionsLocation,
    EUROPE
  );

  const payinTransactionsOutsideEurope = getTransactionsByLocation(
    payinTransactionsLocation,
    OUTSIDE_EUROPE
  );

  const payoutTransactionsOutsideEurope = getTransactionsByLocation(
    payoutTransactionsLocation,
    OUTSIDE_EUROPE
  );

  const payinTransactionsInRiskCountry = getTransactionsByLocation(
    payinTransactionsLocation,
    HIGH_RISK_COUNTRY
  );

  const payoutTransactionsInRiskCountry = getTransactionsByLocation(
    payoutTransactionsLocation,
    HIGH_RISK_COUNTRY
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" className={classes.title}>
                FLUX FINANCIERS EN SORTIE
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <p>Volume</p>
                  <p>?</p>
                </Grid>
                <Grid item xs={4}>
                  <p>Nombres d&apos;opérations</p>
                  <p>{payinTransactions.length}</p>
                </Grid>
                <Grid item xs={4}>
                  <p>Montant</p>
                  <p>{totalAmount(payinTransactions)}</p>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <p>Valeur moyenne</p>
                  <p>{averageAmount(payinTransactions)}</p>
                </Grid>
                <Grid item xs={4}>
                  <p>Opération MAX</p>
                  <p>{maxAmount(payinTransactions)}</p>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <p>{`Nbre d'opérations <1000,00€`}</p>
                  <p>
                    {countTransactionsLowerThanNumber(payinTransactions, 1000)}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p>{`Nbre d'opérations >=1000,00€`}</p>
                  <p>
                    {countTransactionsGreaterThanOrEqualNumber(
                      payinTransactions,
                      1000
                    )}
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <p>{`Nbre d'opérations >=5000,00€`}</p>
                  <p>
                    {countTransactionsGreaterThanOrEqualNumber(
                      payinTransactions,
                      5000
                    )}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p>{`Nbre d'opérations >= 9900,00€`}</p>
                  <p>
                    {countTransactionsGreaterThanOrEqualNumber(
                      payinTransactions,
                      9000
                    )}
                  </p>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" className={classes.title}>
                FLUX FINANCIERS EN ENTRÉE
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <p>Volume</p>
                  <p>?</p>
                </Grid>
                <Grid item xs={4}>
                  <p>Nombres d&apos;opérations</p>
                  <p>{payoutTransactions.length}</p>
                </Grid>
                <Grid item xs={4}>
                  <p>Montant</p>
                  <p>{totalAmount(payoutTransactions)}</p>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <p>Valeur moyenne</p>
                  <p>{averageAmount(payoutTransactions)}</p>
                </Grid>
                <Grid item xs={4}>
                  <p>Opération MAX</p>
                  <p>{maxAmount(payoutTransactions)}</p>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <p>{`Nbre d'opérations < 1000,00€`}</p>
                  <p>
                    {countTransactionsLowerThanNumber(payoutTransactions, 1000)}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p>{`Nbre d'opérations >= 1000,00€`}</p>
                  <p>
                    {' '}
                    {countTransactionsGreaterThanOrEqualNumber(
                      payoutTransactions,
                      1000
                    )}
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <p>{`Nbre d'opérations > 5000,00€`}</p>
                  <p>
                    {' '}
                    {countTransactionsGreaterThanNumber(
                      payoutTransactions,
                      5000
                    )}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p>{`Nbre d'opérations > 9900,00€`}</p>
                  <p>
                    {' '}
                    {countTransactionsGreaterThanNumber(
                      payoutTransactions,
                      9000
                    )}
                  </p>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <br />

      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <fieldset>
              <legend style={{ fontWeight: 'bold' }}>
                Opération en France
              </legend>
              <table className={classes.table_center_cols}>
                <tr>
                  <th></th>
                  <th>NB OPERATIONS</th>
                  <th>MONTANT</th>
                </tr>
                <tr>
                  <th>Entrée</th>
                  <td>{payinTransactionsInFrench.length}</td>
                  <td>{totalAmount(payinTransactionsInFrench)}</td>
                </tr>
                <tr>
                  <th>Sortie</th>
                  <td>{payoutTransactionsInFrench.length}</td>
                  <td>{totalAmount(payoutTransactionsInFrench)}</td>
                </tr>
              </table>
            </fieldset>
          </Grid>

          <Grid item xs={3}>
            <fieldset>
              <legend style={{ fontWeight: 'bold' }}>
                Opération en Europe
              </legend>
              <table className={classes.table_center_cols}>
                <tr>
                  <th></th>
                  <th>NB OPERATIONS</th>
                  <th>MONTANT</th>
                </tr>
                <tr>
                  <th>Entrée</th>
                  <td>{payinTransactionsInEurope.length}</td>
                  <td>{totalAmount(payinTransactionsInEurope)}</td>
                </tr>
                <tr>
                  <th>Sortie</th>
                  <td>{payoutTransactionsInEurope.length}</td>
                  <td>{totalAmount(payoutTransactionsInEurope)}</td>
                </tr>
              </table>
            </fieldset>
          </Grid>

          <Grid item xs={3}>
            <fieldset>
              <legend style={{ fontWeight: 'bold' }}>
                Opération hors Europe
              </legend>
              <table className={classes.table_center_cols}>
                <tr>
                  <th></th>
                  <th>NB OPERATIONS</th>
                  <th>MONTANT</th>
                </tr>
                <tr>
                  <th>Entrée</th>
                  <td>{payinTransactionsOutsideEurope.length}</td>
                  <td>{totalAmount(payinTransactionsOutsideEurope)}</td>
                </tr>
                <tr>
                  <th>Sortie</th>
                  <td>{payoutTransactionsOutsideEurope.length}</td>
                  <td>{totalAmount(payoutTransactionsOutsideEurope)}</td>
                </tr>
              </table>
            </fieldset>
          </Grid>

          <Grid item xs={3}>
            <fieldset>
              <legend style={{ fontWeight: 'bold' }}>
                Opération dans pay à risques
              </legend>
              <table className={classes.table_center_cols}>
                <tr>
                  <th></th>
                  <th>NB OPERATIONS</th>
                  <th>MONTANT</th>
                </tr>
                <tr>
                  <th>Entrée</th>
                  <td>{payinTransactionsInRiskCountry.length}</td>
                  <td>{totalAmount(payinTransactionsInRiskCountry)}</td>
                </tr>
                <tr>
                  <th>Sortie</th>
                  <td>{payoutTransactionsInRiskCountry.length}</td>
                  <td>{totalAmount(payoutTransactionsInRiskCountry)}</td>
                </tr>
              </table>
            </fieldset>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

FinancialDashboard.propTypes = {
  transactions: PropTypes.object,
  treezorTransactionLocation: PropTypes.object,
};

export default FinancialDashboard;
