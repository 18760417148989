import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  companyInfoContainer: {
    padding: '20px',
    minHeight: '500px',
  },
  divider: {
    marginBottom: '20px',
    backgroundColor: 'transparent',
  },
  actionsWapper: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '20px',
  },
  saveBtn: {
    marginRight: '20px',
  },
  downgradeSubBtn: {
    color: 'white',
    borderColor: '#ef5350',
    backgroundColor: '#ef5350',
  },
  loadingBox: {
    backgroundColor: 'white',
    width: '100%',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
