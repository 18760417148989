/* Packages */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';

/* Constants */
import SEPA_TYPE_OF_REQUESTS from 'views/SEPAManagement/constants/typeOfRequest';

const SEPARequestSelect = (props) => {
  const {
    field,
    form,
    label,
    placeholder,
    subLabel,
    selectOptionType,
    readOnly,
  } = props;
  const { name } = field;
  const { errors, touched } = form;

  const filterBySelectOptionType = SEPA_TYPE_OF_REQUESTS.filter(
    (r) => r.type === selectOptionType || r.type === 'Other'
  );

  return (
    <>
      <label
        id={name}
        style={{
          marginBottom: '5px',
          display: 'block',
          fontWeight: 'bold',
          fontSize: '14px',
        }}
      >
        {label}
      </label>
      <p style={{ color: 'gray', marginBottom: '5px', margin: '5px 0px 8px' }}>
        {subLabel}
      </p>
      <FormControl
        error={errors && touched && errors[name] && touched[name]}
        fullWidth={true}
        variant="outlined"
      >
        <Select
          labelId={name}
          id={name}
          placeholder={placeholder}
          inputProps={{ readOnly }}
          {...field}
        >
          {filterBySelectOptionType.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors && errors[name]}</FormHelperText>
      </FormControl>
    </>
  );
};

SEPARequestSelect.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  placeholder: PropTypes.string,
  selectOptionType: PropTypes.string,
  readOnly: PropTypes.bool,
};

SEPARequestSelect.defaultProps = {
  field: {},
  form: {},
  type: 'text',
  label: '',
  subLabel: '',
  placeholder: '',
  readOnly: false,
};

export default SEPARequestSelect;
