/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  amountDebit: {
    fontSize: 19,
    color: '#D22F38',
    fontWeight: 'bold',
    '&::before': {
      content: '"- "',
    },
  },
  amountCredit: {
    fontSize: 19,
    color: '#5CD975',
    fontWeight: 'bold',
    '&::before': {
      content: '"+ "',
    },
  },

  tableDateWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },

  tableDate: {
    color: 'white',
    fontSize: '22px',
    padding: '4px 8px',
    backgroundColor: '#3E319D',
    borderRadius: '14px',
  },

  tableMonthYear: {
    fontSize: '14px',
  },
}));

export default useStyles;
