/* eslint-disable react/display-name */
/* Packages */
import MaterialTable from 'components/materialtable/MaterialTable';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';

/* Icons */
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

/* Components */
import SEPATableAction from 'views/SEPAManagement/components/SEPATableAction';
import SEPATableToolbar from 'views/SEPAManagement/components/SEPATableToolbar';

/* Actions */
import {
  fetchSEPAAdminRequestAction,
  updateSEPAAdminRequestTypeAction,
} from 'actions/SEPAAdminActions';
import AppActions from 'actions/AppActions';

/* Constants */
import SEPA_TYPE_OF_REQUESTS from 'views/SEPAManagement/constants/typeOfRequest';

const SEPAFormList = () => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const history = useHistory();

  const [isVisibility, setIsVisibility] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableSearch, setTableSearch] = useState({
    status: [],
    type: [],
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    dispatch(AppActions.setAppTitle('SEPA Form Management'));
  }, []);

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [tableSearch]);

  const handleChangeTableSearch = (field, value) => {
    setTableSearch({
      ...tableSearch,
      [field]: value,
    });
  };

  const resetFilter = () => {
    setTableSearch({
      status: [],
      type: [],
      startDate: null,
      endDate: null,
    });
  };

  const onClickAction = async (requestId, actionType) => {
    if (actionType === 'view')
      return history.push(`/sepa/management/request/${requestId}`);

    setIsTableLoading(true);

    const updateStatusResponse = await updateSEPAAdminRequestTypeAction({
      requestOId: requestId,
      requestStatus: actionType,
    });

    if (updateStatusResponse.status === 200) tableRef.current.onQueryChange();

    setIsTableLoading(false);

    return true;
  };

  const columns = [
    {
      title: 'Created At',
      field: 'created_at',
      sorting: false,
      render: (rowData) => (
        <span>{format(new Date(rowData.created_at), 'dd/MM/yyyy HH:mm')}</span>
      ),
    },
    {
      title: 'Request ID',
      field: '_id',
      sorting: false,
    },
    {
      title: 'User ID',
      field: 'userId',
      sorting: false,
    },
    {
      title: 'Type',
      field: 'typeOfRequest',
      sorting: false,
      render: (rowData) => (
        <span>
          {
            SEPA_TYPE_OF_REQUESTS.find((r) => r.value === rowData.typeOfRequest)
              .label
          }
        </span>
      ),
      filtering: false,
    },
    {
      title: 'Status',
      field: 'requestStatus',
      sorting: false,
    },
    {
      title: 'Subject',
      field: 'subject',
      sorting: false,
    },
    {
      render: (rowData) => (
        <SEPATableAction rowData={rowData} onClickAction={onClickAction} />
      ),
    },
  ];

  const formatTime = (time) => format(new Date(time), 'yyyy-MM-dd');

  const getSEPARequestListInDb = async (queryTable) => {
    const { startDate, endDate, type, status } = tableSearch;

    const objectQueryString = {
      ...queryTable,
      page: queryTable.page + 1,
      filters: {
        ...(startDate && { start: formatTime(startDate) }),
        ...(endDate && { end: formatTime(endDate) }),
        type,
        status,
      },
    };

    const parseObjectToQueryString = qs.stringify(objectQueryString);

    const response = await fetchSEPAAdminRequestAction(
      parseObjectToQueryString
    );

    return {
      data: response.data.docs,
      totalCount: response.data.totalDocs,
      page: response.data.page - 1,
    };
  };

  const onClickResetFilterButton = () => resetFilter();

  const onChangeVisibility = () => setIsVisibility(!isVisibility);

  const onClickHideResolved = () => {
    onChangeVisibility();

    handleChangeTableSearch(
      'status',
      isVisibility ? [] : ['Pending', 'Rejected']
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '20px 15px 0px',
        borderRadius: '10px',
        boxShadow: '0px 5px 13px 0px #00000033',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <SearchIcon style={{ fontSize: '28px' }} />
        <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Filter</span>
        <div style={{ flex: 'auto' }}></div>
        <Button
          variant="contained"
          startIcon={isVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
          style={{
            borderRadius: 20,
            backgroundColor: 'white',
            boxShadow: '0px 5px 13px 0px #00000033',
            marginRight: '10px',
          }}
          onClick={onClickHideResolved}
        >
          {isVisibility ? 'Show' : 'Hide'} resolved
        </Button>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          style={{
            borderRadius: 20,
            backgroundColor: 'white',
            boxShadow: '0px 5px 13px 0px #00000033',
          }}
          onClick={onClickResetFilterButton}
        >
          Clear Filter
        </Button>
      </div>
      <SEPATableToolbar
        handleChangeTableSearch={handleChangeTableSearch}
        tableSearch={tableSearch}
      />
      <MaterialTable
        style={{ boxShadow: 'none' }}
        isLoading={isTableLoading}
        tableRef={tableRef}
        columns={columns}
        options={{
          filtering: false,
          search: true,
          showTitle: false,
          pageSizeOptions: [],
        }}
        data={getSEPARequestListInDb}
        localization={{
          toolbar: { searchPlaceholder: 'Search by UserID, ID, Subject' },
        }}
      />
    </div>
  );
};

export default SEPAFormList;

SEPAFormList.propTypes = {
  company: PropTypes.object,
  match: PropTypes.object,
};
