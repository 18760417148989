import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  companyInfoContainer: {
    padding: '20px',
  },
  divider: {
    marginBottom: '20px',
    backgroundColor: 'transparent',
  },
}));

export default useStyles;
