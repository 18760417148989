/* eslint-disable react/prop-types */
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import React from 'react';

function CompanyList({ companyList, handleSelectCompany }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="custom pagination table">
        <TableBody>
          {companyList.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell component="th" scope="row">
                {`Company Name: ${row.denomination}`}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {`Address: ${row.adresse}`}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {`SIREN: ${row.siren}`}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSelectCompany(row)}
                >
                  Sélectionner
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CompanyList;
