import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SmallTextField = ({ disabled, label, value, defaultValue }) => {
  const { t } = useTranslation();
  return (
    <TextField
      disabled={disabled}
      fullWidth
      size="small"
      variant="outlined"
      label={t(label)}
      value={value || ' '}
      defaultValue={defaultValue}
    />
  );
};

SmallTextField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
};
export default SmallTextField;
