/* Packages */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';

/* Icons */
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplayIcon from '@material-ui/icons/Replay';

const SEPATableAction = ({ onClickAction, rowData }) => {
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Tooltip title="Pending">
        <IconButton
          size="small"
          onClick={() => onClickAction(rowData._id, 'Pending')}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Resolved">
        <IconButton
          size="small"
          onClick={() => onClickAction(rowData._id, 'Resolved')}
        >
          <CheckIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Rejected">
        <IconButton
          size="small"
          onClick={() => onClickAction(rowData._id, 'Rejected')}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="View">
        <IconButton
          size="small"
          onClick={() => onClickAction(rowData._id, 'view')}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

SEPATableAction.propTypes = {
  onClickAction: PropTypes.func,
  rowData: PropTypes.object,
};

export default SEPATableAction;
