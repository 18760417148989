import axios from 'axios';

const signServerUri = process.env.REACT_APP_CREA || 'http://localhost:8080';

const login = () => {
  return axios.get(`${signServerUri}/login`);
};

const getUserInfo = () => {
  const dsToken = localStorage.getItem('dsToken');

  return axios.get(`${signServerUri}/user-info`, {
    headers: {
      Authorization: `Bearer ${dsToken}`,
    },
  });
};

const sendEnvelope = async (
  documents,
  signers,
  accountId,
  basePath,
  companyName
) => {
  const dsToken = localStorage.getItem('dsToken');

  // Request that the envelope be sent by setting |status| to "sent".
  // To request that the envelope be created as a draft, set to "created"
  const envelopeRequest = {
    emailSubject: `Documents pour ${companyName}`, // TODO: ici le nom de la societe
    status: 'sent',
    signers,
    documents,
  };

  return axios.post(
    `${signServerUri}/envelope`,
    { ...envelopeRequest },
    {
      headers: {
        Authorization: `Bearer ${dsToken}`,
        accountId,
        basePath,
      },
    }
  );
};

const setInitial = async (accountId, basePath, envelopeId) => {
  const dsToken = localStorage.getItem('dsToken');

  const body = {
    envelopeId,
  };

  return axios.post(
    `${signServerUri}/initial`,
    { ...body },
    {
      headers: {
        Authorization: `Bearer ${dsToken}`,
        accountId,
        basePath,
      },
    }
  );
};

export default { sendEnvelope, setInitial, login, getUserInfo };
