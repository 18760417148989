import { APP_LOADING } from 'actions/Types';

export default (state = false, action) => {
  switch (action.type) {
    case APP_LOADING:
      return action.payload.isLoading;
    default:
      return state;
  }
};
