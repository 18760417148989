import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

const ScreenProgress = () => {
  const progress = useSelector((state) => state.app.progress);
  if (progress)
    return (
      <div
        style={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%', background: '#ded5d5ba' }}
        >
          <CircularProgress />
        </Box>
      </div>
    );
  return <></>;
};

export default ScreenProgress;
