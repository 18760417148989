/* Package */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const SEPARequestInput = (props) => {
  const {
    field,
    form,
    placeholder,
    label,
    type,
    multiline,
    rows,
    subLabel,
    readOnly,
  } = props;
  const { name } = field;
  const { errors, touched } = form;

  return (
    <>
      <label
        id={name}
        style={{
          marginBottom: '5px',
          display: 'block',
          fontWeight: 'bold',
          fontSize: '14px',
        }}
      >
        {label}
      </label>
      <p style={{ color: 'gray', marginBottom: '5px', margin: '5px 0px 8px' }}>
        {subLabel}
      </p>
      <TextField
        error={errors && touched && errors[name] && touched[name]}
        id={name}
        placeholder={placeholder}
        helperText={errors && errors[name]}
        variant="outlined"
        type={type}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ readOnly }}
        fullWidth={true}
        multiline={multiline}
        rows={rows}
        {...field}
      />
    </>
  );
};

SEPARequestInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  subLabel: PropTypes.string,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
};

SEPARequestInput.defaultProps = {
  field: {},
  form: {},
  label: '',
  subLabel: '',
  placeholder: '',
  multiline: false,
  readOnly: false,
  rows: 1,
};

export default SEPARequestInput;
