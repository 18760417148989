import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { useAuth0 } from 'auth/react-auth0-spa';

// import styles from 'assets/jss/root';
import logo from 'assets/images/octopus.svg';

// const useStyles = makeStyles(styles);

const Login = () => {
  // const classes = useStyles();

  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (loading) {
    return <CircularProgress />;
  }
  if (isAuthenticated) {
    return <Redirect to="/companylist" />;
  }

  return (
    <Grid
      style={{ marginTop: '5%' }}
      container
      spacing={3}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid itemxs={12}>
        <img src={logo} alt="BBF" />
      </Grid>
      <Grid item xs={12} align="center">
        <Typography variant="h4">Welcome to Be Business Focus</Typography>
        <Typography variant="h5">Backoffice</Typography>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={() => loginWithRedirect({})}
      >
        Log In
      </Button>
    </Grid>
  );
};

Login.propTypes = {
  login: PropTypes.func,
  onClose: PropTypes.func,
  accessDenied: PropTypes.bool,
};

Login.defaultProps = {
  accessDenied: false,
};

export default Login;
