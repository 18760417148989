/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import UserService from 'services/userservice';
import TreezorService from 'services/treezorservice';
import CompanyService from 'services/CompanyService';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListSubheader,
  ListItemText,
  ListItem,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import AppActions from 'actions/AppActions';

import {
  LOST_CARD,
  STOLEN_CARD,
  LOCK_CARD,
  UNLOCK_CARD,
  BLOCK_PIN,
  UNBLOCK_PIN_ACTION,
} from '../../Config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const UserDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  // const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [cards, setCards] = useState(null);
  const [msg, setMessage] = useState(null);
  const [tabCompanyActive, setTabCompanyActive] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabCompanyActive(newValue);
  };
  const [open, setOpen] = useState(false);
  const [cardDialogVisible, setCardDialogVisible] = useState(false);
  const [cardDialogTitle, setCardDialogTitle] = useState('');
  const [cardDialogText, setCardDialogText] = useState('');
  const [cardAction, setCardAction] = useState(null);
  const [selectedCard, setSeletedCard] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCardDialog = () => {
    setCardDialogVisible(false);
    setCardDialogTitle('');
    setCardAction(null);
  };

  const handleOpenCardDialog = (title, text, action, card, companyId) => {
    setCardDialogTitle(title);
    setCardDialogText(text);
    setCardAction(action);
    setSeletedCard(card);
    setSelectedCompanyId(companyId);
    setCardDialogVisible(true);
  };

  const fetchUser = async (_id) => {
    setLoading(true);
    // setError(false);
    try {
      const result = await UserService.fetchUser(_id);
      setUser(result.data);
      // console.log(result.data);
    } catch (e) {
      // console.log(e);
      // setError(true);
      setMessage(e.message);
      setOpen(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(AppActions.setAppTitle('User Details'));
    (async () => {
      await fetchUser(props.match.params._id);
      setLoading(false);
    })();
  }, [props.match.params._id]);

  const fetchUserCompanies = async (id) => {
    // setLoading(true);
    // setError(false);
    const userCompanies = [];
    try {
      const resultCompanies = await UserService.fetchUserCompany(id);
      // console.log(resultCompanies.data);

      for (let i = 0; i < resultCompanies.data.length; i++) {
        const res = await CompanyService.fetchCompany(
          resultCompanies.data[i].company_id
        );
        userCompanies.push(res.data);
        // console.log(res.data);
      }
      setCompanies(userCompanies);
    } catch (e) {
      // console.log(e);
      // setError(true);
      setMessage(e.message);
      setOpen(true);
    }
    // setLoading(false);
    return userCompanies;
  };

  const fetchCards = async (companyId) => {
    try {
      const resultCards = await TreezorService.fetchCards(companyId);
      setCards(resultCards.data);
      return resultCards.data;
      // console.log(resultCards.data);
    } catch (e) {
      // console.error(e);
      // console.log(e.message);
      // setError(true);
      setMessage(e.message);
      setOpen(true);
    }
    setLoading(false);
    return null;
  };

  const updateLockStatus = async (companyId, cardOId, status) => {
    const data = {
      lockStatus: status,
    };

    if (status === LOCK_CARD || status === UNLOCK_CARD) {
      TreezorService.lockUnlockCard(companyId, cardOId, data)
        .then((/* response */) => {
          // console.log(response.data);
          fetchCards(companyId);
        })
        .catch((e) => {
          // console.log(e);
          setMessage(e.message);
        });
    } else if (status === LOST_CARD || status === STOLEN_CARD) {
      TreezorService.lostStolenCard(companyId, cardOId, data)
        .then((/* response */) => {
          // console.log(response.data);
          fetchCards(companyId);
        })
        .catch((e) => {
          // console.log(e);
          setMessage(e.message);
        });
    }
  };

  const updateUserStatus = (status) => {
    const data = {
      // _id: user._id,
      // name: user.name,
      // firstname: user.firstname,
      // user_id: user.user_id,
      suspended: status,
    };

    UserService.updateUser(user._id, data)
      .then((/* response */) => {
        // TODO: check this function
        setUser({ ...user, suspended: status });
        // console.log(response.data);
      })
      .catch((/* e */) => {
        // console.log(e);
      });
  };

  const unblockPIN = async (companyId, cardOId) => {
    TreezorService.unblockPIN(companyId, cardOId)
      .then(() => {
        fetchCards(companyId);
      })
      .catch((e) => {
        // console.log(e);
        setMessage(e.message);
      });
  };

  const handleUserStatus = async (status) => {
    // status === false mean activate the user
    if (!status) {
      // console.log('Activate user');
      return updateUserStatus(status);
    }

    // status === true mean deactivate the user
    try {
      const userCompanies = await fetchUserCompanies(user._id);

      // Check if userCompanies is null or userCompanies is an empty array
      if (userCompanies && userCompanies.length === 0) {
        return updateUserStatus(status);
      }

      const treezorUserCompanies = userCompanies.filter((company) =>
        Object.prototype.hasOwnProperty.call(company, 'treezorUser')
      );

      // Check if list of companies which user associated with that contain treezorUser
      if (treezorUserCompanies && treezorUserCompanies.length === 0) {
        return updateUserStatus(status);
      }

      const cardCompanyPromises = treezorUserCompanies.map((company) => {
        return fetchCards(company._id);
      });
      const resultCards = await Promise.all(cardCompanyPromises);

      // Check if the list of companies containing treezorUser contains card
      const isContainNull = resultCards.some((card) => card === null);

      if ((resultCards && resultCards.length === 0) || isContainNull) {
        return updateUserStatus(status);
      }

      // Lock all available cards
      const cardPromises = resultCards.map((cardList) => {
        return cardList.map(async (card) => {
          await updateLockStatus(card.companyId, card._id, LOCK_CARD);
        });
      });

      await Promise.all(cardPromises);
      return updateUserStatus(status);
    } catch (error) {
      setMessage(error.message);
    }
    return null;
  };

  const renderCompany = (company) => {
    return <Tab key={company._id} label={company.name} />;
  };

  const handleConfirm = () => {
    switch (cardAction) {
      case UNLOCK_CARD:
      case LOCK_CARD:
      case LOST_CARD:
      case STOLEN_CARD:
        handleCloseCardDialog();
        updateLockStatus(selectedCompanyId, selectedCard._id, cardAction);
        break;
      case UNBLOCK_PIN_ACTION:
        handleCloseCardDialog();
        unblockPIN(selectedCompanyId, selectedCard._id);
        break;
      default:
        handleCloseCardDialog();
    }
  };

  const renderDialog = () => {
    return (
      <Dialog open={cardDialogVisible} onClose={handleCloseCardDialog}>
        <DialogTitle>{cardDialogTitle}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{cardDialogText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCardDialog}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderCompanyPanel = (company, index) => {
    return (
      <TabPanel key={company.id} index={index} value={tabCompanyActive}>
        <div>
          ObjectId:{' '}
          <Link to={`/companydetails/${company._id}`}>{company._id}</Link>{' '}
        </div>
        <div>Name: {company.name}</div>
        <div>Business Name: {company.business_name}</div>
        <div>
          Siret: {company.siren} {company.nic}
        </div>
        <div>{company.address}</div>
        <div>
          {company.zipcode} {company.city}
        </div>

        <div>alternativeId: {company.alternativeId}</div>

        <div>
          <Button variant="outlined" onClick={() => fetchCards(company._id)}>
            Cards
          </Button>
          {cards && (
            <>
              {cards.length > 0 && renderDialog()}
              <List subheader={<li />}>
                {cards.map((card, idx) => (
                  <li key={`section-${idx}`} className={classes.listSection}>
                    <ul className={classes.ul}>
                      <ListSubheader>{`Card ${card.cardId}`}</ListSubheader>
                      <ListItem>
                        <ListItemText
                          primary={`EventName : ${card.eventName}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`MaskedPan : ${card.maskedPan}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`EmbossedName : ${card.embossedName}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`ExpiryDate : ${card.expiryDate}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={`STATUS: ${card.statusCode}`} />
                      </ListItem>
                      {card.statusCode !== 'LOST' &&
                        card.statusCode !== 'STOLEN' && (
                          <div>
                            {card.statusCode === 'LOCK' ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleOpenCardDialog(
                                    'Unlock Card',
                                    'Are you sure to unlock the card',
                                    UNLOCK_CARD,
                                    card,
                                    company._id
                                  )
                                }
                              >
                                UNLOCK CARD
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleOpenCardDialog(
                                    'Lock Card',
                                    'Are you sure to lock the card',
                                    LOCK_CARD,
                                    card,
                                    company._id
                                  )
                                }
                              >
                                LOCK CARD
                              </Button>
                            )}
                          </div>
                        )}

                      {(card.statusCode === 'UNLOCK' ||
                        card.statusCode === 'LOCK') && (
                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handleOpenCardDialog(
                                'Lost Card',
                                'Card Holder declare his/her card Lost. Warning : Confirmation is irreversible!',
                                LOST_CARD,
                                card,
                                company._id
                              )
                            }
                          >
                            Lost
                          </Button>

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handleOpenCardDialog(
                                'Stolen Card',
                                'Card Holder declare his/her card Stolen. Warning : Confirmation is irreversible!',
                                STOLEN_CARD,
                                card,
                                company._id
                              )
                            }
                          >
                            Stolen
                          </Button>
                        </div>
                      )}

                      {card.pinTryExceeds === BLOCK_PIN && (
                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handleOpenCardDialog(
                                'Unblock PIN',
                                'Are you sure to unblock PIN',
                                UNBLOCK_PIN_ACTION,
                                card,
                                company._id
                              )
                            }
                          >
                            Unblock PIN
                          </Button>
                        </div>
                      )}
                      {card.status === 400 && (
                        <div>Une Erreur est survenue</div>
                      )}
                    </ul>
                  </li>
                ))}
              </List>
            </>
          )}
        </div>
      </TabPanel>
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      {msg && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={() => setOpen(false)} severity="error">
            {msg}
          </Alert>
        </Snackbar>
      )}
      <div>
        <Link to="/userlist">Back</Link>
      </div>
      {user ? (
        <div key={user._id}>
          <div>{user.name}</div>
          <div>{user.firstname}</div>
          <div>{user.email}</div>
          <div>{user.user_id}</div>
          <div>
            {user.suspended ? 'Is Not Active' : 'Is Active'}&nbsp;
            {user.suspended ? (
              <Button
                variant="contained"
                onClick={() => handleUserStatus(false)}
              >
                Activate
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleUserStatus(true)}
              >
                Deactivate
              </Button>
            )}
          </div>
          <hr></hr>
          <div>
            <Button
              variant="contained"
              onClick={() => fetchUserCompanies(user._id)}
            >
              Companies
            </Button>
            {companies && (
              <>
                <Tabs
                  value={tabCompanyActive}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleTabChange}
                  aria-label="company tabs"
                >
                  {companies.map((company) => {
                    return renderCompany(company);
                  })}
                </Tabs>
                {companies.map((company, index) => {
                  return renderCompanyPanel(company, index);
                })}
              </>
            )}
          </div>
        </div>
      ) : (
        <div>Please wait</div>
      )}
    </>
  );
};

export default UserDetails;
