/* eslint-disable camelcase */
import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { juridicForms } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { fetchCompanyDataFromGreffe, fetchRCS } from 'helpers/rcsHelper';
import Adresse from 'components/Adresse';
import SmallRadio from 'components/SmallRadio';
import CompanyList from 'components/CompanyList';

function InfoMoralPerson({ classes, values }) {
  const { setFieldValue } = useFormikContext();
  const { forme_juridique_personne_morale } = values;
  const [companyList, setCompanyList] = useState(null);

  const handleFetchCompanyInfo = async () => {
    const {
      raison_sociale_actionnaire_personne_morale_sasu: companyName,
    } = values;
    if (companyName) {
      const data = await fetchCompanyDataFromGreffe(companyName);
      if (data && data.length > 0) {
        setCompanyList(data);
      } else {
        setCompanyList(null);
      }
    } else {
      setCompanyList(null);
    }
  };

  const handleSelectCompany = async (company) => {
    const { code_postal, ville } = company;
    const resRCS = await fetchRCS(code_postal, ville);

    setFieldValue(
      'raison_sociale_actionnaire_personne_morale_sasu',
      company.denomination
    );
    setFieldValue(
      'code_postal_actionnaire_personne_morale_sasu',
      company.code_postal
    );
    setFieldValue('adresse_actionnaire_personne_morale_sasu', company.adresse);
    setFieldValue('ville_actionnaire_personne_morale_sasu', company.ville);

    if (resRCS) {
      setFieldValue('rcs_actionnaire_personne_morale_sasu', company.siren);
      setFieldValue('ville_rcs_actionnaire_personne_morale_sasu', resRCS);
    }

    setCompanyList(null);
  };

  return (
    <>
      <h2 className={classes.subTitle}>Informations sur la personne morale.</h2>
      <SmallRadio
        classes={classes}
        title="Forme juridique"
        options={juridicForms}
        value={forme_juridique_personne_morale}
        name="forme_juridique_personne_morale"
        style={{ marginBottom: '2%' }}
      />
      <h3 className={classes.helpUserInfo} style={{ marginBottom: '1%' }}>
        Saisissez les 9 chiffres de votre numero siren ou la raison sociale puis
        cliquer sur la loupe pour sélectionner la société.
      </h3>
      <Field
        component={TextField}
        type="string"
        label="Numéro siren (9 chiffres) / raison sociale *"
        name="raison_sociale_actionnaire_personne_morale_sasu"
        className={classes.nameTextField}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleFetchCompanyInfo}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        size="small"
      />
      {companyList && (
        <CompanyList
          companyList={companyList}
          handleSelectCompany={handleSelectCompany}
        />
      )}
      <Field
        component={TextField}
        // type="number"
        inputMode="numeric"
        label="Capital *"
        name="capital_personne_morale"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse de la personne morale *"
        address="adresse_actionnaire_personne_morale_sasu"
        city="ville_actionnaire_personne_morale_sasu"
        zipCode="code_postal_actionnaire_personne_morale_sasu"
        disabled={true}
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
      />
      <h2 className={classes.subTitle}>Représentée par</h2>
    </>
  );
}

InfoMoralPerson.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
};

export default InfoMoralPerson;
