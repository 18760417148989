import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const GenerateField = ({ configs, label, labelConfig, readOnly, name }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      {label && (
        <Grid item xs={12}>
          <Typography
            style={{ textTransform: 'uppercase' }}
            component={'h6'}
            variant="h6"
            {...labelConfig}
          >
            {t(label)}
          </Typography>
        </Grid>
      )}
      {configs.map((config, configIdx) => (
        <Fragment key={`${name} + ${configIdx}`}>
          {!config.isHidden && (
            <Grid
              item
              xs={config?.gridReponsives?.xs || 12}
              sm={config?.gridReponsives?.sm}
              md={config?.gridReponsives?.md}
              lg={config?.gridReponsives?.lg}
            >
              <config.component
                label={t(config?.componentProps?.label)}
                {...config.componentProps}
                disabled={readOnly || config.componentProps.disabled}
              />
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

GenerateField.propTypes = {
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  labelConfig: PropTypes.object,
  isHidden: PropTypes.bool,
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      gridReponsives: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
      }),
      component: PropTypes.elementType.isRequired,
      componentProps: PropTypes.object.isRequired,
    })
  ),
};
export default GenerateField;
