export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

// User
export const FETCHING_LOGGED_USER = 'FETCHING_LOGGED_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
// access failure
export const ACCESS_FAILED = 'ACCESS_FAILED';

// Companies
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILED = 'FETCH_COMPANIES_FAILED';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

export const GENERATE_ALL_DOCS_PENDING = 'GENERATE_ALL_DOCS_PENDING';
export const GENERATE_ALL_DOCS_SUCCESS = 'GENERATE_ALL_DOCS_SUCCESS';

// VATRates
export const FETCH_VATRATES = 'FETCH_VATRATES';
export const FETCH_VATRATES_FOR_NO_VAT = 'FETCH_VATRATES_FOR_NO_VAT';
export const FETCH_VATRATES_FAILED = 'FETCH_VATRATES_FAILED';

// // User's List
// export const FETCH_USERS = 'FETCH_USERS';
// export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
// export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

// App
export const START_PROGRESS = 'START_PROGRESS';
export const END_PROGRESS = 'END_PROGRESS';
export const SET_TITLE = 'SET_TITLE';

// Webhook
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const FETCHING_WEBHOOK_SUCCESS = 'FETCHING_WEBHOOK_SUCCESS';
export const FETCHING_WEBHOOK_FAILED = 'FETCHING_WEBHOOK_FAILED';
export const RESET_WEBHOOK = 'RESET_WEBHOOK';

// Payout
export const FETCHING_PAYOUT = 'FETCHING_PAYOUT';
export const FETCH_PAYOUT_SUCCESS = 'FETCH_PAYOUT_SUCCESS';
export const FETCH_PAYOUT_FAILURE = 'FETCH_PAYOUT_FAILURE';
export const FETCHING_BLOCKED_TRANSFERS = 'FETCHING_BLOCKED_TRANSFERS';
export const FETCH_BLOCKED_TRANSFERS_SUCCESS =
  'FETCH_BLOCKED_TRANSFERS_SUCCESS';
export const FETCH_BLOCKED_TRANSFERS_FAILURE =
  'FETCH_BLOCKED_TRANSFERS_FAILURE';
export const VALIDATING_PAYOUT = 'VALIDATING_PAYOUT';
export const VALIDATE_PAYOUT_SUCCESS = 'VALIDATE_PAYOUT_SUCCESS';
export const VALIDATE_PAYOUT_FAILURE = 'VALIDATE_PAYOUT_FAILURE';

// Docusign
export const FETCH_DOCUSIGN_USER_INFO_SUCCESS =
  'FETCH_DOCUSIGN_USER_INFO_SUCCESS';
export const FETCH_DOCUSIGN_USER_INFO_FAILURE =
  'FETCH_DOCUSIGN_USER_INFO_FAILURE';
export const LOGIN_DOCUSIGN_FAILURE = 'LOGIN_DOCUSIGN_FAILURE';

// CreaForm
export const FETCH_CREA_FORM_SUCCESS = 'FETCH_CREA_FORM_SUCCESS';
export const FETCH_CREA_FORM_FAILURE = 'FETCH_CREA_FORM_FAILURE';
export const UPDATE_CREA_FORM_SUCCESS = 'UPDATE_CREA_FORM_SUCCESS';
export const UPDATE_CREA_FORM_FAILURE = 'UPDATE_CREA_FORM_FAILURE';

// LegalNotice
export const LEGAL_NOTICE_PROCESSING = 'LEGAL_NOTICE_PROCESSING';
export const LEGAL_NOTICE_SUCCESS = 'LEGAL_NOTICE_SUCCESS';
export const LEGAL_NOTICE_FAILED = 'LEGAL_NOTICE_FAILED';

// snackMessage
export const SET_SNACK_MESSAGE = 'SET_SNACK_MESSAGE';

// Notification
export const START_PROCESS_NOTIFICATION = 'START_PROCESS_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR';

// Bridge Webhook
export const FETCHING_BRIDGE_WEBHOOK_SUCCESS =
  'FETCHING_BRIDGE_WEBHOOK_SUCCESS';
export const FETCHING_BRIDGE_WEBHOOK_FAILED = 'FETCHING_BRIDGE_WEBHOOK_FAILED';
export const RESET_BRIDGE_WEBHOOK = 'RESET_BRIDGE_WEBHOOK';

// fintecture Webhook
export const FETCHING_FINTECTURE_WEBHOOK_SUCCESS =
  'FETCHING_FINTECTURE_WEBHOOK_SUCCESS';
export const FETCHING_FINTECTURE_WEBHOOK_FAILED =
  'FETCHING_FINTECTURE_WEBHOOK_FAILED';
export const RESET_FINTECTURE_WEBHOOK = 'RESET_FINTECTURE_WEBHOOK';

// App loading
export const APP_LOADING = 'APP_LOADING';
