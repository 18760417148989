/* eslint-disable camelcase */
import React from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
// import PropTypes from 'prop-types';
import {
  familySituations,
  genders,
  MARIED,
  OTHER,
  PACTE,
  personTypes,
  PM,
  PP,
  regimeSociale,
} from 'constants/usersContants';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import SmallRadio from 'components/SmallRadio';
import Adresse from 'components/Adresse';
import { SAS, SASU } from 'constants/companies';
import { useTranslation } from 'react-i18next';
import InfoMoralPerson from './InfoMoralPerson';
import InfoWedding from './InfoWedding';
import InfoPacte from './InfoPacte';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
  },
  title: {
    color: '#3D319D',
    marginBottom: '10%',
    textTransform: 'uppercase',
  },
  textInfo: {
    color: '#8E8FA1',
    textAlign: 'left',
  },
  helpUserInfo: {
    color: '#3D319D',
    padding: 0,
    margin: 0,
    textAlign: 'left',
    marginTop: '3%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    textAlign: 'center',
  },
  subTitle: {
    marginTop: '5%',
    fontSize: '1.1em',
    color: '#8E8FA1',
    textAlign: 'left',
  },
  helpTitle: {
    fontSize: '.9em',
    color: '#8E8FA1',
    textAlign: 'left',
  },

  inputWithHelpIconContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  // RADIO TYPE COMPANY
  radioContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '1rem',
  },
  radioWrapper: {
    flex: 1,
    padding: '2%',
    display: 'flex',
    height: '200px',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '15px',
    border: '1px solid #8E8FA1',
    position: 'relative',
  },
  radioWrapperTitle: {
    position: 'absolute',
    fontSize: '1em',
    textTransform: 'uppercase',
    top: -20,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: 'white',
    color: '#8E8FA1',
    zIndex: 10,
  },
  radioLabel: {
    margin: 0,
    padding: '5%',
    height: '100px',
    width: '100px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    color: '#3D319D',
    fontWeight: 'bold',
    border: '2px solid #3D319D',
    '&:hover': {
      backgroundColor: '#3D319D',
      color: 'white',
    },
  },
  // RADIO WITH ICON

  // OTHER RADIO
  radioTitle: {
    marginTop: '3%',
    fontSize: '1em',
    color: '#8E8FA1',
    textAlign: 'left',
  },
  smallRadioContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  smallRadioLabel: {
    margin: 0,
    padding: '3% 1%',
    whiteSpace: 'nowrap',
    height: '1em',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    color: '#3D319D',
    fontWeight: 'bold',
    borderRight: '2px solid #3D319D',
    borderTop: '2px solid #3D319D',
    borderBottom: '2px solid #3D319D',
    '&:first-child': {
      borderLeft: '2px solid #3D319D',
      borderRadius: '10px 0% 0% 10px',
    },
    '&:last-child': {
      borderRadius: '0px 10px 10px 0px',
    },
    '&:hover': {
      backgroundColor: '#3D319D',
      color: 'white',
    },
  },
  selected: {
    backgroundColor: '#3D319D',
    color: 'white',
  },
  radioError: {
    borderColor: 'red !important',
    color: 'red',
  },
  radio: {
    display: 'none',
  },
  nameTextField: {
    margin: '1%',
  },
  nameTextFieldFull: {
    margin: '1%',
    width: '100%',
  },
  townAndZipCode: {
    display: 'flex',
  },
  town: {
    flex: 1,
  },
  zipCode: {
    flex: 0.5,
  },

  subCategories: {
    paddingLeft: '10%',
    display: 'flex',
    flexDirection: 'column',
  },

  // Input type number
  // inputStyle: {
  //   '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
  //     '-webkit-appearance': 'none',
  //     display: 'none',
  //     backgroundColor: 'red',
  //   },
  // },

  // PARTNER CARD
  partnerCard: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'left',
    padding: '10px 20px',
    border: '2px solid #3D319D',
    marginBottom: '2%',
    color: '#3D319D',
    borderRadius: '15px',
    display: 'flex',
    '&:hover': {
      color: 'white',
      backgroundColor: '#3D319D',
      '& $avatar': {
        color: '#3D319D !important',
        backgroundColor: 'white !important',
      },
    },
  },
  partnerCardSelected: {
    color: 'white',
    backgroundColor: '#3D319D',
    '& $avatar': {
      color: '#3D319D !important',
      backgroundColor: 'white !important',
    },
  },
  partnerCardDisable: {
    border: '2px solid lightgray',
    cursor: 'pointer',
    backgroundColor: 'red',
  },
  editIconContainer: {
    position: 'absolute',
    right: '2%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  editIcon: {
    fontSize: '2em',
  },
  avatar: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3D319D',
    marginRight: '3%',
  },
  addPartnerCard: {
    textAlign: 'left',
    padding: '10px 20px',
    border: '2px solid white',
    marginTop: '8%',
    marginBottom: '5%',
    color: '#3D319D',
    fontWeight: 'bold',
    fontSize: '1.2em',
    borderRadius: '15px',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
      backgroundColor: '#3D319D',
      '& $avatar': {
        color: '#3D319D !important',
        backgroundColor: 'white !important',
      },
    },
  },
  parnterName: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
});

const UserInfo = () => {
  const { values } = useFormikContext();
  const classes = useStyles();
  const {
    personne_physique_morale,
    partner_title,
    partner_family_situation,
    partnerProtectionSocial,
    eurl_sasu,
  } = values;
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Information sur l’(les) associé(e-es)</h1>
      <div className={classes.root}>
        <SmallRadio
          classes={classes}
          title="Type d’associé"
          options={personTypes}
          value={personne_physique_morale}
          name="personne_physique_morale"
          helpName="partner"
        />
        {PM === personne_physique_morale && (
          <InfoMoralPerson classes={classes} values={values} />
        )}
        <SmallRadio
          classes={classes}
          title="Titre"
          options={genders}
          value={partner_title}
          name="partner_title"
        />
        <h2 className={classes.subTitle}>Civilité</h2>
        <Field
          component={TextField}
          type="string"
          label={`${t('partner_name')} *`}
          name="partner_name"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="string"
          label={`${t('partner_firstname')} *`}
          name="partner_firstname"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="email"
          label={`${t('partner_email')} *`}
          name="partner_email"
          className={classes.nameTextField}
          variant="outlined"
          size="small"
        />
        {PP === personne_physique_morale &&
          eurl_sasu !== SAS &&
          eurl_sasu !== SASU && (
            <>
              <h2 className={classes.subTitle}>Protection sociale</h2>
              <Field
                component={TextField}
                type="string"
                label="N° sécurité sociale *"
                name="partnerSecuNumber"
                className={classes.nameTextField}
                autoComplete="off"
                variant="outlined"
                size="small"
              />
              <SmallRadio
                classes={classes}
                title="Régime actuel de protection sociale *"
                options={regimeSociale}
                value={partnerProtectionSocial}
                name="partnerProtectionSocial"
                style={{ marginBottom: '3%' }}
              />
              {partnerProtectionSocial === OTHER && (
                <Field
                  component={TextField}
                  type="string"
                  label="Préciser *"
                  name="partnerProtectionSocialDetail"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
              )}
            </>
          )}
        <h2 className={classes.subTitle}>Informations de naissance</h2>
        <Field
          component={KeyboardDatePicker}
          clearable
          label={`${t('partner_date_of_birth')} *`}
          placeholder="01/01/1990"
          format="dd/MM/yyyy"
          maxDate={new Date()}
          name="partner_date_of_birth"
          className={classes.nameTextField}
          inputVariant="outlined"
          size="small"
        />
        <Adresse
          classes={classes}
          address={null}
          city="partner_place_of_birth"
          zipCode="partner_place_of_birth_zipcode"
          department="partner_state_of_birth"
          country="partner_country_of_birth"
          cityLabel={`${t('partner_place_of_birth')} *`}
          countryLabel={`${t('partner_country_of_birth')} *`}
          nationality="partner_nationality"
          nationalityLabel={`${t('partner_nationality')} *`}
        />
        <Adresse
          classes={classes}
          title="Adresse *"
          address="partner_place_of_living"
          city="partner_city"
          zipCode="partner_zipcode"
          department={null}
          country={null}
          cityLabel={`${t('partner_city')} *`}
          nationality={null}
        />
        <h2 className={classes.subTitle}>Votre filiation</h2>
        <Field
          component={TextField}
          type="string"
          label={`${t('partner_father_name')} *`}
          name="partner_father_name"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="string"
          label={`${t('partner_father_firstname')} *`}
          name="partner_father_firstname"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="string"
          label={`${t('partner_mother_name')} *`}
          name="partner_mother_name"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="string"
          label={`${t('partner_mother_firstname')} *`}
          name="partner_mother_firstname"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        {PP === personne_physique_morale && (
          <SmallRadio
            classes={classes}
            title={`${t('partner_family_situation')} *`}
            options={familySituations}
            value={partner_family_situation}
            name="partner_family_situation"
          />
        )}
        {partner_family_situation === MARIED && (
          <InfoWedding classes={classes} />
        )}
        {partner_family_situation === PACTE && (
          <InfoPacte classes={classes} eurl_sasu={eurl_sasu} />
        )}
      </div>
    </div>
  );
};

UserInfo.propTypes = {};

export default UserInfo;
