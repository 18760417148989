import { authInstance, simpleInstance } from '../../utils/axiosInterceptor';

const geturl = async (filename) => {
  const {
    data: { url, type },
  } = await authInstance.get(
    `${process.env.REACT_APP_MEDIA_URL}/geturl?key=${filename}`
  );

  return { url, type };
};

const getobject = async (filename) => {
  const result = await authInstance.get(
    `${process.env.REACT_APP_MEDIA_URL}/getobject?key=${filename}`
  );

  return result;
};

const getUploadUrl = async (alternativeId, file) => {
  return simpleInstance.get(
    `${process.env.REACT_APP_GET_UPLOAD_URL}?name=${file.name}&alternativeId=${alternativeId}&type=${file.type}`
  );
};

const uploadDocument = async (alternativeId, file) => {
  try {
    const { data: presignedPostUrl } = await getUploadUrl(alternativeId, file);
    const formData = new FormData();
    const { key } = presignedPostUrl.fields;
    formData.append('Content-Type', file.type);
    Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', file); // The file has be the last element

    await simpleInstance.post(presignedPostUrl.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return key;
  } catch (e) {
    return null;
  }
};

export default { geturl, getobject, uploadDocument };
