export default ({
  companies: {
    isDeletingCompany,
    errorDeletingCompany,
    companies,
    isFetchingCompanies,
    errorFetchingCompanies,
    companyDeleteResult,
  },
}) => ({
  companies,
  isDeletingCompany,
  errorDeletingCompany,
  isFetchingCompanies,
  errorFetchingCompanies,
  companyDeleteResult,
});
