import React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    padding: '4%',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5em',
    textAlign: 'center',
    position: 'relative',
    marginBottom: '1em',
  },
  lockIcon: {
    position: 'absolute',
    right: 0,
    color: 'white',
    marginRight: '2%',
    padding: '1%',
    width: '15px',
    height: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#32BEA6',
    borderRadius: '100%',
  },
}));

const SaveDateButton = ({
  selectedDate,
  handleDateChange,
  handleSubmitDate,
  label,
  disabled,
  minDate,
  done,
}) => {
  const DATE_DIPLAY_FORMAT = 'dd/MM/yyyy';
  const classes = useStyles();

  let minDateAccepted = null;
  if (minDate) {
    const dateSplited = minDate.split('/');
    minDateAccepted = `${dateSplited[1]}/${dateSplited[0]}/${dateSplited[2]}`;
  }
  return (
    <Paper elevation={3} className={classes.card}>
      <Typography
        color="primary"
        component="h6"
        variant="h6"
        className={classes.cardHeading}
      >
        {label}
      </Typography>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        label={label}
        format={DATE_DIPLAY_FORMAT}
        minDate={minDateAccepted}
        minDateMessage="La date ne doit pas être postérieur à là date de dépôt des fonds"
        value={selectedDate}
        InputAdornmentProps={{ position: 'end' }}
        onChange={(date) => handleDateChange(date)}
      />

      <Button
        variant="contained"
        color="primary"
        className={classes.submitDateBtn}
        onClick={handleSubmitDate}
        disabled={disabled}
      >
        Enregistrer la date
      </Button>
      {done && (
        <div className={classes.lockIcon}>
          <CheckIcon style={{ height: '12px', width: '12px' }} />
        </div>
      )}
    </Paper>
  );
};

SaveDateButton.propTypes = {
  selectedDate: PropTypes.string,
  label: PropTypes.string,
  handleDateChange: PropTypes.func,
  handleSubmitDate: PropTypes.func,
  disabled: PropTypes.bool,
  done: PropTypes.bool,
  minDate: PropTypes.string,
};

export default SaveDateButton;
