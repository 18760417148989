import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button } from '@material-ui/core';

import { useAuth0 } from 'auth/react-auth0-spa';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Unauthorized = ({ message }) => {
  const classes = useStyles();
  const { logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <div className={classes.root}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {message}
      </Alert>
      <Button
        variant="contained"
        color="primary"
        onClick={() => logoutWithRedirect()}
      >
        OK
      </Button>
    </div>
  );
};

Unauthorized.propTypes = {
  message: PropTypes.string,
};
export default Unauthorized;
