import {
  authInstance as axios,
  authSubscriptionInstance,
} from 'utils/axiosInterceptor';

const fetchCompanies = (query) =>
  axios.get(`${process.env.REACT_APP_COMPANY_URL}/admin/companies?${query}`);

const fetchCompany = async (id) =>
  axios.get(`${process.env.REACT_APP_COMPANY_URL}/admin/companies/${id}`);

const updateCompany = async (company) =>
  axios.put(
    `${process.env.REACT_APP_COMPANY_URL}/admin/companies/${company._id}`,
    company
  );
const updateCompanyDetails = (id, data) =>
  axios.put(`${process.env.REACT_APP_COMPANY_URL}/admin/companies/${id}`, data);

const deleteCompany = (id) =>
  axios.delete(`${process.env.REACT_APP_CLEANUP_URL}/deleteCompany/${id}`);

const fetchCompanySubscription = async (companyId) => {
  const res = await axios.get(
    `${process.env.REACT_APP_SUBSCRIPTION_URL}/admin/company-subscription/${companyId}`
  );
  return res.data;
};

const fetchHistorySubscription = async (companyId) => {
  const res = await axios.get(
    `${process.env.REACT_APP_SUBSCRIPTION_URL}/company-subscription/history`,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );
  return res.data;
};

// case downgrade Subscription - isDeleted param always = true
const downgradeSubscription = async (dataDowngradeSub) => {
  const res = await axios.post(
    `${process.env.REACT_APP_SUBSCRIPTION_URL}/company-subscription/to-free`,
    dataDowngradeSub
  );
  return res.data;
};

const updatePaymentLinkLimit = async (dataUpdatePaymentLink) => {
  const res = await authSubscriptionInstance.put(
    `${process.env.REACT_APP_SUBSCRIPTION_URL}/company-subscription/quota`,
    dataUpdatePaymentLink
  );
  return res.data;
};

export default {
  fetchCompanies,
  fetchCompany,
  updateCompany,
  deleteCompany,
  updateCompanyDetails,
  fetchCompanySubscription,
  fetchHistorySubscription,
  downgradeSubscription,
  updatePaymentLinkLimit,
};
