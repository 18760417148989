import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  modal: {
    textAlign: 'center',
    marginTop: '5%',
    margin: '0 auto',
    width: '40%',
    backgroundColor: 'white',
    borderRadius: '15px',
    padding: '2em',
  },
}));

const ModalFoundDepositPlace = ({
  initValues,
  open,
  handleClose,
  saveFoundDepositPlace,
}) => {
  const classes = useStyles();
  const creaData = { ...initValues };
  const {
    // eslint-disable-next-line
    depot_capital_chez,
    // eslint-disable-next-line
    banking_corporation_name,
    // eslint-disable-next-line
    ville_agence_bancaire,
    // eslint-disable-next-line
    adresse_agence_bancaire,
    // eslint-disable-next-line
    code_postal_agence_bancaire,
  } = initValues;

  const initialValues = {
    depotCapitalChez: depot_capital_chez,
    bankingCorporationName: banking_corporation_name,
    villeAgenceBancaire: ville_agence_bancaire,
    adresseAgenceBancaire: adresse_agence_bancaire,
    codePostalAgenceBancaire: code_postal_agence_bancaire,
  };

  const [capitalInfo, setCapitalInfo] = useState(initialValues);

  const onchange = (event) => {
    const copyCapitalInfo = { ...capitalInfo };
    const { id, value } = event.target;
    copyCapitalInfo[id] = value;
    setCapitalInfo(copyCapitalInfo);
  };

  const onchangeRadio = (event) => {
    const copyCapitalInfo = { ...capitalInfo };
    const { name, value } = event.target;
    copyCapitalInfo[name] = value;
    setCapitalInfo(copyCapitalInfo);
  };

  const clearAndClose = () => {
    setCapitalInfo(initialValues);
    handleClose();
  };

  const canSave = () => {
    if (
      capitalInfo.bankingCorporationName === '' ||
      capitalInfo.villeAgenceBancaire === '' ||
      capitalInfo.adresseAgenceBancaire === '' ||
      capitalInfo.codePostalAgenceBancaire === ''
    ) {
      return false;
    }
    return true;
  };

  const formatAndSave = () => {
    if (canSave()) {
      creaData.depot_capital_chez = capitalInfo.depotCapitalChez;
      creaData.banking_corporation_name = capitalInfo.bankingCorporationName;
      creaData.ville_agence_bancaire = capitalInfo.villeAgenceBancaire;
      creaData.adresse_agence_bancaire = capitalInfo.adresseAgenceBancaire;
      creaData.code_postal_agence_bancaire =
        capitalInfo.codePostalAgenceBancaire;
      creaData.stateOfClientFolder.placeFoundDepositSaved = true;
      saveFoundDepositPlace(creaData);
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={clearAndClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        <Typography
          id="modal-modal-title"
          variant="h4"
          style={{ margin: '2%' }}
        >
          Dépôt du capital
        </Typography>
        <FormControl
          style={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={capitalInfo.depotCapitalChez}
            name="depotCapitalChez"
            id="depotCapitalChez"
            style={{ display: 'flex', flexDirection: 'row', margin: '0 auto' }}
            onChange={onchangeRadio}
          >
            <FormControlLabel
              value="notaire"
              control={<Radio />}
              label="notaire"
            />
            <FormControlLabel
              value="banque"
              control={<Radio />}
              label="banque"
            />
          </RadioGroup>
        </FormControl>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            style={{ marginTop: '2%' }}
            fullWidth
            value={capitalInfo.bankingCorporationName}
            id="bankingCorporationName"
            label="Nom de l'agence ou de l'étude"
            variant="outlined"
            onChange={onchange}
          />
          <TextField
            style={{ marginTop: '2%' }}
            fullWidth
            value={capitalInfo.adresseAgenceBancaire}
            id="adresseAgenceBancaire"
            label="Adresse de l'agence ou de l'étude"
            variant="outlined"
            onChange={onchange}
          />
          <TextField
            style={{ marginTop: '2%' }}
            fullWidth
            value={capitalInfo.villeAgenceBancaire}
            id="villeAgenceBancaire"
            label="Ville de l'agence ou de l'étude"
            variant="outlined"
            onChange={onchange}
          />
          <TextField
            style={{ marginTop: '2%' }}
            fullWidth
            value={capitalInfo.codePostalAgenceBancaire}
            id="codePostalAgenceBancaire"
            label="Code postal de l'agence ou de l'étude"
            variant="outlined"
            onChange={onchange}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '5%',
          }}
        >
          <Button
            variant="contained"
            // color="primary"
            onClick={clearAndClose}
          >
            Annuler
          </Button>
          <Button variant="contained" color="primary" onClick={formatAndSave}>
            Enregistrer
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ModalFoundDepositPlace.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  saveFoundDepositPlace: PropTypes.func,
  initValues: PropTypes.object,
};

export default ModalFoundDepositPlace;
