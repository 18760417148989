import { authInstance as axios } from 'utils/axiosInterceptor';

const fetchCards = async (companyId) =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/admin/cards`, {
    headers: {
      'X-Company-Id': companyId,
    },
  });

const lostStolenCard = async (companyId, cardOId, data) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/cards/${cardOId}/lost`,
    data,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const lockUnlockCard = async (companyId, cardOId, data) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/cards/${cardOId}/lock`,
    data,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const fetchWallets = async (companyId) =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/admin/wallets/`, {
    headers: {
      'X-Company-Id': companyId,
    },
  });

const fetchTransactions = async (walletId, companyId, qs = '') =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/wallets/${walletId}/transactions?${qs}`,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const fetchUsers = async (companyId) => {
  return axios.get(`${process.env.REACT_APP_TREEZOR_URL}/admin/users`, {
    headers: {
      'X-Company-Id': companyId,
    },
  });
};

const updateDocuments = async (userId, companyId, data) => {
  return axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/users/${userId}`,
    data,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );
};

const postDocument = async (companyId, data) => {
  return axios.post(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/document`,
    data,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );
};

const updateTreezorUser = async (userOId, companyId, data) => {
  return axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/users/${userOId}`,
    data,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );
};

const kycReview = async (userId, companyId, data = {}) => {
  return axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/users/${userId}/KYCReview`,
    data,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );
};

const fetchWebhooks = async (limit, last) => {
  let lastQuery;
  if (last && last.PK) {
    lastQuery = `&lastPK=${encodeURIComponent(last.PK)}${
      last.SK && `&lastSK=${encodeURIComponent(last.SK)}`
    }`;
  }

  return axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/webhooks?limit=${limit}${
      lastQuery || ''
    }`
  );
};

const fetchWebhookDetails = async (webhookId) => {
  return axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/webhooks/${webhookId}`
  );
};

const unblockPIN = async (companyId, cardOId, data = {}) => {
  return axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/cards/${cardOId}/unblockPIN`,
    data,
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );
};

const searchWebhooks = async (searchObjectId, searchObject) => {
  return axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/webhooks/search?objectId=${searchObjectId}&object=${searchObject}`
  );
};

const fetchSEPAAdminRequest = async (qs) => {
  return axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/sepa/all-requests?${qs}`
  );
};

const updateSEPAAdminRequestType = async (formData) => {
  return axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/sepa/request`,
    formData
  );
};

const fetchSEPARequestById = async (requestOId) => {
  return axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/sepa/request?requestOId=${requestOId}`
  );
};

const fetchSEPATransaction = async (foreignId) => {
  return axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/admin/transactions?foreignId=${foreignId}`
  );
};

export default {
  fetchCards,
  lostStolenCard,
  lockUnlockCard,
  updateTreezorUser,
  fetchWallets,
  fetchTransactions,
  fetchUsers,
  updateDocuments,
  postDocument,
  kycReview,
  fetchWebhooks,
  fetchWebhookDetails,
  searchWebhooks,
  unblockPIN,
  fetchSEPAAdminRequest,
  updateSEPAAdminRequestType,
  fetchSEPARequestById,
  fetchSEPATransaction,
};
