/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, parse } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch, useSelector } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  Tooltip,
  AppBar,
  Toolbar,
  Button,
  Box,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PublishIcon from '@material-ui/icons/Publish';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/core/styles';
import FilePreview from 'components/FilePreview';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  fetchDocuSignUserInfo,
  login,
  sendEnvelope,
} from 'actions/DocuSignActions';

import {
  fetchCreationCompanyForm,
  updateCreaForm,
  recreateStatutes,
  saveFoundDepositDate,
  updateCreationCompanyFormSuccess,
  notaryEmailSuccess,
  sendDocSignSuccess,
  saveLegalNotiveDone,
  reactivateSaveStatutsDate,
  sendFinalEmailToClient,
  generateAllDocs,
} from 'actions/CompanyActions';
import {
  generateLegalNotice,
  sendLegalNotice,
} from 'actions/LegalNoticeActions';

// import S3FileService from 'services/S3FileService';
import CreationCompanyService from 'services/CreationCompanyService';
import _ from 'lodash';
import SnackBar from 'components/Snackbar';
import { ERROR, SUCCESS } from 'constants/severity';
import AppActions from 'actions/AppActions';
import ListOfDocs from './ListOfDocs';
import SaveDateButton from './SaveDateButton';
import SimpleActionButton from './SimpleActionButton';
import ModalFoundDepositPlace from './ModalFoundDepositPlace';
import ModalLegalNotice from './ModalLegalNotice';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '15px',
    gap: '22px',
  },
  mainContainer: {
    display: 'flex',
    gap: '1em',
  },
  leftColumn: {
    flex: '2 0 60%',
  },
  rightColumn: {
    flex: '1 0 20%',
  },
  cardContainer: {
    justifyContent: 'center',
    marginBottom: '2em',
  },
  cardHeading: {
    textAlign: 'center',
  },
  thinTitle: {
    fontWeight: 'lighter',
  },
  listItem: {
    marginBottom: '20px',
  },
  submitDateBtn: {
    marginLeft: '40px',
  },
  '@media (max-width: 960px)': {
    mainContainer: {
      display: 'flex',
      gap: '1em',
      flexDirection: 'column',
    },
  },
  backBtn: {
    marginRight: 20,
    textDecoration: 'none',
  },
}));

const DATE_DIPLAY_FORMAT = 'dd/MM/yyyy';

const ReviewDocuments = (props) => {
  const history = useHistory();

  const classes = useStyles();
  const dispatch = useDispatch();
  const companyId = props.match.params._id;
  const [loading, setLoading] = useState(false);
  const [creationCompany, setCreationCompany] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [openingDocument, setOpeningDocument] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [docusignToken, setDocusignToken] = useState(
    localStorage.getItem('dsToken')
  );
  const [showModalFoundDepositPlace, setShowModalFoundDepositPlace] = useState(
    false
  );
  const [annonceLegalNotice, setShowModalLegalNotice] = useState(null);

  const dsUserInfo = useSelector((state) => state.docusign.userInfo);
  const { creaFormData, generatingAllDocs } = useSelector(
    (state) => state.companies
  );

  const [showSpinner, setShowSpinner] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [signatureStatusDate, setSignatureStatusDate] = useState(new Date());

  useEffect(() => {
    setShowSpinner(false);
  }, [creaFormData.documents]);

  useEffect(() => {
    if (_.isEmpty(creaFormData)) {
      dispatch(fetchCreationCompanyForm(companyId));
    }
  }, []);

  const handleDateChange = (date) => {
    const updatedCreaFormData = {
      ...creaFormData,
      stateOfClientFolder: {
        ...creaFormData.stateOfClientFolder,
        dateFoundDepositSaved: false,
      },
    };
    dispatch(updateCreationCompanyFormSuccess(updatedCreaFormData));
    return setSelectedDate(date);
  };

  const handleSignDateChange = (date) => {
    setSignatureStatusDate(date);
    dispatch(reactivateSaveStatutsDate(creaFormData));
  };

  // eslint-disable-next-line
  const fetchDocumentURL = async (key, id) => {
    try {
      const res = await CreationCompanyService.getUrl(id, key);
      return res.data;
    } catch (error) {
      // eslint-disable-next-line
      console.error('Backoffice error: ', error);
    }
  };

  const fetchCreationCompany = async (id) => {
    setLoading(true);
    try {
      const res = await dispatch(fetchCreationCompanyForm(id));

      setCreationCompany(res.data);
    } catch (error) {
      // eslint-disable-next-line
      console.error('Backoffice error: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (creationCompany === null) {
      fetchCreationCompany(companyId);
    } else {
      // eslint-disable-next-line
      const {
        date_depot_des_fonds: dateDepotFounds,
        date_signature_statuts: dateSignature,
      } = creationCompany;
      if (dateSignature) {
        setSignatureStatusDate(parse(dateSignature, 'dd/MM/yyyy', new Date()));
      }
      if (dateDepotFounds) {
        setSelectedDate(parse(dateDepotFounds, 'dd/MM/yyyy', new Date()));
      }
    }
  }, [creationCompany]);

  // eslint-disable-next-line
  const handleDocument = async (file) => {
    try {
      // Get file extension
      const fileType = file.key.split('.').at(-1);

      // Get document'url
      const documentURL = await fetchDocumentURL(file.key, companyId);

      const document = {
        ...file,
        key: documentURL.url,
        fileType,
      };

      return document;
    } catch (error) {
      // eslint-disable-next-line
      console.error('Backoffice error: ', error);
    }
  };

  const handleCloseDialog = () => {
    setDialogVisible(false);
    setOpeningDocument(null);
  };

  const handleSubmitDate = async () => {
    // eslint-disable-next-line
    const date_depot_des_fonds = format(selectedDate, DATE_DIPLAY_FORMAT);
    dispatch(saveFoundDepositDate(creaFormData, date_depot_des_fonds));
  };

  const handleSubmitSignStatusDate = async () => {
    // eslint-disable-next-line
    const date_signature_statuts = format(
      signatureStatusDate,
      DATE_DIPLAY_FORMAT
    );
    const res = {
      ...creaFormData,
      date_signature_statuts,
      stateOfClientFolder: {
        ...creaFormData.stateOfClientFolder,
        dateOfSignatureSaved: true,
      },
    };

    dispatch(updateCreaForm(companyId, creaFormData._id, res));
    return setCreationCompany(res);
  };

  const updateFoundDepositPlace = async (companyData) => {
    dispatch(updateCreaForm(companyId, creationCompany._id, companyData));
    dispatch(
      AppActions.setSackbarMessage(
        'Lieux du dépôt des fonds enregistré.',
        SUCCESS
      )
    );
    return setCreationCompany(companyData);
  };

  const handleRecreateStatutes = async () => {
    setShowSpinner(true);
    dispatch(recreateStatutes(companyId));
    const newCreationCompany = {
      ...creationCompany,
      stateOfClientFolder: {
        ...creaFormData.stateOfClientFolder,
        newStatutesDocGenerated: true,
      },
    };

    dispatch(
      updateCreaForm(companyId, creationCompany._id, newCreationCompany)
    );
    dispatch(AppActions.setSackbarMessage('Nouveau document généré.', SUCCESS));
  };

  const removeKeyInDocument = (key) => {
    const docIdx = creationCompany.documents.findIndex(
      (document) => document.key === key
    );

    const updatedcreationCompany = { ...creationCompany };
    updatedcreationCompany.documents[docIdx] = {
      ...updatedcreationCompany.documents[docIdx],
      key: null,
    };
    dispatch(
      updateCreaForm(
        companyId,
        updatedcreationCompany._id,
        updatedcreationCompany
      )
    );
    setCreationCompany(updatedcreationCompany);
  };

  const downloadFile = async (file) => {
    try {
      const { url } = await fetchDocumentURL(file.key, companyId);
      window.location.href = url;
    } catch (error) {
      // eslint-disable-next-line
      console.error('Backoffice error: ', error);
    }
  };

  const renderPreviewFileDialog = () => {
    return (
      <Fragment>
        <Dialog
          onClose={handleCloseDialog}
          aria-labelledby="file-preview-dialog-title"
          open={dialogVisible}
          maxWidth="md"
          fullScreen
        >
          <AppBar style={{ position: 'relative' }}>
            <Toolbar
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant="h6" component="div">
                File Preview
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {openingDocument && (
            <FilePreview
              content={{
                type:
                  openingDocument.fileType === 'pdf'
                    ? 'application/pdf'
                    : openingDocument.fileType,
                value: openingDocument.key,
              }}
            />
          )}
        </Dialog>
      </Fragment>
    );
  };

  useEffect(() => {
    if (docusignToken) {
      (async () => {
        await dispatch(fetchDocuSignUserInfo());
      })();
    } else {
      setDocusignToken(null);
    }
  }, [docusignToken]);

  const renderReviewScreen = () => {
    const { documents: files, company_name: companyName } = creaFormData;
    // const { documents: files } = creationCompany;
    const groupedFiles = _.groupBy(files, 'title');
    const filesLastVersion = Object.keys(groupedFiles).map((key) => {
      const lastIndex = groupedFiles[key].length - 1;
      return groupedFiles[key][lastIndex];
    });
    let generatedDocs;
    let uploadByClientDocs;
    let bbfDocumens = [];
    if (
      creationCompany &&
      creationCompany.documents &&
      creationCompany.documents.length > 0
    ) {
      generatedDocs = filesLastVersion.filter((doc) => doc.source === null);
      bbfDocumens = filesLastVersion.filter((doc) => doc.source === 'BBF');
      uploadByClientDocs = filesLastVersion.filter(
        (doc) => doc.source === 'CLIENT'
      );
    }

    const handleSign = async () => {
      const validatedDocuments = [];
      let recipientList = [];

      files.map((file) => {
        const { key, recipients } = file;

        recipientList = [...recipientList, ...recipients];

        if (key && recipients && recipients.length > 0) {
          // check & push the latest version document
          const { version } = file;
          if (version) {
            const { title } = file;
            const foundDoc = validatedDocuments.find(
              (el) => el.title === title
            );

            if (foundDoc) {
              const foundIdxDoc = validatedDocuments.findIndex(
                (el) => el.title === title
              );
              const { version: foundDocVersion } = foundDoc;

              if (version > foundDocVersion) {
                validatedDocuments[foundIdxDoc] = { ...file };
              }
            } else {
              validatedDocuments.push(file);
            }
          } else {
            validatedDocuments.push(file);
          }
        }

        return file;
      });

      if (recipientList.length > 0) {
        recipientList = recipientList.map((recipient) => ({
          email: recipient.email,
          firstname: recipient.firstname,
          name: recipient.name,
        }));
      }

      // Remove the duplicate emails in array
      recipientList = _.uniqBy(recipientList, 'email');

      await dispatch(
        sendEnvelope(validatedDocuments, recipientList, dsUserInfo, companyName)
      );
      dispatch(sendDocSignSuccess(creaFormData));
    };

    const handleDocuSignSignIn = () => {
      dispatch(login());
    };

    const handleSendEmailWithDocuments = async () => {
      setSendingEmail(true);

      const result = await CreationCompanyService.sendEmailWithDocuments(
        companyId
      );

      if (result.data.code === 200 && result.data.message === 'OK') {
        dispatch(notaryEmailSuccess(creaFormData));
      } else {
        dispatch(
          AppActions.setSackbarMessage(
            "Erreur lors de l'envoi du mail au notaire",
            ERROR
          )
        );
      }

      setSendingEmail(false);
    };

    const handleLegaleAnnonce = async () => {
      const annonce = await dispatch(generateLegalNotice(companyId));
      setShowModalLegalNotice(annonce);
    };

    const sendLegalAnnonce = (annonce) => {
      dispatch(sendLegalNotice(companyId, annonce));
      setShowModalLegalNotice(null);
      dispatch(saveLegalNotiveDone(creaFormData));
    };

    const sendFinalEmail = () => {
      dispatch(sendFinalEmailToClient(creaFormData));
    };

    const showDepositPlaceModal = () => {
      setShowModalFoundDepositPlace(true);
    };

    const regenerateDocs = async () => {
      await dispatch(generateAllDocs(creaFormData));
    };

    const unlock = (stepToUnlock) => {
      const creaFormDataCopy = { ...creaFormData };
      creaFormDataCopy.stateOfClientFolder[stepToUnlock] = false;
      dispatch(
        updateCreaForm(companyId, creaFormDataCopy._id, creaFormDataCopy)
      );
    };

    const renderPicker = () => {
      if (creaFormData) {
        const {
          kit,
          liberation_partielle,
          stateOfClientFolder: {
            dateFoundDepositSaved,
            sentToTheNotary,
            sentToDocusign,
            dateOfSignatureSaved,
            sentTolegalNotice,
            newStatutesDocGenerated,
            placeFoundDepositSaved,
            finalEmailSent,
          },
        } = creaFormData;
        return (
          <div className={classes.rightColumn}>
            <Grid container className={classes.cardContainer}>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {(kit === 'kit4soc' || kit === 'kit4sci') && (
                    <SimpleActionButton
                      title="Envoyer Email au notaire"
                      label="Envoyer"
                      handleSubmit={handleSendEmailWithDocuments}
                      // disabled={sendingEmail}
                      done={sentToTheNotary}
                      doneMessage="Email envoyé"
                      step="sentToTheNotary"
                      unlock={unlock}
                    />
                  )}
                  <SimpleActionButton
                    title="Lieux de dépôt des fonds"
                    label="Editer lieux de dépôt des fonds"
                    handleSubmit={showDepositPlaceModal}
                    done={placeFoundDepositSaved}
                    step="placeFoundDepositSaved"
                    unlock={unlock}
                  />
                  <SaveDateButton
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    handleSubmitDate={handleSubmitDate}
                    label="Date de dépôt des fonds"
                    done={dateFoundDepositSaved}
                    // disabled={
                    //   dateFoundDepositSaved ||
                    //   (!sentToTheNotary &&
                    //     (kit === 'kit4soc' || kit === 'kit4sci'))
                    // }
                  />
                  <SaveDateButton
                    minDate={creaFormData.date_depot_des_fonds}
                    selectedDate={signatureStatusDate}
                    handleDateChange={handleSignDateChange}
                    handleSubmitDate={handleSubmitSignStatusDate}
                    label="Date Signature des Statuts"
                    done={dateOfSignatureSaved}
                    // disabled={dateOfSignatureSaved}
                  />
                  <SimpleActionButton
                    title="Générer nouveau document de statuts"
                    label="Générer"
                    handleSubmit={handleRecreateStatutes}
                    disabled={showSpinner}
                    done={newStatutesDocGenerated}
                    step="newStatutesDocGenerated"
                    unlock={unlock}
                  />
                  <SimpleActionButton
                    title="Signature (Docusign)"
                    label={
                      dsUserInfo
                        ? "Faire signer l'enveloppe"
                        : 'Se connecter à docusign'
                    }
                    handleSubmit={
                      dsUserInfo ? handleSign : handleDocuSignSignIn
                    }
                    done={sentToDocusign}
                    step="sentToDocusign"
                    unlock={unlock}
                  />
                  <SimpleActionButton
                    title="Annonce légale"
                    label="Générer l’annonce legale"
                    handleSubmit={handleLegaleAnnonce}
                    done={sentTolegalNotice}
                    step="sentTolegalNotice"
                    unlock={unlock}
                    // disabled={!sentToDocusign}
                  />
                  <SimpleActionButton
                    title="Envoyer Email au client"
                    label="Envoyer"
                    handleSubmit={sendFinalEmail}
                    step="finalEmailSent"
                    unlock={unlock}
                    done={finalEmailSent}
                    // disabled={!sentTolegalNotice}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        );
      }
      return <></>;
    };

    return (
      <div className={classes.mainContainer}>
        <div className={classes.leftColumn}>
          <Grid container className={classes.cardContainer}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <Typography
                  color="primary"
                  component="h1"
                  variant="h4"
                  className={classes.cardHeading}
                >
                  Documents générés
                </Typography>
                <ListOfDocs
                  documents={generatedDocs}
                  handleDocument={handleDocument}
                  setDialogVisible={setDialogVisible}
                  setOpeningDocument={setOpeningDocument}
                  downloadFile={downloadFile}
                  companyId={companyId}
                />
                {generatingAllDocs && (
                  <div style={{ width: '100%', padding: '2% 10%' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={regenerateDocs}
                      disabled={generatingAllDocs}
                    >
                      génération des documents...
                    </Button>
                  </div>
                )}
              </Card>
            </Grid>
          </Grid>
          <Grid container className={classes.cardContainer}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <Typography
                  color="primary"
                  component="h1"
                  variant="h4"
                  className={classes.cardHeading}
                >
                  Documents fournis par le client
                </Typography>
                <ListOfDocs
                  documents={uploadByClientDocs}
                  handleDocument={handleDocument}
                  setDialogVisible={setDialogVisible}
                  setOpeningDocument={setOpeningDocument}
                  downloadFile={downloadFile}
                  companyId={companyId}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid container className={classes.cardContainer}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <Typography
                  color="primary"
                  component="h1"
                  variant="h4"
                  className={classes.cardHeading}
                >
                  ACTION DE BBF
                </Typography>
                <List>
                  {bbfDocumens.map((doc, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <DescriptionIcon />
                      </ListItemIcon>
                      <ListItemText primary={doc.title} />

                      <ListItemSecondaryAction>
                        {doc.notary && (
                          <Tooltip title="Envoyé au notaire">
                            <span>
                              <IconButton disabled>
                                <AttachFileIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        {doc.recipients.length > 0 && (
                          <Tooltip title="Ajouté dans l'enveloppe Docusign">
                            <span>
                              <IconButton disabled>
                                <MailIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        {doc.key ? (
                          <IconButton
                            aria-label="view"
                            onClick={async () => {
                              const document = await handleDocument(doc);
                              setDialogVisible(true);
                              setOpeningDocument(document);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ) : (
                          <IconButton aria-label="view">
                            <Link
                              to={`/uploaddocument/${doc.title}/${companyId}`}
                            >
                              <PublishIcon />
                            </Link>
                          </IconButton>
                        )}
                        {doc.key && (
                          <IconButton edge="end" aria-label="download">
                            <DeleteIcon
                              style={{ color: 'red' }}
                              onClick={() => {
                                removeKeyInDocument(doc.key);
                              }}
                            />
                          </IconButton>
                        )}
                        {doc.key && (
                          <IconButton
                            edge="end"
                            aria-label="download"
                            // eslint-disable-next-line
                            disabled={!doc.hasOwnProperty('key')}
                          >
                            <span onClick={() => downloadFile(doc)} download>
                              <GetAppIcon />
                            </span>
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                  <div style={{ width: '100%', padding: '2% 10%' }}>
                    <Link to={`/uploaddocument/addDoc/${companyId}`}>
                      <Button variant="contained" color="primary" fullWidth>
                        Ajouter un document
                      </Button>
                    </Link>
                  </div>
                </List>
              </Card>
            </Grid>
          </Grid>
        </div>
        {renderPicker()}
        <SnackBar />
        <ModalFoundDepositPlace
          open={showModalFoundDepositPlace}
          handleClose={() => setShowModalFoundDepositPlace(false)}
          initValues={creaFormData}
          saveFoundDepositPlace={(companyData) => {
            updateFoundDepositPlace(companyData);
          }}
        />
        <ModalLegalNotice
          annonce={annonceLegalNotice}
          handleClose={() => setShowModalLegalNotice(null)}
          sendLegalAnnonce={sendLegalAnnonce}
        />
      </div>
    );
  };

  const renderBody = () => {
    if (creationCompany.length === 0) {
      return (
        <Typography variant="h4" style={{ textAlign: 'center' }}>
          Not found any creation company data
        </Typography>
      );
    }

    const {
      nom_propre: nomPropre,
      company_name: companyName,
    } = creationCompany;

    return (
      <>
        <Grid container spacing={2}>
          <Box display="flex" alignItems="center" mb={5}>
            <Button
              className={classes.backBtn}
              variant="contained"
              color="primary"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <Typography variant="h4">
              {nomPropre}: {companyName}
            </Typography>
          </Box>
        </Grid>
        <Divider />
        {renderReviewScreen()}
      </>
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.root}>
      {renderPreviewFileDialog()}
      {creationCompany && renderBody()}
    </div>
  );
};

ReviewDocuments.propTypes = {
  match: PropTypes.object,
};

export default ReviewDocuments;
