import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import { red, green, blue } from '@material-ui/core/colors';

const RefusedChip = withStyles({
  root: {
    background: red[400],
    color: 'white',
  },
})(Chip);

const ValidatedChip = withStyles({
  root: {
    background: green[400],
    color: 'white',
  },
})(Chip);

const ReplacedChip = withStyles({
  root: {
    background: blue[400],
    color: 'white',
  },
})(Chip);

export default {
  RefusedChip,
  ValidatedChip,
  ReplacedChip,
};
