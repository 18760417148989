import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Typography, Box } from '@material-ui/core';
import { INSEE } from '@bebusinessfocus/compta-hub-core';
import { LOOKUP_LABEL } from 'Config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontWeight: 500,
  },
  item: {
    paddingBottom: theme.spacing(1),
  },
  heading: {
    color: '#3d319d',
  },
  treezorInfo: {
    minWidth: 250,
    maxWidth: 300,
    overflowWrap: 'break-word',
  },
  kycReview: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  kycInfo: {
    borderRadius: 4,
    overflow: 'hidden',
    marginRight: 10,
    minWidth: 170,
  },
  kycLabel: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    color: 'white',
    padding: 12,
    fontSize: 12,
    fontWeight: 600,
  },
  kycValue: {
    color: 'white',
    padding: 8,
    fontSize: 12,
    fontWeight: 600,
  },
  kycLevelNone: {
    backgroundColor: '#90a4ae',
  },
  kycLevelLight: {
    backgroundColor: '#80deea',
  },
  kycLevelRegular: {
    backgroundColor: '#90caf9',
  },
  kycLevelStrong: {
    backgroundColor: '#66bb6a',
  },
  kycLevelRefused: {
    backgroundColor: '#ef5350',
  },
  kycReviewNone: {
    backgroundColor: '#90a4ae',
  },
  kycReviewPending: {
    backgroundColor: '#80deea',
  },
  kycReviewValidated: {
    backgroundColor: '#66bb6a',
  },
  kycReviewRefused: {
    backgroundColor: '#ef5350',
  },
}));

const CompanyDetailHeader = (props) => {
  const classes = useStyles();
  const { company, treezorUsers } = props;
  const {
    name,
    created_at: createdAt,
    code_ape: codeAPE,
    bank_details: bankDetails,
  } = company;

  const renderKYCLevel = (kycLevel) => {
    const levels = {
      0: 'None',
      1: 'Light',
      2: 'Regular',
      3: 'Strong',
      4: 'Refused',
      5: 'Investigating',
    };

    return levels[kycLevel] || 'Unknown';
  };

  const renderKYCReview = (kycReview) => {
    const levels = {
      0: 'None',
      1: 'Pending',
      2: 'Validated',
      3: 'Refused',
    };

    return levels[kycReview] || 'Unknown';
  };

  const renderTreezorUserInfo = (
    userTypeId,
    firstname,
    lastname,
    legalName,
    userId
  ) => {
    switch (userTypeId) {
      case '1': {
        return (
          <div
            className={classes.treezorInfo}
          >{`${firstname} ${lastname} - ${userId}`}</div>
        );
      }
      case '2': {
        return (
          <div
            className={classes.treezorInfo}
          >{`${legalName} - ${userId}`}</div>
        );
      }
      default: {
        return <div className={classes.treezorInfo}>Unknown</div>;
      }
    }
  };

  const renderKYCInfo = (user) => {
    const {
      kycReview,
      kycLevel,
      userTypeId,
      firstname,
      lastname,
      legalName,
      userId,
    } = user;

    return (
      <Box className={classes.kycReview} my={1}>
        {renderTreezorUserInfo(
          userTypeId,
          firstname,
          lastname,
          legalName,
          userId
        )}

        <div className={classes.kycInfo} mr={2}>
          <span>Kyc Level </span>
          <span className={classes.kycLabel}>{renderKYCLevel(kycLevel)}</span>
          <span
            className={classNames(classes.kycValue, {
              [classes.kycLevelNone]: kycLevel === '0',
              [classes.kycLevelLight]: kycLevel === '1',
              [classes.kycLevelRegular]: kycLevel === '2',
              [classes.kycLevelStrong]: kycLevel === '3',
              [classes.kycLevelRefused]: kycLevel === '4',
            })}
          >
            {kycLevel}
          </span>
        </div>

        <div className={classes.kycInfo}>
          <span>Kyc Review </span>
          <span className={classes.kycLabel}>{renderKYCReview(kycReview)}</span>
          <span
            className={classNames(classes.kycValue, {
              [classes.kycReviewNone]: kycReview === '0',
              [classes.kycReviewPending]: kycReview === '1',
              [classes.kycReviewValidated]: kycReview === '2',
              [classes.kycReviewRefused]: kycReview === '3',
            })}
          >
            {kycReview}
          </span>
        </div>
      </Box>
    );
  };

  const renderLookupLabel = (code) => {
    const labelList = INSEE.find((item) => item.Code === code);
    return labelList ? labelList[LOOKUP_LABEL] : '';
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={[classes.heading, classes.item]}>
        {name}
      </Typography>
      {bankDetails &&
        bankDetails.length > 0 &&
        bankDetails.map((item, i) => (
          <div className={classes.item} key={i}>
            Wallet ID: {item.walletId || 'Unknown'}
          </div>
        ))}

      <div className={classes.item}>
        Date Creation du Compte sur BBF: {createdAt}
      </div>
      {codeAPE && (
        <div className={classes.item}>
          {codeAPE} - {renderLookupLabel(codeAPE)}
        </div>
      )}

      <div className={classes.item}>Risque identifié à l&apos;origine: </div>

      {treezorUsers && treezorUsers.map((user) => renderKYCInfo(user))}
    </div>
  );
};

CompanyDetailHeader.propTypes = {
  treezorUsers: PropTypes.object,
  company: PropTypes.object,
};
export default CompanyDetailHeader;
