import axios from 'axios';

const fetchRCS = async (zipcode, city) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_URL_RCS}?zip=${zipcode}&city=${city}`
    );
    return data.rcs;
  } catch (e) {
    return '';
  }
};

const fetchCompanyDataFromGreffe = async (companyName) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_URL_INFOGREFFE}?q=${companyName}`
  );
  return data;
};

export { fetchRCS, fetchCompanyDataFromGreffe };
