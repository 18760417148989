/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { values, find, flattenDeep } from 'lodash';
import PropTypes from 'prop-types';
import CompanyDeleteDialog from 'containers/deletecompany/CompanyDeleteDialog';
import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  // DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Snackbar,
  // Tooltip,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from '@material-ui/core';
import CustomChip from 'components/CustomChip';
import FilePreview from 'components/FilePreview';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTable from 'components/materialtable/MaterialTable';
import TransactionDetails from 'components/transactions/TransactionDetails';
import CompanyService from 'services/CompanyService';
import UserService from 'services/userservice';
import TreezorService from 'services/treezorservice';
import S3FileService from 'services/S3FileService';
import { useTranslation } from 'react-i18next';
// import TreezorUserMock from '../../mocks/treezorUser';
import { Treezor, KYCStatus } from '@bebusinessfocus/compta-hub-core';
import AppActions from 'actions/AppActions';
import CompanyDetailHeader from 'components/companydetails/CompanyDetailHeader';
import { fetchBlockedTransfers } from 'actions/PayoutActions';
import { updateCompany } from 'actions/CompanyActions';
import BlockedTransfers from 'components/companydetails/BlockedTransfers';
import { LOCK_CARD } from 'Config';
import TreezorTransactions from 'containers/TreezorTransactions';
import { useDeactivateTreezor } from './hooks';
import CompanyInfo from './CompanyInfo';
import VATInfo from './CompanyInfo/VATInfo';
import LogoCompany from './CompanyInfo/LogoCompany';
import CompanySubscription from './CompanySubscription';

const drawerWidth = 700;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  document: {
    marginTop: 10,
    marginBottom: 15,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  message: {
    margin: '1rem 0',
  },
  kycInfo: {
    borderRadius: 4,
    overflow: 'hidden',
    marginRight: 10,
  },
  kycLabel: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    color: 'white',
    padding: 8,
    fontSize: 12,
    fontWeight: 600,
  },
  kycValue: {
    color: 'white',
    padding: 8,
    fontSize: 12,
    fontWeight: 600,
  },
  kycLevelNone: {
    backgroundColor: '#90a4ae',
  },
  kycLevelLight: {
    backgroundColor: '#80deea',
  },
  kycLevelRegular: {
    backgroundColor: '#90caf9',
  },
  kycLevelStrong: {
    backgroundColor: '#66bb6a',
  },
  kycLevelRefused: {
    backgroundColor: '#ef5350',
  },
  kycReviewNone: {
    backgroundColor: '#90a4ae',
  },
  kycReviewPending: {
    backgroundColor: '#80deea',
  },
  kycReviewValidated: {
    backgroundColor: '#66bb6a',
  },
  kycReviewRefused: {
    backgroundColor: '#ef5350',
  },
  closeAccountBtn: {
    backgroundColor: '#ef5350',
    borderColor: '#ef5350',
    color: 'white',
    '&:hover': {
      backgroundColor: '#b61827',
      borderColor: '#b61827',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#b61827',
      borderColor: '#b61827',
    },
  },
  backBtn: {
    marginRight: 20,
    textDecoration: 'none',
  },
  companyStatus: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'end',
  },
  textAlignEnd: {
    textAlign: 'center',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CompanyDetails = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = props.match.params._id;

  const [company, setCompany] = useState(null);
  const [users, setUsers] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [wallets, setWallets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMessage] = useState(null);
  const [openTransactionDetails, setOpentransactionDetails] = useState(false);
  const [open, setOpen] = useState(false);
  // messages d'erreurs
  const [openDelete, setOpenDelete] = useState(false);
  // treezor users
  const [treezorUsers, setTreezorUsers] = useState(null);
  // const [chosenTreezorUser, setChosenTreezorUser] = useState(null);
  const [openDocument, setOpenDocument] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [openTransaction, setOpenTransaction] = useState(false);
  // errors
  const [errSnackPack, setErrSnackPack] = useState([]);
  const [openErrSnack, setOpenErrSnack] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  // handle activate/deactivate treezor
  const { toggleActivationTreezor } = useDeactivateTreezor(company);
  // kycReview
  const [enableKYCReview, setKYCReview] = useState(false);
  // blockedTransfers
  const blockedTransfers = useSelector((state) =>
    state.payout.payouts.filter((payout) => !payout.hasOwnProperty('payoutId'))
  );

  useEffect(() => {
    dispatch(AppActions.setAppTitle('Company Details'));
    if (errSnackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...errSnackPack[0] });
      setErrSnackPack((prev) => prev.slice(1));
      setOpenErrSnack(true);
    }
  }, [errSnackPack, messageInfo]);

  const handleErrSnackClose = () => {
    setOpenErrSnack(false);
  };
  const handleExited = () => {
    setMessageInfo(undefined);
  };

  // const toggleDrawer = () => {
  //   setOpentransactionDetails(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkDocument = (e, userIdx, docIdx) => {
    const updatedTreezorUsers = [...treezorUsers];
    updatedTreezorUsers[userIdx].documents[docIdx].mark = e.target.value;

    setTreezorUsers(updatedTreezorUsers);
  };

  const handleChangeMessage = (e, userIdx, docIdx) => {
    const updatedTreezorUsers = [...treezorUsers];
    updatedTreezorUsers[userIdx].documents[docIdx].message = e.target.value;

    setTreezorUsers(updatedTreezorUsers);
  };

  const fetchUserCompanyByCompanyId = async (id) => {
    try {
      const resultUsers = await UserService.fetchUserCompanyByCompanyId(id);
      // console.log(resultUsers.data);

      const companyUsers = [];
      for (let i = 0; i < resultUsers.data.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const res = await UserService.fetchUser(resultUsers.data[i].user_id);
        companyUsers.push(res.data);
        // console.log(res.data);
      }
      setUsers(companyUsers);
    } catch (e) {
      // console.log(e);
    }
  };

  const fetchCompany = async (_id) => {
    setLoading(true);
    try {
      const res = await CompanyService.fetchCompany(_id);
      setCompany(res.data);
      // console.log(res.data);
    } catch (e) {
      // console.log(e);
    }
    setLoading(false);
  };

  // const mockTreezorUserData = async () => {
  //   const res = await TreezorUserMock.mockTreezorUser();
  //   setTreezorUsers(res.users);
  // };

  const fetchWallets = async (companyId) => {
    try {
      const resultWallets = await TreezorService.fetchWallets(companyId);
      setWallets(resultWallets.data);
    } catch (e) {
      setMessage(e.message);
      setOpen(true);
    }
  };

  const checkDocumentKycReview = (status) => {
    const kycReviews = Object.values(Treezor.KYCDocumentReview);

    if (kycReviews.indexOf(status) === -1) {
      return true;
    }
    return false;
  };

  const fetchTreezorUsers = async (companyId) => {
    try {
      const resultTreezorUsers = await TreezorService.fetchUsers(companyId);

      await Promise.all(
        resultTreezorUsers.data.map(async (user) => {
          const newDocumentsPromise = user.documents.map(async (doc) => {
            const urlRs = await S3FileService.geturl(doc.filename)
              .then((res) => {
                const { url, type } = res;
                return {
                  ...doc,
                  url,
                  fileType: type,
                  mark: checkDocumentKycReview(doc.status),
                };
              })
              .catch((err) => {
                if (err.code === 'NoSuchKey') {
                  return { ...doc, mark: checkDocumentKycReview(doc.status) };
                }
              });

            return urlRs;
          });

          const newDocuments = await Promise.all(newDocumentsPromise);

          // Check empty documents
          const isEmptyDocs = newDocuments.includes(undefined);
          isEmptyDocs ? (user.documents = []) : (user.documents = newDocuments);
        })
      );

      setTreezorUsers(resultTreezorUsers.data);
      const isKYCReview = await checkForKYCRequest(resultTreezorUsers.data);
      console.log(
        '🚀 ~ file: CompanyDetails.js ~ line 428 ~ handleOpenDocumentPreview ~ isKYCReview',
        isKYCReview
      );
      setKYCReview(isKYCReview);
    } catch (error) {
      console.log(error);
      setMessage(error.message);
      setOpen(true);
    }
  };

  const updateDocuments = async (userIdx) => {
    try {
      const formatTrezzorUser = [...treezorUsers][userIdx];

      formatTrezzorUser.documents.map((doc) => {
        if (doc.mark === 'accepted') {
          doc.status = parseInt(
            Treezor.KYCDocumentReview.KYC_DOCUMENT_REVIEW_VALIDATED
          );
          delete doc.message;
        } else if (doc.mark === 'refused') {
          doc.status = parseInt(
            Treezor.KYCDocumentReview.KYC_DOCUMENT_REVIEW_REFUSED
          );
        }
        delete doc.mark;
        delete doc.url;
        delete doc.fileType;
      });

      const companyId = props.match.params._id;
      const { _id: userId } = formatTrezzorUser;
      const data = {
        documents: formatTrezzorUser.documents,
      };

      await TreezorService.updateDocuments(userId, companyId, data)
        .then(() => {
          // handleCloseDocumentPreview();
          fetchTreezorUsers(companyId);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const postDocuments = async (user) => {
    const companyId = props.match.params._id;

    const { _id: userOId, documents } = user;

    // send all document to treezor
    const postDocumentPromise = documents.map(async (doc) => {
      if (
        !doc.documentId &&
        doc.status === Treezor.KYCDocumentReview.KYC_DOCUMENT_REVIEW_VALIDATED
      ) {
        const data = {
          userOId,
          documentOId: doc._id,
        };
        const result = await TreezorService.postDocument(companyId, data);
        result.data.documentOId = doc._id;
        return result.data;
      }
    });
    let postedDocuments = await Promise.all(postDocumentPromise);

    postedDocuments = postedDocuments.filter((item) => item);
    console.log(
      '🚀 ~ file: CompanyDetails.js ~ line 312 ~ postDocuments ~ postedDocuments',
      postedDocuments
    );
    if (postedDocuments.length) {
      // have documentId, update documentId to database
      const documentsData = JSON.parse(JSON.stringify(documents));
      const newDocuments = documentsData.map((doc) => {
        const found = postedDocuments.find(
          (posted) => posted.documentOId === String(doc._id)
        );
        if (found) {
          const { documentId } = found;
          delete doc.mark;
          delete doc.fileType;
          delete doc.url;
          return { ...doc, documentId };
        }
        delete doc.mark;
        delete doc.fileType;
        delete doc.url;
        return { ...doc };
      });
      console.log(
        '🚀 ~ file: CompanyDetails.js ~ line 332 ~ newDocuments ~ newDocuments',
        newDocuments
      );
      const newTreezorUser = { ...user };
      delete newTreezorUser.documents;
      newTreezorUser.documents = newDocuments;

      return await TreezorService.updateTreezorUser(
        userOId,
        companyId,
        newTreezorUser
      )
        .then((res) => res.data)
        .catch((err) => {
          console.log(
            '🚀 ~ file: CompanyDetails.js ~ line 340 ~ ).then ~ err',
            err
          );
          return err;
        });
    }
  };

  const kycReviewRequest = async () => {
    try {
      dispatch(AppActions.startScreenProgress());
      const users = JSON.parse(JSON.stringify(treezorUsers));

      // if there are any refused or no validated document, don't request kyc review
      let foundRefused = [];

      const checkRefusedPromise = users.map((user) => {
        const { documents } = user;
        const found = documents.filter(
          (doc) =>
            doc.status ===
              Treezor.KYCDocumentReview.KYC_DOCUMENT_REVIEW_REFUSED ||
            doc.status === 0
        );

        return Promise.resolve(found).then(
          (rs) => (foundRefused = [...foundRefused, ...rs])
        );
      });
      await Promise.all(checkRefusedPromise);

      console.log(
        '🚀 ~ file: CompanyDetails.js ~ line 374 ~ kycReviewRequest ~ foundRefused',
        foundRefused
      );

      if (foundRefused.length) {
        const error = [
          {
            errorMessage:
              'All documents should be validated before request KYC review',
            key: new Date().getTime(),
          },
        ];
        setErrSnackPack(error);
        return false;
      }

      // send all documents to treezor, then update to db, return updated treezorUsers
      const postDocumentPromise = users.map((user) => {
        return postDocuments(user);
      });

      let updatedTreezorUrs = await Promise.all(postDocumentPromise);

      updatedTreezorUrs = updatedTreezorUrs.filter((item) => item);
      console.log(
        '🚀 ~ file: CompanyDetails.js ~ line 406 ~ kycReviewRequest ~ updatedTreezorUrs',
        updatedTreezorUrs
      );
      // call kyc review for the company
      let theCompany;
      if (updatedTreezorUrs.length) {
        if (updatedTreezorUrs.length > 1) {
          // entreprenuer individual
          theCompany = updatedTreezorUrs.find(
            (user) => user.userTypeId === '2'
          );
        } else {
          // auto-entreprenuer
          [theCompany] = updatedTreezorUrs;
        }
      } else if (enableKYCReview) {
        if (users.length > 1) {
          // entreprenuer individual
          theCompany = users.find((user) => user.userTypeId === '2');
        } else {
          // auto-entreprenuer
          [theCompany] = users;
        }
      }
      const companyId = props.match.params._id;
      const { _id: userOId } = theCompany;

      // get the company
      await TreezorService.kycReview(userOId, companyId)
        .then((res) => {
          const { data } = res;
          console.log(
            '%cCompanyDetails.js line:337 data',
            'color: #007acc;',
            data
          );
          dispatch(AppActions.endScreenProgress());
          handleCloseDocumentPreview();
        })
        .catch((error) => {
          console.log(error);
          dispatch(AppActions.endScreenProgress());
          if (error.errors) {
            const errArr = [
              ...error.errors.map((err) => {
                return { ...err, key: new Date().getTime() };
              }),
            ];
            setErrSnackPack(errArr);
          }
        });
    } catch (error) {
      console.log(error);
      setMessage(error.message);
      setOpen(true);
    }
  };

  useEffect(() => {
    fetchUserCompanyByCompanyId(props.match.params._id);
    fetchCompany(props.match.params._id);
    fetchTreezorUsers(props.match.params._id);
    dispatch(fetchBlockedTransfers(props.match.params._id));
  }, [props.match.params._id]);

  if (loading) {
    return <CircularProgress />;
  }

  const handleOpenDocumentPreview = async () => {
    setOpenDocument(true);
    setDocumentLoading(true);
    await fetchTreezorUsers(props.match.params._id);
    setDocumentLoading(false);
  };

  const handleCloseDocumentPreview = () => {
    setOpenDocument(false);
    setKYCReview(false);
    setTreezorUsers([...treezorUsers]);
  };

  const fetchCards = async (companyId) => {
    try {
      const resultCards = await TreezorService.fetchCards(companyId);
      return resultCards.data;
    } catch (error) {
      setMessage(error);
    }
  };

  const handleCompanyStatus = async (status) => {
    // status === true when user want to disable a company
    // check if status is true and treezorUser is not null
    // TODO: redux action exisits: use it
    if (status && company.treezorUser) {
      const cards = await fetchCards(company._id);

      if (cards) {
        const cardPromises = cards.map(async (card) => {
          return await updateCardStatus(company._id, card._id, LOCK_CARD);
        });

        await Promise.all(cardPromises);
      }

      // return await updateCompanyStatus(status);
      dispatch(updateCompany(company));
      return setCompany({ ...company, suspended: status });
    }

    // return await updateCompanyStatus(status);
    dispatch(updateCompany(company));
    return setCompany({ ...company, suspended: status });
  };

  // const updateCompanyStatus = async (status) => {
  //   const data = {
  //     suspended: status,
  //   };

  //   CompanyService.updateCompanyDetails(company._id, data)
  //     .then((/* response */) => {
  //       setCompany({ ...company, suspended: status });
  //       // console.log(response.data);
  //     })
  //     .catch((/* e */) => {
  //       // console.log(e);
  //     });
  // };

  const updateCardStatus = async (companyId, cardOId, status) => {
    const data = {
      lockStatus: status,
    };

    try {
      const result = await TreezorService.lockUnlockCard(
        companyId,
        cardOId,
        data
      );
      return result.data;
    } catch (error) {
      setMessage(e.message);
    }
  };

  const renderDrawer = () => {
    if (!transactions) return null;
    if (!transactionId) return null;

    const allTransactions = flattenDeep(values(transactions));
    const theTransaction = find(allTransactions, (transac) => {
      return transac._id === transactionId;
    });

    return (
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={openTransactionDetails}
        onClose={() => {
          setOpentransactionDetails(false);
          setTransactionId(null);
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <IconButton onClick={() => setOpentransactionDetails(false)}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <TransactionDetails
          transactionId={transactionId}
          transaction={theTransaction}
        />
      </Drawer>
    );
  };

  const handleFetchTransaction = async (walletId) => {
    try {
      const options = {
        pagination: false,
      };

      const queryString = Object.keys(options)
        .map((key) => `${key}=${options[key]}`)
        .join('&');

      const res = await TreezorService.fetchTransactions(
        walletId,
        company._id,
        queryString
      );
      if (res && res.data) {
        setTransactions([...res.data.docs]);
      } else {
        setTransactions(null);
      }
      setOpenTransaction(true);
    } catch (err) {
      setTransactions(null);
      setOpenTransaction(true);
    }
  };

  const handleCloseTransactionPopup = () => {
    setOpenTransaction(false);
    setTransactions(null);
  };

  const renderWalletDetails = (wallet, index) => {
    const { walletId } = wallet;
    return (
      <div key={index}>
        <div>Wallet ID : {wallet.walletId}</div>
        <div>Created Date : {wallet.createdDate}</div>
        <div>Currency : {wallet.currency}</div>
        <div>Event Name : {wallet.eventName}</div>
        <div>balance : {wallet.solde}</div>
        <div>User ID : {wallet.userId}</div>
        <div>Wallet Type ID : {wallet.walletTypeId}</div>
        <div>Walllet Status : {wallet.status}</div>
        <div>Wallet IBAN : {wallet.iban}</div>
        <div>Wallet BIC : {wallet.bic}</div>
        <div>
          Wallet Transactions :{' '}
          <IconButton
            aria-label="transactions"
            onClick={() => handleFetchTransaction(walletId)}
          >
            <ReceiptIcon />
          </IconButton>
        </div>
        <Divider />
      </div>
    );
  };

  const renderDocumentStatus = (status) => {
    switch (status) {
      case 2:
        return <CustomChip.ValidatedChip label="VALIDATED" />;
      case 3:
        return <CustomChip.RefusedChip label="REFUSED" />;
      case 4:
        return <CustomChip.ReplacedChip label="REPLACED" />;
      default:
        return <></>;
    }
  };

  const renderDocumentType = (type) => {
    if (!!type) {
      return (
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {t(`treezor.document.${type}`)}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const checkDocument = (documents, condition) => {
    const rs = documents.filter((doc) => condition.indexOf(doc.status) === -1);

    if (rs.length > 0) {
      return false;
    }
    return true;
  };

  const checkForKYCRequest = async (users) => {
    let theCompany;
    if (users.length > 1) {
      // entreprenuer individual
      theCompany = users.find((user) => user.userTypeId === '2');
    } else {
      // auto-entreprenuer
      [theCompany] = users;
    }

    if (typeof theCompany === 'undefined') {
      return false;
    }

    // get the company with userType = 2 to get kycLevel & kycReview
    const { kycLevel, kycReview } = theCompany;

    // check status of the company by kycLevel & kycReview
    const status = KYCStatus.getKYCStatus(Number(kycLevel), Number(kycReview));

    // if there are any no-documentId-validated document, allow kycReview
    let noDocumentIdValidated = [];
    let newUsers = [];

    const checkPromise = users.map((user) => {
      const { documents } = user;
      if (documents.length) {
        const found = documents.filter(
          (doc) =>
            doc.status ===
              Treezor.KYCDocumentReview.KYC_DOCUMENT_REVIEW_VALIDATED &&
            !doc.documentId
        );

        return Promise.resolve(found).then(
          (rs) => (noDocumentIdValidated = [...noDocumentIdValidated, ...rs])
        );
      } else {
        // if there are any new user with no document
        const { kycLevel, kycReview } = user;
        const status = KYCStatus.getKYCStatus(
          Number(kycLevel),
          Number(kycReview)
        );
        if (status === 1) {
          newUsers.push(user);
        }
        return false;
      }
    });
    await Promise.all(checkPromise);

    // status 1,3,4 or any no-documentId-validated document
    if (
      [1, 3, 4].indexOf(status) !== -1 ||
      noDocumentIdValidated.length ||
      newUsers.length
    ) {
      return true;
    }
    return false;
  };

  const renderKYCReviewBtn = () => {
    try {
      if (enableKYCReview) {
        return (
          <DialogActions>
            <Button onClick={kycReviewRequest} color="primary">
              KYC Review
            </Button>
          </DialogActions>
        );
      }
    } catch (error) {
      console.log(
        '%cCompanyDetails.js line:593 error',
        'color: #007acc;',
        error
      );
    }
  };

  const renderUpdateDocumentBtn = (user, userIdx) => {
    const kycDocReviews = Object.values(Treezor.KYCDocumentReview);
    const { documents } = user;
    if (!checkDocument(documents, kycDocReviews)) {
      return (
        <Button onClick={() => updateDocuments(userIdx)} color="primary">
          Update
        </Button>
      );
    }
  };

  const renderKYCInfo = (kycLevel, kycReview) => {
    return (
      <Box display="flex" flexDirection="row" flexWrap="wrap" my={1}>
        <div className={classes.kycInfo}>
          <span className={classes.kycLabel}>KycLevel</span>
          <span
            className={classNames(classes.kycValue, {
              [classes.kycLevelNone]: kycLevel === '0',
              [classes.kycLevelLight]: kycLevel === '1',
              [classes.kycLevelRegular]: kycLevel === '2',
              [classes.kycLevelStrong]: kycLevel === '3',
              [classes.kycLevelRefused]: kycLevel === '4',
            })}
          >
            {kycLevel}
          </span>
        </div>
        <div className={classes.kycInfo}>
          <span className={classes.kycLabel}>KycReview</span>
          <span
            className={classNames(classes.kycValue, {
              [classes.kycReviewNone]: kycReview === '0',
              [classes.kycReviewPending]: kycReview === '1',
              [classes.kycReviewValidated]: kycReview === '2',
              [classes.kycReviewRefused]: kycReview === '3',
            })}
          >
            {kycReview}
          </span>
        </div>
      </Box>
    );
  };

  const renderDocumentPreview = () => (
    <Dialog open={openDocument} fullWidth={true} maxWidth="md" scroll="paper">
      <DialogTitle>
        <Typography variant="h5" color="textPrimary">
          Documents Preview
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleCloseDocumentPreview}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {documentLoading ? (
          <Box
            my={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          treezorUsers &&
          treezorUsers.map((user, i) => (
            <Box key={i} mb={3}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {user.userTypeId === '1' || user.userTypeId === 1
                  ? `${user.firstname} ${user.lastname}`
                  : user.userTypeId === '2' || user.userTypeId === 2
                  ? `${user.legalName}`
                  : 'N/A'}
              </Typography>
              <Typography variant="p" color="textPrimary" gutterBottom>
                {`TreezorUserID: ${user.userId}`}
              </Typography>
              {renderKYCInfo(user.kycLevel, user.kycReview)}
              {user.documents && user.documents.length > 0 ? (
                user.documents.map((doc, k) => (
                  <Card key={k} className={classes.document}>
                    <CardContent>
                      <Grid container alignItems="center">
                        <Grid item xs={8}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Typography
                              variant="h6"
                              color="textPrimary"
                              gutterBottom
                            >
                              {doc.filename}
                            </Typography>
                            <Box ml={2}>{renderDocumentStatus(doc.status)}</Box>
                          </Box>
                        </Grid>
                        {doc.mark ? (
                          <Grid item xs={4}>
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              style={{ justifyContent: 'flex-end' }}
                            >
                              <RadioGroup
                                value={doc.mark}
                                onChange={(e) => handleMarkDocument(e, i, k)}
                              >
                                <FormControlLabel
                                  value="refused"
                                  control={<Radio />}
                                  label="REFUSED"
                                />
                                <FormControlLabel
                                  value="accepted"
                                  control={<Radio />}
                                  label="ACCEPTED"
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      {renderDocumentType(doc.type)}
                      {doc.documentId && (
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography variant="subtitle1">
                              Document id:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1">
                              {doc.documentId}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}

                      {doc.message && !doc.mark && (
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography variant="subtitle1">
                              Message:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1">
                              {doc.message}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {doc.mark === 'refused' && (
                        <div className={classes.message}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Message"
                                multiline
                                rows={4}
                                // defaultValue="If refused, give a mandatory comment"Ï
                                variant="outlined"
                                // name={item.filename}
                                value={doc.message}
                                onChange={(e) => handleChangeMessage(e, i, k)}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      )}

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        {doc.fileType ? (
                          <Paper>
                            {doc.fileType === 'application/pdf' ? (
                              <FilePreview
                                content={{
                                  type: 'application/pdf',
                                  value: doc.url,
                                }}
                              />
                            ) : (
                              <FilePreview
                                content={{
                                  type: 'image',
                                  value: doc.url,
                                }}
                              />
                            )}
                          </Paper>
                        ) : (
                          <Typography>No file</Typography>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <></>
              )}
              {renderUpdateDocumentBtn(user, i)}

              {i !== treezorUsers.length - 1 && <Divider />}
            </Box>
          ))
        )}
      </DialogContent>
      {renderKYCReviewBtn()}
    </Dialog>
  );

  const renderWalletTransactions = () => (
    <Dialog
      open={openTransaction}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle>
        <Typography variant="h5" color="textPrimary">
          Wallet Transactions
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={() => handleCloseTransactionPopup()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {transactions ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Transaction">
              <TableHead>
                <TableRow>
                  <TableCell>_id</TableCell>
                  <TableCell>amount</TableCell>
                  <TableCell>createdDate</TableCell>
                  <TableCell>currency</TableCell>
                  <TableCell>executionDate</TableCell>
                  <TableCell>expenseId</TableCell>
                  <TableCell>transactionId</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((tx, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{tx._id}</TableCell>
                    <TableCell>{tx.amount}</TableCell>
                    <TableCell>{tx.createdDate}</TableCell>
                    <TableCell>{tx.currency}</TableCell>
                    <TableCell>{tx.executionDate}</TableCell>
                    <TableCell>{tx.expenseId}</TableCell>
                    <TableCell>{tx.transactionId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          'Nothing to show'
        )}
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      {msg && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={() => setOpen(false)} severity="error">
            {msg}
          </Alert>
        </Snackbar>
      )}
      {/* snackbar for multiple error alerts */}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openErrSnack}
        onClose={handleErrSnackClose}
        onExited={handleExited}
      >
        <Alert onClose={handleErrSnackClose} severity="error">
          {messageInfo ? messageInfo.errorMessage : undefined}
        </Alert>
      </Snackbar>
      {renderDrawer()}
      <Grid container spacing={2}>
        <Box display="flex" alignItems="center" mb={5}>
          <Button
            variant="contained"
            color="primary"
            className={classes.backBtn}
            onClick={() => history.push('/companylist')}
          >
            Back
          </Button>
        </Box>
        <Grid item xs={12}>
          {company ? (
            <div>
              <Grid container>
                <Grid item xs={12} sm={12} md={8}>
                  <CompanyDetailHeader
                    company={company}
                    treezorUsers={treezorUsers}
                  />
                  <br />
                  <Button
                    variant="contained"
                    className={classes.closeAccountBtn}
                    onClick={() =>
                      history.push(
                        `/company-details/close-account/${companyId}`
                      )
                    }
                  >
                    FERMETURE DU COMPTE DE PAIEMENTS
                  </Button>
                  <br />
                  <br />
                  {company && <TreezorTransactions company={company} />}
                  <br />
                  {blockedTransfers && blockedTransfers.length > 0 && (
                    <BlockedTransfers blockedTransfers={blockedTransfers} />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.textAlignEnd}
                >
                  <LogoCompany values={company} />
                </Grid>
              </Grid>

              <br />
              <div className={classes.companyStatus}>
                <Chip
                  label={
                    company.suspended
                      ? 'Company Is Not Active'
                      : 'Company Is Active'
                  }
                  color="primary"
                />
              </div>

              <CompanyInfo values={company} />
              <br />
              <CompanySubscription companyId={companyId} />
              <br />
              <div>
                {company.suspended ? (
                  <Button
                    variant="outlined"
                    onClick={() => handleCompanyStatus(false)}
                  >
                    Avtive
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => handleCompanyStatus(true)}
                  >
                    Deactivate
                  </Button>
                )}
                <Button variant="outlined" onClick={() => setOpenDelete(true)}>
                  Delete
                </Button>
                <Button variant="outlined" onClick={toggleActivationTreezor}>
                  {company?.isActivateTreezor
                    ? 'Disable Treezor'
                    : 'Enable Treezor'}
                </Button>
                <CompanyDeleteDialog
                  companyId={company._id}
                  companyName={company.name}
                  open={openDelete}
                  toggle={setOpenDelete}
                />
              </div>

              <div>
                {users && (
                  <MaterialTable
                    title="User List"
                    columns={[
                      {
                        title: '_id',
                        field: '_id',
                        render: (user) => (
                          <Link to={`/userdetails/${user._id}`}>
                            {user._id}
                          </Link>
                        ),
                      },
                      {
                        title: 'First Name',
                        field: 'firstname',
                        render: (user) => <>{user.firstname}</>,
                      },
                      {
                        title: 'Last Name',
                        field: 'name',
                        render: (user) => <>{user.name}</>,
                      },
                      {
                        title: 'Email',
                        field: 'email',
                        render: (user) => <>{user.email}</>,
                      },
                      {
                        title: 'Auth0',
                        field: 'user_id',
                        render: (user) => <>{user.user_id}</>,
                      },
                    ]}
                    data={users}
                  />
                )}
              </div>

              <div>
                <Button
                  variant="outlined"
                  onClick={() => fetchWallets(company._id)}
                >
                  Wallets
                </Button>
                <Button variant="outlined" onClick={handleOpenDocumentPreview}>
                  Document Preview
                </Button>
              </div>

              <div>
                {wallets &&
                  wallets.map((wallet, index) => {
                    return renderWalletDetails(wallet, index);
                  })}
              </div>

              {/* {treezorUsers && renderDocumentPreview()} */}
              {renderDocumentPreview()}
              {wallets && renderWalletTransactions()}
            </div>
          ) : (
            <div>Please wait</div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

CompanyDetails.propTypes = {
  companyDeleteResult: PropTypes.array,
};

export default CompanyDetails;
