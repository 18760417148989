import React from 'react';
import GenerateField from 'components/GenerateField';
import SmallTextField from 'components/SmallTextField';
import PropTypes from 'prop-types';

const CompanyOverview = ({ values }) => {
  const reponsiveSm = {
    xs: 12,
    sm: 6,
    md: 3,
  };
  const reponsiveLg = {
    xs: 12,
    sm: 12,
    md: 6,
  };
  const fiedls = [
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      isHidden: values.isContractor,
      componentProps: {
        label: 'company.companyOrverview.company_id',
        value: values._id,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      isHidden: values.isContractor,
      componentProps: {
        label: 'company.companyOrverview.company_name',
        value: values.name,
      },
    },
    {
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.business_name',
        value: values.business_name,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.address',
        value: values.address,
      },
    },
    {
      gridReponsives: reponsiveSm,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.zip_code',
        value: values.zipcode,
      },
    },
    {
      gridReponsives: reponsiveSm,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.city',
        value: values.city,
      },
    },
    {
      gridReponsives: values.isContractor ? reponsiveLg : reponsiveSm,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.phone_number',
        value: values.phone_number,
      },
    },
    {
      gridReponsives: reponsiveSm,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.siren',
        value: values.siren,
      },
    },
    {
      gridReponsives: reponsiveSm,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.nic',
        value: values.nic,
      },
    },
    {
      gridReponsives: reponsiveSm,
      component: SmallTextField,
      isHidden: values.isContractor,
      componentProps: {
        label: 'company.companyOrverview.rcs',
        value: values.rcs,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.code_ape',
        value: values.code_ape,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.legal_form',
        value: values.legal_form,
      },
    },

    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.create',
        value: values.created_at,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.update',
        value: values.updated_at,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.alternative_id',
        value: values.alternativeId,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.website',
        value: values.website,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.referrer',
        value: values.referrer,
      },
    },
    {
      gridReponsives: reponsiveLg,
      component: SmallTextField,
      componentProps: {
        label: 'company.companyOrverview.treezorUser',
        value: values.treezorUser,
      },
    },
  ];

  return <GenerateField name="companyOverview" configs={fiedls} readOnly />;
};

CompanyOverview.propTypes = {
  values: PropTypes.object,
};
export default CompanyOverview;
