import {
  FETCHING_PAYOUT,
  FETCH_PAYOUT_SUCCESS,
  FETCH_PAYOUT_FAILURE,
  FETCHING_BLOCKED_TRANSFERS,
  FETCH_BLOCKED_TRANSFERS_SUCCESS,
  FETCH_BLOCKED_TRANSFERS_FAILURE,
  VALIDATING_PAYOUT,
  VALIDATE_PAYOUT_SUCCESS,
  VALIDATE_PAYOUT_FAILURE,
} from 'actions/Types';

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: null,
  payouts: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_PAYOUT:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case FETCH_PAYOUT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        payouts: [{ ...action.payload }],
      };
    case FETCH_PAYOUT_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        success: false,
        loading: false,
        error,
      };
    }
    case FETCHING_BLOCKED_TRANSFERS: {
      return {
        ...state,
        success: false,
        loading: true,
      };
    }
    case FETCH_BLOCKED_TRANSFERS_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        payouts: action.payload,
      };
    }
    case FETCH_BLOCKED_TRANSFERS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        success: false,
        loading: false,
        error,
      };
    }
    case VALIDATING_PAYOUT:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case VALIDATE_PAYOUT_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
      };
    }
    case VALIDATE_PAYOUT_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        success: false,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};
