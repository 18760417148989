import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';

import InfoIcon from '@material-ui/icons/Info';

import useStyles from './styles';

function SEPAConfirmCard(props) {
  const { foreignId, transactionType, amount, currency, createdDate } = props;

  const classnames = useStyles();

  return (
    <Box
      style={{
        borderRadius: '10px',
        border: '1px solid black',
        width: '100%',
        height: '100%',
        padding: '20px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: '10px',
          gap: '5px',
        }}
      >
        <InfoIcon color="primary" />
        <Typography
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
          color="primary"
        >
          Vos informations de transaction
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Time:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {createdDate && (
            <Box className={classnames.tableDateWrapper}>
              <Typography className={classnames.tableDate}>
                {format(new Date(createdDate), 'dd')}
              </Typography>
              <Box>
                <Typography className={classnames.tableMonthYear}>
                  {format(new Date(createdDate), 'MMM')}
                </Typography>
                <Typography className={classnames.tableMonthYear}>
                  {format(new Date(createdDate), 'yyyy')}
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Typography className={classnames.tableMonthYear}>
                {format(new Date(createdDate), 'HH:mm')}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {transactionType === 'Payin' ? 'Payin ID' : 'Payout ID'}:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography style={{ fontSize: '18px' }}>{foreignId}</Typography>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Amount:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            className={
              transactionType === 'Payin'
                ? classnames.amountCredit
                : classnames.amountDebit
            }
          >
            {amount} {currency}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

SEPAConfirmCard.propTypes = {
  foreignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transactionType: PropTypes.string,
  amount: PropTypes.string,
  currency: PropTypes.string,
  createdDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

export default SEPAConfirmCard;
