import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

// eslint-disable-next-line react/prop-types
const ViewImage = ({ src, alt }) => {
  const classes = useStyles();
  return (
    !!src && (
      <div className={classes.imageContainer}>
        <img className={classes.image} src={src} alt={alt || 'img'} />
      </div>
    )
  );
};

ViewImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default ViewImage;
