export const SUBSCRIPTION_INTERVAL = [
  {
    label: 'company.subscription.subscription_history.subscriptionType.monthly',
    value: 'monthly',
  },
  {
    label: 'company.subscription.subscription_history.subscriptionType.yearly',
    value: 'yearly',
  },
];

export const SUBSCRIPTION_STATUS = [
  {
    label: 'company.subscription.subscription_history.status.active',
    value: 'active',
  },
  {
    label: 'company.subscription.subscription_history.status.canceled',
    value: 'canceled',
  },
  {
    label: 'company.subscription.subscription_history.status.expired',
    value: 'expired',
  },
  {
    label: 'company.subscription.subscription_history.status.modified',
    value: 'modified',
  },
];

export const DISCOVERY_PACKAGE = 'Formule Découverte';
export const STANDARD_FORMULA = 'Formule Standard';
export const EFFICIENCY_FORMULA = 'Formule Efficience';
export const COMPANY_TYPE = [
  {
    label: 'company.subscription.discovery_formula',
    name: DISCOVERY_PACKAGE,
  },
  {
    label: 'company.subscription.standard_formula',
    name: STANDARD_FORMULA,
  },
  {
    label: 'company.subscription.efficiency_formula',
    name: EFFICIENCY_FORMULA,
  },
];

export const TIME_DISABLED_DOWNGRADE_SUB = 14;

export const SUBSCRIPTION_TYPE = {
  FREE_TIER: 'free-tier',
  PAID_TIER: 'paid-tier',
  CUSTOM_TIER: 'custom-tier',
};
