import React from 'react';
import GenerateField from 'components/GenerateField';
import PropTypes from 'prop-types';
import ReadOnlyCheckboxGroup from 'components/ReadOnlyCheckboxGroup';

const InvoiceSetting = ({ values }) => {
  const {
    settings: {
      invoices: {
        delivery_form: deliveryFrom,
        order_form: orderFrom,
        quotation,
      },
    },
  } = values;
  const fiedls = [
    {
      component: ReadOnlyCheckboxGroup,
      componentProps: {
        options: [
          {
            label: 'company.invoicesSetting.quotation',
            value: quotation,
          },
          {
            label: 'company.invoicesSetting.order_form',
            value: orderFrom,
          },
          {
            label: 'company.invoicesSetting.delivery_form',
            value: deliveryFrom,
          },
        ],
      },
    },
  ];

  return (
    <GenerateField
      name="invoicesSettings"
      configs={fiedls}
      readOnly
      label="company.invoicesSetting.title"
    />
  );
};

InvoiceSetting.propTypes = {
  values: PropTypes.object,
};
export default InvoiceSetting;
