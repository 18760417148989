import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  addTypeContainer: {
    width: '100%',
    borderRadius: 8,
    border: '1px solid #ccc',
    marginTop: 20,
  },
  addTypeHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#f3f4f6',
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderEndEndRadius: 0,
    padding: 8,
  },
  addTypeTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  btnAdd: {
    cursor: 'pointer',
    '&:hover': {
      color: 'gray',
    },
    height: 24,
    width: 24,
  },
  list: {
    padding: 20,
  },
  noData: {
    textAlign: 'center',
  },
}));

const useStylesItem = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'start',
    width: '100%',
    gap: 20,
    borderRadius: 6,
    padding: 8,
  },
  deleteIcon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    '&:hover': {
      color: 'gray',
    },
    marginTop: 8,
  },
  accountNumber: {
    minWidth: 480,
  },
  errorMessage: {
    color: 'red',
    margin: 0,
    paddingLeft: 8,
    paddingTop: 2,
  },
}));

export default { useStyles, useStylesItem };
