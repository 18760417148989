import React, { useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import SmallRadio from 'components/SmallRadio';
import { FastField, Field, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import UserInfo from 'views/CreationCompanyUserInfo';

import Clipboard from 'utils/clipboard';

import { headOfficeLocations } from 'constants/usersContants';
import formatDateType from 'utils/dateHelper';
import SmallSelect from 'components/SmallSelect';
import { REGIME_VAT_OPTIONS } from 'constants/companies';
import { useTranslation } from 'react-i18next';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import PartnerCard from './PartnerCard';

const CreationFormDataFields = ({ classes, partnersToRender }) => {
  const { values, handleSubmit, setValues, setFieldValue } = useFormikContext();

  const [generalFormValues, setGeneralFormValues] = useState(null);
  // const [partnerFormValues, setPartnerFormValues] = useState(null);
  const [isPartnerEditing, setPartnerEditing] = useState(false);
  const [selectedPartnerIdx, setSelectedPartnerIdx] = useState(null);
  const { t } = useTranslation();
  const excludeGenerateField = [
    'siege_social_chez',
    'submitCondition',
    'regimeVat',
    'dgPartnerIDs',
    'undefined',
    'dg_spouce_title',
    'dg_spouce_name',
    'dg_spouce_firstname',
    'dg_spouce_email',
    'dg_spouce_secu_number',
    'dg_spouce_birthday',
    'dg_spouce_place_of_birth',
    'dg_spouce_adress',
    'dg_spouce_city',
    'dg_spouce_zipcode',
    'dg_spouce_additional_adress',
    'dg_spouce_effective_date',
    'dg_spouce_position',
    'dg_pacse_title',
    'dg_pacse_name',
    'dg_pacse_firstname',
    'dg_pacse_email',
    'dg_pacse_secu_number',
    'dg_pacse_birthday',
    'dg_pacse_place_of_birth',
    'dg_pacse_adress',
    'dg_pacse_city',
    'dg_pacse_zipcode',
    'dg_pacse_additional_adress',
    'dg_pacse_effective_date',
    'dg_pacse_position',
    'dg_cohabitant_title',
    'dg_cohabitant_name',
    'dg_cohabitant_firstname',
    'dg_cohabitant_email',
    'dg_cohabitant_secu_number',
    'dg_cohabitant_birthday',
    'dg_cohabitant_place_of_birth',
    'dg_cohabitant_adress',
    'dg_cohabitant_city',
    'dg_cohabitant_zipcode',
    'dg_cohabitant_additional_adress',
    'dg_cohabitant_effective_date',
    'dg_cohabitant_position',
    'headOfficeAddress',
    'headOfficeCity',
    'headOfficeOwner',
    'headOfficezipcode',
    'closing_date_with_year',
    'closing_date',
    'date_debut_exercice',
    'partner_date_of_birth',
    'partner_wedding_date',
    'date_aujourdhui',
  ];
  const dateFields = {
    closing_date_with_year: 'dd/MM/yyyy',
    closing_date: 'dd/MM',
    date_debut_exercice: 'dd/MM',
    partner_date_of_birth: 'dd/MM/yyyy',
    partner_wedding_date: 'dd/MM/yyyy',
    date_aujourdhui: 'dd/MM/yyyy',
  };

  const handleSelectPartner = (idx) => {
    setSelectedPartnerIdx(idx);
    setGeneralFormValues(values);
    setValues(partnersToRender[idx]);
    setPartnerEditing(true);
  };

  const handleSubmitCreationCompany = () => {
    // setPartnerFormValues({ ...values });
    setFieldValue('submitCondition', {
      partnerFormValues: isPartnerEditing ? values : null,
      isPartnerEditing,
      selectedPartnerIdx,
      generalFormValues,
    });
    handleSubmit(values);
    if (isPartnerEditing) {
      // setValues(generalFormValues);
      setPartnerEditing(false);
    }
  };

  return (
    values && (
      <>
        <div style={{ display: 'flex' }}>
          <Typography
            variant="h5"
            component="h2"
            color="primary"
            style={{ flex: 1 }}
          >
            COMPANY CREATION FORM
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmitCreationCompany()}
          >
            Save
          </Button>
        </div>

        <Divider style={{ marginTop: 10, marginBottom: 10 }} />

        {isPartnerEditing ? (
          <UserInfo />
        ) : (
          <Grid container spacing={3}>
            {Object.keys(values).map((key, index) => {
              if (!excludeGenerateField.includes(key)) {
                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <label>{t(key)}</label>
                      <FastField
                        component={FormikTextField}
                        fullWidth
                        name={key}
                        placeholder={t(key)}
                        color="primary"
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Clipboard valueToCopy={`${values[key]}`} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                );
              }
              if (key && dateFields[key]) {
                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <label>{t(key)}</label>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        placeholder="01/01/1990"
                        format={dateFields[key]}
                        name={key}
                        className={classes.nameTextField}
                        inputVariant="outlined"
                        size="small"
                        margin="dense"
                        value={formatDateType(values[key])}
                      />
                    </FormControl>
                  </Grid>
                );
              }

              if (key && key === 'siege_social_chez') {
                return (
                  <Grid item xs={12} sm={12} key={index}>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <SmallRadio
                        classes={classes}
                        title="Forme de la société du commissaire aux comptes"
                        options={headOfficeLocations}
                        value={values[key]}
                        name={key}
                      />
                    </FormControl>
                  </Grid>
                );
              }
              if (key && key === 'regimeVat') {
                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <label>Régime de TVA</label>
                      <SmallSelect
                        classes={classes}
                        options={REGIME_VAT_OPTIONS}
                        value={values[key]}
                        name={key}
                      />
                    </FormControl>
                  </Grid>
                );
              }
              return null;
            })}

            {partnersToRender && (
              <Grid item xs={12} sm={12}>
                <label>Partners</label>
                {partnersToRender.map(
                  (partner, idx) =>
                    partner &&
                    !isEmpty(partner) && (
                      <PartnerCard
                        key={partner.id}
                        partner={partner}
                        handleClick={() => handleSelectPartner(idx)}
                        canEdit={true}
                      />
                    )
                )}
              </Grid>
            )}
          </Grid>
        )}

        <Divider style={{ marginTop: 10, marginBottom: 10 }} />

        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ flex: 1 }}
            onClick={() => handleSubmitCreationCompany()}
          >
            Save
          </Button>
        </div>
      </>
    )
  );
};

CreationFormDataFields.propTypes = {
  classes: PropTypes.object,
  partnersToRender: PropTypes.array,
};

export default CreationFormDataFields;
