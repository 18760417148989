import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WalletTransactions from 'components/companydetails/WalletTransactions';
import TreezorService from 'services/treezorservice';
import TreezorTransactionLocationService from 'services/TreezorTransactionLocation';
import FinancialDashboard from 'components/companydetails/FinancialDashboard';

const TreezorTransactions = (props) => {
  const { company } = props;
  const { _id: companyId } = company;
  const [wallets, setWallets] = useState(null);
  const [
    treezorTransactionLocation,
    setTreezorTransactionLocation,
  ] = useState();
  const [transactions, setTransactions] = useState([]);

  const fetchWallets = async (id) => {
    try {
      const resultWallets = await TreezorService.fetchWallets(id);
      setWallets(resultWallets.data);
    } catch (e) {
      // console.log(e);
      setWallets(null);
    }
  };

  useEffect(() => {
    fetchWallets(companyId);
  }, []);

  const fetchTreezorTransactionLocation = async (id) => {
    try {
      const result = await TreezorTransactionLocationService.fetchTreezorTransactionLocation(
        id
      );
      setTreezorTransactionLocation(result.data);
    } catch (error) {
      setTreezorTransactionLocation([]);
    }
  };

  useEffect(() => {
    fetchTreezorTransactionLocation(companyId);
  }, []);

  const fetchAllTransactions = async (walletId) => {
    // Fetch all transactions without pagination
    const options = {
      pagination: false,
    };

    const queryString = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join('&');

    try {
      const res = await TreezorService.fetchTransactions(
        walletId,
        company._id,
        queryString
      );

      if (res && res.data) {
        setTransactions((prevState) => [...prevState, res.data.docs]);
      } else {
        setTransactions(null);
      }
    } catch (err) {
      setTransactions(null);
    }
  };

  const handleFetchTransactions = async () => {
    if (wallets && wallets.length > 0) {
      const promises = wallets.map(async (wallet) => {
        const { walletId } = wallet;
        const transaction = await fetchAllTransactions(walletId);
        return transaction;
      });
      return Promise.all(promises);
    }
    return null;
  };

  useEffect(() => {
    handleFetchTransactions(wallets);
  }, [wallets]);

  return (
    <>
      {transactions && transactions.length !== 0 && (
        <FinancialDashboard
          transactions={transactions}
          treezorTransactionLocation={treezorTransactionLocation}
        />
      )}

      <br />

      {transactions && transactions.length !== 0 && (
        <WalletTransactions transactions={transactions} />
      )}
    </>
  );
};

TreezorTransactions.propTypes = {
  company: PropTypes.object,
};

export default TreezorTransactions;
