// Alert/Warning limits
export const FRAUD_LIMIT_PAYIN =
  process.env.REACT_APP_FRAUD_LIMIT_PAYIN || 5000;
export const FRAUD_LIMIT_PAYOUT =
  process.env.REACT_APP_FRAUD_LIMIT_PAYOUT || 10000;

// transactionTypes
export const PAYIN = 'Payin';
export const PAYOUT = 'Payout';

// Comapny ID request header
export const X_COMPANY_ID = 'X-Company-Id';

// Lookup label for INSEE
export const LOOKUP_LABEL = 'Intitulés_NAF';

// Lock/Unlock card status
export const UNLOCK_CARD = 0;
export const LOCK_CARD = 1;
export const LOST_CARD = 2;
export const STOLEN_CARD = 3;

// Block PIN status
export const BLOCK_PIN = 1;
export const UNBLOCK_PIN_ACTION = 'UnblockPIN';

export const SOCKET_URL =
  process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001';
